<div class="mt-md-3 container-fluid">
    <div class="row mt-2">
      <div class="col-12 col-md-8 offset-md-2">
        <div class="row rmt-2">
          <div class="col-12 col-md-12">
            <div class="h4 text-dark mt-5 mb-2">
              <span
                [routerLink]="['/console/dashboard-direct-debit-affiliation']"
                class="fz-16 fas fa-arrow-left me-2"
              >
              </span>
  
              <span class="text-primary">{{ "Historico Afiliaciones" }}</span>
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="cursor-pounter mt-3 my-3 p-3 bg-body rounded shadow-sm">
            <div class="col-12 text-end pt-2">
              <div class="col-12 col-md-12 mt-2">
                <input
                  style="padding: 16px 9px; background: white"
                  id="demo-external"
                  (onSet)="updateCurrentDirectDebit()"
                  [(ngModel)]="range"
                  mbsc-range
                  class="form-control"
                  [mbsc-options]="dateSetting"
                  #externalRange="mobiscroll"
                />
              </div>
  
              <form
                autocomplete="nope"
                novalidate
                [formGroup]="form"
                class="text-start"
              >
                <div class="row mt-2">
                  <div class="form-group col-6 mt-4">
                    <div class="form-floating">
                      <select
                        class="form-select"
                        id="bank"
                        formControlName="bank"
                        aria-label="Floating label select example"
                        (change)="updateCurrentDirectDebit()"
                      >
                        <option value="">Selecione una opción</option>
                        <option
                          *ngFor="let item of banks; let i = index"
                          [value]="item?.code"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                      <label for="origin_account">Entidad Bancaria</label>
                    </div>
                  </div>
  
                  <div class="form-group col-6 mt-4">
                    <div class="form-floating">
                      <select
                        class="form-select"
                        id="type"
                        formControlName="type"
                        aria-label="Floating label select example"
                        (change)="updateCurrentDirectDebit()"
                      >
                        <option
                          *ngFor="let item of types; let i = index"
                          [value]="item?.id"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                      <label for="origin_account">Tipo</label>
                    </div>
                  </div>
                </div>
              </form>
              <!-- <button type="button" class="btn-close" aria-label="Close" (click)=" view > 1 ? view = 1 : offcanvas.dismiss('Cross click'); "></button> -->
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="cursor-pounter mt-3 my-3 p-3 bg-body rounded shadow-sm">
            <ng-container *ngIf="historic_list.length > 0">
              <ng-container *ngFor="let row of historic_list">
                  <div class="text-dark pt-3 w-100 " >
                      <div class="pb-3 mb-0 small lh-sm  w-100">
                        <div class="d-flex justify-content-between">
                            <span> 
                              <span class="font-weight-bold">
                                  {{ row.type==="1"?'Diario': type==="2"?'Masivo': type==="3"?'Morosos': '' }} <br>
                                  {{ 'Lote:'+row.lote }}</span><br>
                                  {{ row.date | date: 'dd/MM/yyyy' }}<br>
                                  {{ getDescriptionStatus(row.status) }}
                              </span>
                              <span class="font-weight-bold fs-16 text-end pull-right w-100"> {{ row.total_amount }} Bs  </span>
                            <br>
                            
        
                        </div>
        
                        
                      </div>
                    </div>
  
                  <!---->
                  <div class="pt-2 font-weight-bold small" *ngIf="row.directDebitStatus.length>0">
                      Archivos:
                  </div>
                  <div class="small" *ngFor="let ele of row.directDebitStatus">
                      <div *ngIf="ele.status==='GENERATED'">
                        
  
                          Archivo de Afiliación <a href="javascript:void()" (click)="downloadFile(ele.url, 'txt', 'afiliacion_'+row.lote)" class="active-cursor" title="Descargar Archivo de Afiliación">Descargar</a>
                      </div>
                      <div *ngIf="ele.status==='PROCESING'">
                          Respuesta del Banco <a href="javascript:void()" (click)="downloadFile(ele.url, 'txt', 'afiliacion_respuesta_banco_'+row.lote)" class="active-cursor" title="Descargar Archivo de Afiliación">Descargar</a>
                      </div>
                      <div *ngIf="ele.status==='FINISHED'">
                          Resumen de Afiliación <span *ngIf="ele?.url_failed">(Última Corrida) </span> <a href="javascript:void()" (click)="downloadFile(ele.url, 'xlsx', 'afiliacion_resumen_'+row.lote)" class="active-cursor" title="Descargar Archivo de Afiliación Corrida">Descargar</a><br>
                          <span *ngIf="ele?.url_failed">Resumen de Afiliación <a href="javascript:void()" (click)="downloadFile(ele.url_failed, 'xlsx', 'afiliacion_resumen_general_'+row.lote)" class="active-cursor" title="Descargar Archivo de Afiliación Corrida">Descargar</a></span>
  
                      </div>
                  </div>
                  <div class="border-bottom pt-4"></div>
              </ng-container>
              
            </ng-container>
  
            <ng-container *ngIf="historic_list.length === 0">
              <div class="d-flex text-dark pt-3">
                <div class="pb-3 mb-0 small lh-sm border-bottom border-top w-100">
                  <div class="  text-center pt-3">
                    SIN RESULTADOS
                  </div>
                </div>
              </div>
            </ng-container>
  
  
          </div>
        </div>
      </div>
    </div>
  </div>
  