<div *ngIf="isCameraExist && mediaStream; else noCameraExist">
    <div style="text-align:center">
        <div class="w-100 ">
            <webcam [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam" [switchCamera]="nextWebcamObservable"></webcam>
        </div>

        <!-- <div class="d-grid gap-2">
            <div class="mt-3 ">
                <button class="btn btn-primary me-2" (click)="takeSnapshot()">Tomar Foto</button>
            </div>
        </div> -->
    </div>

    <div *ngIf="errors.length > 0">
        <h4>Error Messages:</h4>
        <ul *ngFor="let error of errors">
            <li>{{ error | json }}</li>
        </ul>
    </div>
</div>

<ng-template #noCameraExist>
    Camera device not available
</ng-template>