
        <div class=" col-12 col-md-6 offset-md-3 ">


                <div class="row mt-2">
                    <div class="col-12  col-md-8 offset-md-2">
                        <h5 class=" text-secondary text-center">
                            <i class="  fas fa-user"></i> Estadisticas de registros de clientes
                        </h5>
                    </div>
                </div>
                

                <ng-container *ngIf="loading ">
                    <div class="w-100 text-center mt-3">
                        <img src="./assets/public/images/loading.gif  " alt=" ">
                    </div>
                </ng-container>

            <div class="mt-5" *ngIf="!loading ">


                <div class="float-end text-primary" *ngIf="!reloadStadisticLoading">
                    <i (click)="stadisticGet(false)" class="fas fa-sync-alt"></i>
                </div>
                <div class=" small" *ngIf="!reloadStadisticLoading"> Ult. actualización {{client_stadistic?.date  | date: 'dd/MM/yyyy h:mm:ss a'}} 

                </div>

                <div class=" small " *ngIf="reloadStadisticLoading"> actualizando... 

                </div>

                
            </div>

            <div *ngIf="!loading " class="mt-2 my-3 p-3 bg-body rounded shadow-sm">
                <ng-container>

                    <h4 class="text-secondary mt-2">Registros</h4>


                    <div class=" d-flex text-dark pt-3" *ngFor="let item of stadistic?.clients[0]?.statuses">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark"> {{item?.status?'App':'Consola'}}
                                </span>

                                <span >
                                    {{item?.count}}
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark  fw-bold "> Total Registros
                                </span>

                                <span class="fw-bold">
                                    {{stadistic?.clients[0]?.total}}
                                </span>
                            </div>

                        </div>
                    </div>

                </ng-container>
            
        </div>


        <div *ngIf="!loading " class="mt-3 my-3 p-3 bg-body rounded shadow-sm">
            <ng-container>
                <h4 class="text-secondary mt-2">Biometria</h4>


                <div class=" d-flex text-dark pt-3" *ngFor="let item of stadistic?.status_biometric[0]?.statuses">
                    <div class="pb-3 mb-0 small lh-sm border-bottom w-100" *ngIf="item?.status != 'sin estatus'">
                        <div class="d-flex justify-content-between">
                            <span class="text-gray-dark"> {{item?.status}}
                            </span>

                            <span >
                                {{item?.count}}
                            </span>
                        </div>

                    </div>
                </div>

                <div class=" d-flex text-dark pt-3">
                    <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                        <div class="d-flex justify-content-between">
                            <span class="text-gray-dark  fw-bold "> Sin Biometria
                            </span>

                            <span class="fw-bold">
                                {{stadistic?.status_biometric[0]?.sinBiometria}}
                            </span>
                        </div>

                    </div>
                </div>

                <div class=" d-flex text-dark pt-3">
                    <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                        <div class="d-flex justify-content-between">
                            <span class="text-gray-dark  fw-bold "> Con Biometria
                            </span>

                            <span class="fw-bold">
                                {{stadistic?.status_biometric[0]?.conBiometria}}
                            </span>
                        </div>

                    </div>
                </div>

                <div class=" d-flex text-dark pt-3">
                    <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                        <div class="d-flex justify-content-between">
                            <span class="text-gray-dark  fw-bold "> Incompletos
                            </span>

                            <span class="fw-bold">
                                {{stadistic?.status_biometric[0]?.incompletos}}
                            </span>
                        </div>

                    </div>
                </div>

                <div class=" d-flex text-dark pt-3">
                    <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                    <div class="d-flex justify-content-between">
                        <span class="text-gray-dark  fw-bold "> Completados
                        </span>

                        <span class="fw-bold">
                            {{stadistic?.status_biometric[0]?.completados}}
                        </span>
                    </div>

                </div>
            </div>

            
            

            </ng-container>
           


    </div>


    <div *ngIf="!loading " class="mt-3 my-3 p-3 bg-body rounded shadow-sm">
        <ng-container>
            <h4 class="text-secondary mt-2">Onboarding</h4>


            <div class=" d-flex text-dark pt-3" *ngFor="let item of stadistic?.ob_sections">
                <div class="pb-3 mb-0 small lh-sm border-bottom w-100" *ngIf="item?.status != 'sin estatus'">
                    <div class="d-flex justify-content-between">
                        <span class="text-gray-dark"> {{item?.section?.name}}
                        </span>

                        <span >
                            {{item?.total}} / <span class="text-warning">{{stadistic?.total_APK - item?.total}}</span>
                        </span>
                    </div>

                </div>
            </div>

            <br>


            <div class=" d-flex text-dark pt-3" *ngFor="let item of stadistic?.ob_total[0]?.statuses">
                <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                    <div class="d-flex justify-content-between">
                        <span class="text-gray-dark fw-bold text-lowercase"> {{item?.status}}
                        </span>

                        
                        <span class=" fw-bold" *ngIf="item?.status != 'IN_PROGRES'" >
                            {{item?.count }}  
                        </span>

                        <span class=" fw-bold" *ngIf="item?.status == 'IN_PROGRES'" >
                            
                            {{item?.count - stadistic?.ob_sin_enviar[0]?.total }} 

                        </span>
                        
                    </div>

                </div>
            </div>

            
            <div class=" d-flex text-dark pt-3" >
                <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                    <div class="d-flex justify-content-between">
                        <span class="text-gray-dark fw-bold">Pendiente por enviar
                        </span>

                        <span class=" fw-bold" >
                            {{ stadistic?.ob_sin_enviar[0]?.total}}
                        </span>
                    </div>

                </div>
            </div>

        </ng-container>
    
</div>

<div *ngIf="!loading " class="mt-3 my-3 p-3 bg-body rounded shadow-sm">
    <ng-container>

        <h4 class="text-secondary mt-2">Analisis de aprobación</h4>


        <div class=" d-flex text-dark pt-3" *ngFor="let item of stadistic?.aprobacion[0]?.statuses">
            <div class="pb-3 mb-0 small lh-sm border-bottom w-100" *ngIf="item?.status != '-'" >
                <div class="d-flex justify-content-between">
                    <span class="text-gray-dark"> {{item?.status}}
                    </span>

                    <span  *ngIf="item?.status != 'sin analisis'">
                       {{item?.count}} 
                    </span>

                    <span  *ngIf="item?.status == 'sin analisis'">
                     {{ item?.count + (stadistic?.total_raya > 0 ? stadistic?.total_raya :0)}}
                    </span>
                </div>

            </div>
        </div>

        <div class=" d-flex text-dark pt-3">
            <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                <div class="d-flex justify-content-between">
                    <span class="text-gray-dark  fw-bold "> Total Registros
                    </span>

                    <span class="fw-bold">
                        {{stadistic?.aprobacion[0]?.total}}
                    </span>
                </div>

            </div>
        </div>

    </ng-container>

     </div>
 
    </div>


