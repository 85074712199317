import { Directive, ElementRef, HostListener, HostBinding, EventEmitter, Output } from '@angular/core';

   @Directive({
     selector: '[appDnd]'
   })
   export class DndDirective {
    @Output() fileDropped = new EventEmitter<File[]>();

     @HostBinding('class.file-over') fileOver: boolean | undefined;

     constructor(private el: ElementRef) {}

     @HostListener('dragover', ['$event']) onDragOver(event: any) {
      console.log('Over');
       event.stopPropagation();
       event.preventDefault();
       this.fileOver = true;
     }

     @HostListener('dragleave', ['$event']) public onDragLeave(event: any) {
      console.log('Leave');
       event.stopPropagation();
       event.preventDefault();
       this.fileOver = false;
     }

     @HostListener('drop', ['$event']) public onDrop(event: any) {
      console.log('Drop');
       event.stopPropagation();
       event.preventDefault();
       const files = event.dataTransfer.files;
       console.log(files);
       this.fileDropped.emit(files);
       this.fileOver = false;
     }
   }