<div class="mt-md-3 p-3">

    <div class="row ">
        <div class=" col-12 col-md-6 offset-md-3 ">
            <h5 class=" text-secondary text-center ">
                <i class="  fas fa-users me-2"></i> Estadisticas de Aprobación/Compras
            </h5>



            <div class="row mt-4">

                <div class="mb-4 mt-2 col-12 col-md-6 ">
                    <input style="
                padding: 16px 9px;
                background: white;
            " id="demo-external" (onSet)="birthdaySet()" [(ngModel)]="range" mbsc-range class="form-control" [mbsc-options]="dateSetting3" #externalRange="mobiscroll" />

                </div>
            </div>


            <ng-container *ngIf="loading ">
                <div class="w-100 text-center text-md-start">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>


            <div *ngIf="!loading " class="mt-3 my-3 p-3 bg-body rounded shadow-sm">

                <h5>Total</h5>
                <ng-container>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark text-success"> Aprobadas
                                </span>

                                <span class="fw-bold ">
                                 {{buro_stadistic?.total_A}}
                                </span>
                            </div>

                        </div>
                    </div>
                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark text-danger"> Rechazadas
                                </span>

                                <span class="fw-bold ">
                                 {{buro_stadistic?.total_R}}
                                </span>
                            </div>

                        </div>
                    </div>


                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark text-warning"> Espera psicometrico
                                </span>

                                <span class="fw-bold ">
                                 {{buro_stadistic?.psicometrico_W}}
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark text-secondary"> Sin Consultar
                                </span>

                                <span class="fw-bold ">
                                 {{buro_stadistic?.sin_consult}}
                                </span>
                            </div>

                        </div>
                    </div>


                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0  lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark fw-bold"> Total
                                </span>

                                <span class="fw-bold text-primary">
                                 {{buro_stadistic?.total}}
                                </span>
                            </div>

                        </div>
                    </div>

                </ng-container>

            </div>

            <div *ngIf="!loading " class="mt-4 my-3 p-3 bg-body rounded shadow-sm">

                <h5 class="text-success">Aprobadas</h5>
                <ng-container>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark">Buro
                                </span>

                                <span class="fw-bold">
                                 {{buro_stadistic?.buro_A}}
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark">Psicometrico
                                </span>

                                <span class="fw-bold">
                                 {{buro_stadistic?.psicometrico_A}}
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark fw-bold">Total
                                </span>

                                <span class="fw-bold text-primary">
                                 {{buro_stadistic?.buro_A + buro_stadistic?.psicometrico_A}}
                                </span>
                            </div>

                        </div>
                    </div>

                </ng-container>

            </div>

            <div *ngIf="!loading " class="mt-4 my-3 p-3 bg-body rounded shadow-sm">

                <h5 class="text-danger">Rezadadas</h5>
                <ng-container>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark">Buro
                                </span>

                                <span class="fw-bold">
                                 {{buro_stadistic?.buro_R}}
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark">Psicometrico
                                </span>

                                <span class="fw-bold">
                                 {{buro_stadistic?.psicometrico_R}}
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark fw-bold">Total
                                </span>

                                <span class="fw-bold text-primary">
                                 {{buro_stadistic?.buro_R + buro_stadistic?.psicometrico_R}}
                                </span>
                            </div>

                        </div>
                    </div>

                </ng-container>

            </div>


            <div *ngIf="!loading " class="mt-4 my-3 p-3 bg-body rounded shadow-sm">

                <h5 class="text-dark">Aprobadas Manualmente</h5>
                <div class="text-secondary small">Clientes aprobados manualmente que habián sido rechazados</div>
                <ng-container>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark">Buro
                                </span>

                                <span class="fw-bold">
                                 {{buro_stadistic?.buro_R_manual_A}}
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark">Psicometrico
                                </span>

                                <span class="fw-bold">
                                 {{buro_stadistic?.psicometrico_R_manual_A}}
                                </span>
                            </div>

                        </div>
                    </div>



                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark fw-bold">Total
                                </span>

                                <span class="fw-bold text-primary">
                                 {{buro_stadistic?.buro_R_manual_A + buro_stadistic?.psicometrico_R_manual_A}}
                                </span>
                            </div>

                        </div>
                    </div>

                </ng-container>

            </div>

            <!-- <pre>{{ buro_stadistic | json }}</pre> -->
            <div *ngIf="!loading " class="mt-4 my-3 p-3 bg-body rounded shadow-sm">

                <h5 class="text-dark">Con cupo activo</h5>
                <div class="text-secondary small">Clientes aprobados con el cupo activo para usarlo nuevamente</div>
                <ng-container>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark">Sin compras
                                </span>

                                <span class="fw-bold">
                                 {{buro_stadistic?.sin_compras_A}}
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark">Con compras
                                </span>

                                <span class="fw-bold">
                                 {{buro_stadistic?.con_compras_A}}
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark fw-bold">Total
                                </span>

                                <span class="fw-bold text-primary">
                                 {{buro_stadistic?.compra_A}}
                                </span>
                            </div>

                        </div>
                    </div>

                </ng-container>

            </div>

            <div *ngIf="!loading " class="mt-4 my-3 p-3 bg-body rounded shadow-sm">

                <h5 class="text-dark">Con notas cargadas</h5>
                <div class="text-secondary small">Clientes aprobados con notas cargadas</div>
                <ng-container>


                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark">Buro
                                </span>

                                <span class="fw-bold">
                                    {{buro_stadistic?.compra_b}}
                                </span>
                            </div>

                        </div>
                    </div>


                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark">Psicometrico
                                </span>

                                <span class="fw-bold">
                                 {{buro_stadistic?.compra_p}}
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark fw-bold">Total
                                </span>

                                <span class="fw-bold text-primary">
                                 {{buro_stadistic?.compra_p + buro_stadistic?.compra_b}}
                                </span>
                            </div>

                        </div>
                    </div>

                </ng-container>

            </div>




            <!-- 
            <pre>
                {{ buro_stadistic | json }}
            </pre> -->
        </div>
    </div>
</div>