<div class="mt-md-3 container-fluid">

    <div class="row mt-2">
        <div class=" col-12 col-md-8 offset-md-2">
            <div class="row mt-2">
                <div class="col-12  col-md-8 offset-md-2">
                    <h5 class=" text-secondary text-center">
                        <i class="  fas fa-user"></i> Compras financiadas
                    </h5>
                </div>
            </div>





            <ng-container>
                <div class="row" *ngIf="!loading">

                    <div class="col-12  col-md-10 offset-md-1">
                        <div class="row mt-4">

                            <div class="col-12  col-md-6  mt-4">
                                <input style="
                            padding: 16px 9px;
                            background: white;
                        " id="demo-external" (onSet)="birthdaySet()" [(ngModel)]="range" mbsc-range class="form-control" [mbsc-options]="dateSetting3" #externalRange="mobiscroll" />

                            </div>
                        </div>
                        <div class="row">
                            <div class="mb-4 mt-2 col-12">

                                <div class="row" *ngIf="stadistic">
                                    <div class="col-12 col-md-6 mt-4">
                                        <div class="card" [routerLink]="['/console/financial-payments/']">
                                            <div class="card-body">
                                                <h5 class="card-title">Todas las tiendas</h5>

                                                <div class="mt-3">
                                                    <span class="badge text-bg-secondary me-2">Pendiente {{stadistic?.total?.pending || 0}}</span>
                                                    <span class="badge text-bg-dark">Completados {{stadistic?.total?.complete || 0}}</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngFor="let item of shops; let i=index">

                                        <div *ngIf="item?.visibility" class="col-12 col-md-6 mt-4">
                                            <div class="card" [routerLink]="['/console/financial-payments/',item.code]">
                                                <div class="card-body">
                                                    <h5 class="card-title">{{item?.name}}</h5>
                                                    <div class="mt-3">
                                                        <span class="badge text-bg-secondary me-2">Pendiente {{stadistic?.segment?.pending[item.code] || 0}}</span>
                                                        <span class="badge text-bg-dark">Completados {{stadistic?.segment?.complete[item.code] || 0}}</span>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="loading">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif" alt=" ">
                </div>
            </ng-container>

        </div>
    </div>
</div>