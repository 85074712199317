export const environment = {
    production: false,
    inactive: false,

    BASE_LA: 'https://dev2-api.creditotal.online',
    BASE_FORNT2: 'https://dev2-console.creditotal.online',
    BASE_FORNT: 'https://dev2-app.creditotal.online',
    redirectRoute:"/redirect",
    searchClient:"/console/create-constacts",
    psicometrico:"/console/psicometrico",
    initRoute:"/console/search-client",
  
    externalPsicometrico:'https://www.acredita.com.ve:8860/psicometrico/home_test.php',
  
    loginRoute:"/login",
    logoutRoute:"/logout",
  
     socketBase:{
      SOCKET_ENDPOINT: 'https://staging-socket-api.creditotal.online/'
     },
    
    ClieID:"12",
    CED:"11314836",
    IP_LOCAL:"159.203.105.202",

    ONBOARDING_LA : 'https://onboarding-api.akeela.co',
    ANALYSIS_REPORT : 'https://staging-analysis-report-node.creditotal.online',

    TRUSTX : 'https://trustx-dev-api.akeela.co'
  
  };
  
