<ng-container *ngIf="loading">
  <div class="w-100 text-center mt-3">
    <img src="./assets/public/images/loading.gif " alt=" " />
  </div>
</ng-container>
  <div class="card mt-3">
    <div class="card-body">
      <div class="form-group col-12">

        <h5><span *ngIf="code">Editar</span> <span *ngIf="!code">Nueva</span> Penalidad<br>Información básica</h5>
      </div>



      <form [formGroup]="formInitial">

        <div class="row pt-3">
          <div class="col-6">
            <div class="form-floating">
              <select (blur)="validateField1('status')" [ngClass]="{'is-invalid': hasError1('status')}" formControlName="status" class="form-select" aria-label="Floating label select example">
                <option value="ACTIVE">Activa</option>
                <option value="INACTIVE">Ináctiva</option>
              </select>
              <label for="status">Estatus</label>
              <div *ngIf="hasError1('status')" class="text-danger small mt-1">
                {{ getErrorMessage1('status') }}
              </div>
            </div>
          </div>
        </div>


        <div class="row pt-3">
          <div class="col-6">
            <div class="form-floating">
              <input (blur)="validateField1('title')" [ngClass]="{'is-invalid': hasError1('title')}" type="text" formControlName="title" class="form-control" />
              <label for="title">Título</label>
              <div *ngIf="hasError1('title')" class="text-danger small mt-1">
                {{ getErrorMessage1('title') }}
              </div>
            </div>
          </div>

          
          <div class="col-6">
            <div class="form-floating">
              <input (blur)="validateField1('codigo')" [ngClass]="{'is-invalid': hasError1('codigo')}" type="text" formControlName="codigo" class="form-control" />
              <label for="codigo">Código Penalidad</label>
              <div *ngIf="hasError1('codigo')" class="text-danger small mt-1">
                {{ getErrorMessage1('codigo') }}
              </div>
            </div>
          </div>



          


          <!-- <div class="col-6">
            <div class="form-floating">
              <input (blur)="validateField1('code')" [ngClass]="{'is-invalid': hasError1('code')}" type="text" formControlName="code" class="form-control text-capitalize" />
              <label for="code">Código</label>
              <div *ngIf="hasError1('code')" class="text-danger small mt-1">
                {{ getErrorMessage1('code') }}
              </div>
            </div>
          </div> -->
        </div>
      
        <div class="row pt-3">
          <div class="col-12">
            <div class="form-floating">
              <input (blur)="validateField1('description')" [ngClass]="{'is-invalid': hasError1('description')}" type="text" formControlName="description" class="form-control" />
              <label for="description">Descripción</label>
              <div *ngIf="hasError1('description')" class="text-danger small mt-1">
                {{ getErrorMessage1('description') }}
              </div>
            </div>
          </div>
        </div>
      
        <div class="row pt-3">
          
          <div class="form-group col-12">
            <div class="form-floating">
              <select (blur)="validateField1('application_type')" [ngClass]="{'is-invalid': hasError1('application_type')}" formControlName="application_type" class="form-select" aria-label="Floating label select example">
                <option value="">-- seleccione --</option>
                <option *ngFor="let category of list_categories" [value]="category._id" [innerHTML]="category.description"></option>
              </select>
              <label for="application_type">Aplicación</label>
              <div *ngIf="hasError1('application_type')" class="text-danger small mt-1">
                {{ getErrorMessage1('application_type') }}
              </div>
            </div>
          </div>
        </div>
      
        <div class="form-group col-12 mt-3">
          <h5>Aplicación</h5>
          <small class="text-secondary">Definición de la forma de deducción de la Penalidad, ¿Cuándo se podrá aplicar?</small>
        </div>
        <div class="row">
          <div class="mt-3" [class.col-4]="showFrequencyInputs" [class.col-12]="!showFrequencyInputs">
            <div class="form-floating">
              <select (blur)="validateField1('frequency')" [ngClass]="{'is-invalid': hasError1('frequency')}" formControlName="frequency" class="form-select" aria-label="Floating label select example">
                <option value="">-- seleccione --</option>
                <option *ngFor="let frecuency of list_fee_frecuencies" [value]="frecuency._id" [innerHTML]="frecuency.description"></option>
                
              </select>
              <label for="frequency">Frecuencia</label>
              <div *ngIf="hasError1('frequency')" class="text-danger small mt-1">
                {{ getErrorMessage1('frequency') }}
              </div>
            </div>
          </div>
          <div class="col-4 mt-3"  *ngIf="showFrequencyInputs===true">
            <div class="form-floating">
              <select (blur)="validateField1('unit_of_measure')" [ngClass]="{'is-invalid': hasError1('unit_of_measure')}" formControlName="unit_of_measure" class="form-select" aria-label="Floating label select example">
                <option value="">-- seleccione --</option>
                <option *ngFor="let measurement of list_fee_measurements" [value]="measurement._id" [innerHTML]="measurement.description"></option>

              </select>
              <label for="unit_of_measure">Unidad de Medida</label>
              <div *ngIf="hasError1('unit_of_measure')" class="text-danger small mt-1">
                {{ getErrorMessage1('unit_of_measure') }}
              </div>
            </div>
          </div>
          <div class="col-4 mt-3" *ngIf="showFrequencyInputs===true">
            <div class="form-floating">
              <input (blur)="validateField1('limit')" [ngClass]="{'is-invalid': hasError1('limit')}" type="text" formControlName="limit" class="form-control text-capitalize" />
              <label for="limit">Limite</label>
              <div *ngIf="hasError1('limit')" class="text-danger small mt-1">
                {{ getErrorMessage1('limit') }}
              </div>
            </div>
          </div>
        </div>
      </form>



    </div>
  </div>
  <div class="card mt-3">
    <div class="card-body">
      <!-- <div>Form values: {{ form.value | json }}</div> -->
      
      <div class="form-group col-12">
        <h5>Aplicación de las tarifas</h5>
      </div>
      <form [formGroup]="form">
      <div class="row pt-3">
      


      



      <div class="form-group col-12 mt-3">
        <h5>Condición</h5> 
        <div class="cursor-pointer float-start" >
          <div class="d-flex align-items-center">
            &nbsp;&nbsp;<div class="ml-5 form-check form-switch pb-3">
              <input class="ml-5  form-check-input fs-5" 
                  type="checkbox" 
                  formControlName="withCondition"
                  role="switch" >
            </div>      
          </div>
          </div>
        </div>
      </div>

      

        <ng-container *ngIf="showContainerConditions===true">
          <div class="row pt-3">
            <div class="col-3">
              <div class="form-floating">
                <select (blur)="validateField('attribute')" [ngClass]="{'is-invalid': hasError('attribute')}" class="form-select" formControlName="attribute">
                  <option value="">-- seleccione --</option>
                  <option *ngFor="let attribute of list_fee_attributes" [value]="attribute.key">{{ attribute.value }}</option>
                
                </select>
                <label>Atributo</label>
                <div *ngIf="hasError('attribute')" class="text-danger small mt-1">
                  {{ getErrorMessage('attribute') }}
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-floating">
                <select (blur)="validateField('condition')" [ngClass]="{'is-invalid': hasError('condition')}" class="form-select" formControlName="condition">
                  <option value="">-- seleccione --</option>
                  <option *ngFor="let comparison of list_comparison" [value]="comparison.key" [innerHTML]="comparison.simbol + ' ' +comparison.value "></option>
                </select>
                <label>Condición</label>
                <div *ngIf="hasError('condition')" class="text-danger small mt-1">
                  {{ getErrorMessage('condition') }}
                </div>
              </div>
            </div>


            <ng-container *ngIf="showBetweenInputs; else singleInput">
              <div class="col-3">
                <div class="form-floating">
                  <input (blur)="validateField('lower_limit')" [ngClass]="{'is-invalid': hasError('lower_limit')}" type="text" class="form-control text-capitalize" formControlName="lower_limit">
                  <label>Límite Inferior</label>
                  <div *ngIf="hasError('lower_limit')" class="text-danger small mt-1">
                    {{ getErrorMessage('lower_limit') }}
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="form-floating">
                  <input (blur)="validateField('upper_limit')" [ngClass]="{'is-invalid': hasError('upper_limit')}" type="text" class="form-control text-capitalize" formControlName="upper_limit">
                  <label>Límite Superior</label>
                  <div *ngIf="hasError('upper_limit')" class="text-danger small mt-1">
                    {{ getErrorMessage('upper_limit') }}
                  </div>
                </div>
              </div>
            </ng-container>


            <ng-template #singleInput>
              <div class="col-6">
                <div class="form-floating">
                  <input (blur)="validateField('single_value')" [ngClass]="{'is-invalid': hasError('single_value')}"  type="text" class="form-control text-capitalize" formControlName="single_value">
                  <label>Valor</label>
                  <div *ngIf="hasError('single_value')" class="text-danger small mt-1">
                    {{ getErrorMessage('single_value') }}
                  </div>
                </div>
              </div>
            </ng-template>
            
            



          </div>
        
          <div class="form-group col-12 mt-3">
            <h5>Tarifa</h5>
            <small class="text-secondary">Indique la tarifa que aplica para la condición</small>
          </div>

        </ng-container>
        <div class="row pt-3">
          <div class="col-6">
            <div class="form-floating">

              <select (blur)="validateField('commission_type')" [ngClass]="{'is-invalid': hasError('commission_type')}" class="form-select" formControlName="commission_type">
                <option value="">-- seleccione --</option>
                <option *ngFor="let application of list_fee_application_types" [value]="application.code">{{ application.description }}</option>
              </select>
              <label>Tipo de Penalidad</label>
              <div *ngIf="hasError('commission_type')" class="text-danger small mt-1">
                {{ getErrorMessage('commission_type') }}
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-floating">
              <select (blur)="validateField('currency')" [ngClass]="{'is-invalid': hasError('currency')}" class="form-select" formControlName="currency">
                <option value="USD">USD</option>
                <option value="BS">Bs</option>
              </select>
              <label>Moneda</label>
              <div *ngIf="hasError('currency')" class="text-danger small mt-1">
                {{ getErrorMessage('currency') }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">

          <ng-container *ngIf="showMixedCommission || showFixedCommission">
            <div class="col-6 mt-3">
              <div class="form-floating">
                <input (blur)="validateField('fixed_amount')" [ngClass]="{'is-invalid': hasError('fixed_amount')}" type="text" class="form-control text-capitalize" formControlName="fixed_amount">
                <label>Monto Fijo</label>
                <div *ngIf="hasError('fixed_amount')" class="text-danger small mt-1">
                  {{ getErrorMessage('fixed_amount') }}
                </div>
              </div>
            </div>
          </ng-container>
          
          <ng-container *ngIf="showMixedCommission || showPercentageCommission">
            <div class="col-6 mt-3">
              <div class="form-floating">
                <input (blur)="validateField('percentage')" [ngClass]="{'is-invalid': hasError('percentage')}" type="number" min="1" max="100" class="form-control text-capitalize" formControlName="percentage">
                <label>Porcentaje</label>
                <div *ngIf="hasError('percentage')" class="text-danger small mt-1">
                  {{ getErrorMessage('percentage') }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      
        <div class="form-group col-12 mt-3">
          <h5>Vigencia</h5>
          <small class="text-secondary">Si se deja la "Fecha tope vacía" no tendrá fecha de expiración hasta que se registre una actualización que sobreescriba la configuración</small>
        </div>

        <div class="row">
          <div class="col-6 mt-3">
            <div class="form-floating">
              <input (blur)="validateField('start_date')" [ngClass]="{'is-invalid': hasError('start_date')}" type="date" class="form-control text-capitalize" formControlName="start_date">
              <label>Fecha Inicio</label>
              <div *ngIf="hasError('start_date')" class="text-danger small mt-1">
                {{ getErrorMessage('start_date') }}
              </div>
            </div>
          </div>
          <div class="col-6 mt-3">
            <div class="form-floating">
              <input (blur)="validateField('end_date')" [ngClass]="{'is-invalid': hasError('end_date')}" type="date" class="form-control text-capitalize" formControlName="end_date">
              <label>Fecha tope</label>
              <div *ngIf="hasError('end_date')" class="text-danger small mt-1">
                {{ getErrorMessage('end_date') }}
              </div>
            </div>
          </div>
        </div>
      </form>


      
      <div class="form-group col-12 mt-3">
        <button class="btn btn-primary"   (click)="add()">Agregar</button>
      </div>

      <div class="card intern mt-3" *ngIf="listElements.length == 0">
        <div class="card-body text-center font-weight-bold "> No se han detallado tarifas</div>
      </div>

      

      <div class="row pt-3" *ngIf="listElements.length > 0">
        <div class="col-6" *ngFor="let elem of listElements">
              
    
    
                <div class="card mt-4 pb-2" >
                    <div class="card-body card-instru p-2 cursor-pounter">
                      <ng-container>
                        
                        <div class="text-primary fw-bold">
                            
                        </div>
    
                        <div class="d-flex mt-2" >
                            <div class="text-primary fw-bold fz-14" [innerHTML]="elem?.uuid"></div>
                            <div class="fw-bold fz-14 ms-auto">
                                <span class="badge badge-secondary">Active</span> &nbsp;&nbsp;
                                <div class="pr-2 cursor-pounter float-end ">
                                    <i  class="fas fa-times text-danger " style="font-size: 18px;cursor: pointer;" (click)="delete(elem?.uuid)"></i>
    
                                    
                                </div>
    
                            </div>
                          </div>
    
                        
    
                        <div class="d-flex mt-1 pb-2" >
                            <div class="font-weight-bold fz-14">{{ getValueByKey(list_fee_attributes,elem.attribute) }} {{ getValueByKey(list_comparison, elem.condition) }} <span *ngIf="elem.condition=='BETWEEN'">{{ elem.lower_limit }}  y {{ elem.upper_limit }}</span> <span *ngIf="elem.condition!='BETWEEN'">{{ elem.single_value }}</span>  </div>
                            
                        </div>
    
                        <div class="d-flex mt-2" >
                          <div class="text-secondary fz-12">Vigencia</div>
                          <div class="fw-bold fz-14 ms-auto">
                            {{ elem.start_date | date: 'dd/MM/yyyy' }} <span *ngIf="elem.end_date"> hasta {{ elem.end_date | date: 'dd/MM/yyyy' }}</span>
                          </div>
                        </div>
    
                       
      
                         
                        <div class="d-flex " >
                          <div class="text-secondary fz-12">Monto</div>
                          <div class="fw-bold fz-14 ms-auto">
                            {{ elem.currency }} {{ elem.fixed_amount }} <span *ngIf="elem.percentage"> + {{ elem.percentage }}% </span>
                          </div>
                        </div>
    
                       
    
                        
                        
                       
                        
                        
                        
                      </ng-container>
                      
                    </div>
                    
                  </div>
    
    
    
                
        </div>
      </div>


    </div>
  </div>
  <div class="card mt-3">
    <div class="card-body">
      <div class="form-group col-12 mt-3">
        <h5>Cuenta Contable</h5> Cuenta contable vinculada a SAP
      </div>
      <form [formGroup]="formAccount">
      <div class="row">
        <div class="col-4 mt-3">
          <div class="form-floating">
              <select  class="form-select" formControlName="account" aria-label="Floating label select example">
                <option  value="">-- seleccione --</option>
                <option  *ngFor="let cc of account_payments" [value]="cc._id">{{ cc?.payment_method?.name }}   -  {{ cc?.subscriptor_cuenta }} -  {{ cc?.subscriptor_simbolo }}  - {{ cc?.subscriptor_mp }}</option>
              </select>
              <label for="public">Cuenta Contable</label>

          </div>
          
        </div>
      </div>
    </form>

      
    </div>
  </div>
<br />
<div *ngIf="!loading" class="col-12 mt-4 mb-2">
  <!--this.list_comparison-->
  <button type="button" [disabled]="(!formInitial.valid || !formAccount.valid || listElements.length===0) || submit_disabled" class="btn btn-block btn-primary" (click)="submit()">
    <span>Guardar</span>
    <span *ngIf="submit_disabled">
      <i class="fa fa-spinner fa-spin ms-2"></i>
    </span>
  </button>
</div>
<!-- <pre>{{ formInitial.getRawValue() | json }}</pre>
<pre>{{ formAccount.getRawValue() | json }}</pre>
<pre>{{ listElements | json }}</pre> -->
<!-- <pre>{{ person | json }}</pre> -->