import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-accounts-admin',
  templateUrl: './accounts-admin.component.html',
  styleUrls: ['./accounts-admin.component.css']
})
export class AccountsAdminComponent implements OnInit {
  accountPayment:any;
  paymentMethods:any = [];
  paymentMethodsSelected = null;
  accountPaymentOrigin:any =[];

  accountPayment_load:any = true;

  constructor(
    private _user: UserService
  ) { }

  ngOnInit(): void {
    this.getPaymentMethods();
    
  }

  getPaymentMethods = async () =>  {
    try {
      let resp =  await this._user.payment_methods();
      this.paymentMethods = resp?.resp;
      this.paymentMethods = this.paymentMethods.filter((row: any) => row.admin);
      this.paymentMethodsSelected = this.paymentMethods[0].code;
      this.getAccountPayment();
    } catch (error) {
      console.log(error);
      this.paymentMethods = [];
    }
  
  }

  getAccountPayment = async () =>  {
    this.accountPayment_load = true;
    try {
      let resp =  await this._user.account_payment();
      this.accountPayment = resp?.res;
      this.accountPayment =this.accountPayment.filter((row: any) => row.payment_method.code === this.paymentMethodsSelected)
      this.accountPayment = this.accountPayment
      .filter((row: any) => row.payment_method.code === this.paymentMethodsSelected)
      .sort((a: any, b: any) => {
        if (a.typeConfirm === 'AUTO' && b.typeConfirm !== 'AUTO') return -1;
        if (b.typeConfirm === 'AUTO' && a.typeConfirm !== 'AUTO') return 1;
        if (a.showClient && !b.showClient) return -1;
        if (!a.showClient && b.showClient) return 1;
        return 0;
      });
      this.accountPayment_load = false;
    } catch (error) {
      console.log(error);
      this.accountPayment_load = false;
    }
  
  }

  changeStatusVerification = async (account_payment: string, typeConfirm: string) => {
    await this._user.updateVerificationStatus(account_payment, typeConfirm);
    this.getAccountPayment();
  }

  changeStatus = async (account_payment: string, showClient: boolean) => {
    await this._user.updateAccountStatus(account_payment, showClient);
    this.getAccountPayment();
  }

}
