import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import { MbscDatetimeOptions, mobiscroll } from '@mobiscroll/angular';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};

const now = new Date();

//const week = [ new Date(now.getFullYear(),0, 1),now];
let week = [ new Date() ,new Date()];


@Component({
  selector: 'app-dashboard-invice',
  templateUrl: './dashboard-invice.component.html',
  styleUrls: ['./dashboard-invice.component.css'],
  providers: [DatePipe]

})
export class DashboardInviceComponent implements OnInit {

  loading:any;
  shop:any;
  stadistic:any;
  shops:any;
  shops_obj:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService
  ) { }



 range: Array < Date > = week;
 date: Array < Date > = [];
 nonForm: Array < Date > = [];
 external = week;
 d1:any;
 d2:any; 


 dateSetting3: MbscDatetimeOptions = {
   lang: 'es',
   theme: 'ios',
   themeVariant: 'light',
   max:now,
   responsive: {
     small: {
         controls: ['calendar'],
         display: 'center'
     },
     medium: {
         display: 'bubble',
     }
 }
 
 };

 birthday = new Date();
date_base: any = new Date;
date_base_string :any;

mobileSettings: MbscDatetimeOptions = {
 display: 'bottom',
 themeVariant: 'dark',
};


desktopSettings: MbscDatetimeOptions = {
 display: 'bubble',
 touchUi: false
};



  ngOnInit(): void {
    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');

    this.getshops();
    this.stadisticGet();

  }


  stadisticGet = async () => {
    try {
      this.loading = true;

      let query =  {
        init:this.d1,
        end:this.d2
      };

      const resp = await this.getStadisticServer(query);

      this.stadistic = resp?.res;

        
    } catch (error) {
      console.error(error);



    } 
    this.loading = false;

   


  }



  getStadisticServer = async (query:any) => {
    try{
        return this._user.getstadistic_invice_status(query);

      } catch (error) {
        console.error(error);
        
    
      }
  }


getshops = async () => {
  try {

    const resp = await this.getshopsServer();
    this.shops = resp?.res;

    let shops_resp = resp?.res;
    let shops:any = {};


    shops_resp.map((value:any)=>{

      shops[value.code] = value?.name;

    });

    this.shops_obj = shops;
    
    
  } catch (error) {
    console.error(error);

  } 

 


}


getshopsServer = async () => {
  try{
      return this._user.getshops({});

    } catch (error) {
      console.error(error);
      
      
    }
}


birthdaySet() {  
    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');
    this.stadisticGet();
      
}
}
