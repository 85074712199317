<ng-template #optonsAcciones let-offcanvas>
    <div class="offcanvas-header">
        <div class=" w-100">
            <div class="col-12  ">
                <div class="row">
                    <div class="col-10">
                        <div class=" h5 text-secondary mt-2 ">
                            Seleccione la cuenta destino
                        </div>

                    </div>
                    <div class="col-2 text-end pt-2">
                        <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
                    </div>

                </div>

            </div>

        </div>
    </div>
    <div class="offcanvas-body">
        <div class="row">
            <div class="col-12  ">

                <ng-container *ngIf="accountPayment_load ">
                    <div class="w-100 text-center text-md-start">
                        <img src="./assets/public/images/loading.gif  " alt=" ">
                    </div>
                </ng-container>

                <div class="mt-2 " *ngIf="!accountPayment_load && accountPayment?.length < 1">
                    No se encontraron resultados
                </div>

                <div *ngIf="!accountPayment_load && accountPayment?.length > 0">
                    <div [ngClass]="{'active': account?.code == item?.code}" class="card mt-4 pb-2" *ngFor="let item of accountPayment">
                        <div class="card-body card-instru p-2 cursor-pounter" (click)="selectAccount(item)">
                            <ng-container>

                                <div class="titule-card-accion  fz-14 text-capitalize   fw-bold w-100 text-primary ">
                                    {{item?.bank?.name}}  <span class="badge-new" *ngIf="item?.tag">{{item?.tag}}</span>
                                </div>

                                <div class="d-flex mt-2" if>
                                    <div class="text-secondary  fz-12">
                                        Tipo
                                    </div>
                                    <div class="fw-bold fz-14 ms-auto">
                                        {{item?.payment_method?.name}}
                                    </div>
                                </div>

                                <div class="d-flex mt-1" if>
                                    <div class="text-secondary  fz-12">
                                        Moneda
                                    </div>
                                    <div class="fw-bold fz-14 ms-auto">
                                        {{item?.currency?.name}}
                                    </div>
                                </div>
                                <div class="d-flex mt-1" *ngIf="item?.taxId">
                                    <div class="text-secondary  fz-12">
                                        Rif
                                    </div>
                                    <div class="fw-bold fz-14 ms-auto">
                                        {{item?.taxId}}
                                    </div>
                                </div>
                                <div class="d-flex mt-1" *ngIf="item?.number">
                                    <div class="text-secondary  fz-12">
                                        Número
                                    </div>
                                    <div class="fw-bold fz-14 ms-auto">
                                        {{item?.number}}
                                    </div>
                                </div>

                                <div class="d-flex mt-1" *ngIf="item?.phone">
                                    <div class="text-secondary  fz-12">
                                        Teléfono
                                    </div>
                                    <div class="fw-bold fz-14 ms-auto">
                                        {{item?.phone}}
                                    </div>
                                </div>

                                <div class="d-flex mt-1" *ngIf="item?.email">
                                    <div class="text-secondary  fz-12">
                                        Email
                                    </div>
                                    <div class="fw-bold fz-14 ms-auto">
                                        {{item?.email}}
                                    </div>
                                </div>

                            </ng-container>
                        </div>

                    </div>
                </div>

            </div>
        </div>

    </div>

</ng-template>
<ng-template #view1 let-data>
    <div class=" mt-4">

        <div class="card  mt-4 ">
            <div class="p-2 card-instru card-body pt-3 cursor-pounter" (click)="openview(optonsAcciones)">

                <ng-container *ngIf="!account">

                    <i class="fas fa-angle-right text-primary float-end" style="font-size: 26px"></i>

                    <div>
                        <span class="ps-1">
                            Seleccione la cuenta destino
                                            </span>
                    </div>

                </ng-container>

                <ng-container *ngIf="account">


                    <div class="titule-card-accion  fz-14 text-capitalize text-dark w-100 fw-bold">
                        <i class="  fas fa-edit float-end text-primary "></i> {{account?.bank?.name}}
                    </div>

                    <div class="d-flex mt-2" if>
                        <div class="text-secondary  fz-12">
                            Tipo
                        </div>
                        <div class="fw-bold fz-14 ms-auto">
                            {{account?.payment_method?.name}}   <span class="badge badge-primary" *ngIf="account?.tag">{{account?.tag}}</span>
                        </div>
                    </div>

                    <div class="d-flex mt-1" if>
                        <div class="text-secondary  fz-12">
                            Moneda
                        </div>
                        <div class="fw-bold fz-14 ms-auto">
                            {{account?.currency?.name}}
                        </div>
                    </div>
                    <div class="d-flex mt-1" *ngIf="account?.taxId">
                        <div class="text-secondary  fz-12">
                            Rif
                        </div>
                        <div class="fw-bold fz-14 ms-auto">
                            {{account?.taxId}}
                        </div>
                    </div>
                    <div class="d-flex mt-1" *ngIf="account?.number">
                        <div class="text-secondary  fz-12">
                            Número
                        </div>
                        <div class="fw-bold fz-14 ms-auto">
                            {{account?.number}}
                        </div>
                    </div>

                    <div class="d-flex mt-1" *ngIf="account?.phone">
                        <div class="text-secondary  fz-12">
                            Teléfono
                        </div>
                        <div class="fw-bold fz-14 ms-auto">
                            {{account?.phone}}
                        </div>
                    </div>


                    <div class="d-flex mt-1" *ngIf="account?.email">
                        <div class="text-secondary  fz-12">
                            Email
                        </div>
                        <div class="fw-bold fz-14 ms-auto">
                            {{account?.email}}
                        </div>
                    </div>

                </ng-container>

            </div>


        </div>

        <div class="form-control-feedback help-block" *ngIf="!account && !this.form.pristine">
            <div>
                La cuenta es obligatoria
            </div>

        </div>
    </div>

    <ng-container *ngIf="account">


        <div class=" mt-4 mb-3 ">

            <ng-container *ngIf="rate_load ">
                <div class="alert alert-secondary p-2" style="background-color: #f8d7da3b" role="alert">
                    <span class="text-dark small">Consultando tasa (BCV)... </span>
                </div>
            </ng-container>

            <ng-container *ngIf="!rate_load && !rate_error">

                <!--   <div *ngIf="pec()">
                    <div class="alert alert-danger p-2" style="background-color: #f8d7da3b" role="alert">

                        <span class="text-dark">Pago excedido  </span>

                       <span class="text-danger" *ngIf="account?.currency?.code == 'BS'">{{ pexe() | currency:'USD':'': '1.2-2'}} BS</span>
                        <span class="text-danger" *ngIf="account?.currency?.code != 'BS'">{{ pexe()  | currency:'USD':'': '1.2-2'}} USD</span>


                        <span class="text-danger" *ngIf="account?.currency?.code == 'BS'">{{ amountFB_bs | currency:'USD':'': '1.2-2'}} BS</span>
                        <span class="text-danger" *ngIf="account?.currency?.code != 'BS'">{{ amountFB | currency:'USD':'': '1.2-2'}} USD</span>

                        <div class="small mt-2 mb-2">
                            En caso de haber excedido pago del monto total de la cuota, dicho monto sera aplicado a otra cuota faltante cuando sea conciliado dicho pago.
                            <a href=""> Politicas de pago</a>
                        </div>
                    </div>
                </div>-->

                <div>
                    <!-- <div [ngClass]="{'alert-primary': amountF > 0,'alert-success': amountF == 0}" class="alert alert-primary  p-2" role="alert"> -->

                    <div class="alert alert-primary  p-2" role="alert">

                        <div *ngIf="!paymentEdit && !multipago">
                            <span class="text-dark">Pago pendiente  </span>
                            <span *ngIf="account?.currency?.code == 'BS'">{{  amountFB_bs | currency:'USD':'': '1.2-2'}} BS</span>
                            <span *ngIf="account?.currency?.code != 'BS'">{{  amountFB | currency:'USD':'': '1.2-2'}} USD</span>
    
                            <div *ngIf="account?.currency?.code != 'BS'">
                                <span class="text-secondary fz-12 fw-bold ">
                                    IGTF Incluido  
                                    <span class="text-dark">3%</span>
                                </span>
    
                            </div>
    
    

                        </div>
                       
                        <div class="fz-12 text-secondary" *ngIf=" remanente?.payment_status_remante">Remanente de {{remanente?.remaining_amount}} USD incluido</div>




                        <div class="mt-1" *ngIf="account?.currency?.code == 'BS'">
                            <span class="text-secondary fz-12 fw-bold ">
                            Tasa de cambio USD 1 =
                            <span class="text-dark">BS {{rate?.amount | currency:'USD':'': '1.2-2'}}</span>
                            </span>
                        </div>

                    </div>
                </div>
            </ng-container>


        </div>



        <div class="d-block d-md-none mt-4">
            <mbsc-date (onSet)="dateSet($event)" [(ngModel)]="date_base" [options]="mobileSettings" placeholder="dd/mm/aaaa">Fecha de pago</mbsc-date>
        </div>
        <div class="d-none d-md-block mt-4">
            <mbsc-date (onSet)="dateSet($event)" [(ngModel)]="date_base" [options]="desktopSettings" placeholder="dd/mm/aaaa">Fecha de pago</mbsc-date>
        </div>

        <div *ngIf="rate_error" class="text-danger small p-1 ">
            Error al consultar tasa (BCV) a esta fecha
        </div>

    </ng-container>

    <form *ngIf="!rate_error" autocomplete="nope" novalidate [formGroup]="form" class="text-start">


        <div class="row mt-2" *ngIf="account">
            <div class="form-group  col-12  mt-4" *ngIf="account?.bank?.name != 'Zelle' && account?.payment_method.code != 'DEPOSITO_USD' && account?.payment_method.code != 'DEPOSITO_BS' && account?.type_customer != 'CUSTOMER'">
                <div class="form-floating">
                    <select [ngClass]="{'error': form.controls.origin_account.invalid && form.controls.origin_account.dirty}" class="form-select" id="origin_account" formControlName="origin_account" aria-label="Floating label select example">
                        <option  selected value="">Selecione una opción</option>
    
                        <option *ngFor="let item of banks; let i=index" [value]="item?.bankCode" >
    
                            {{ item.name }}
    
                        </option>
                       
                     </select>
                    <label for="origin_account">Cuenta origen</label>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form.get('origin_account').errors">
                    <div *ngIf="form.get('origin_account').errors['required'] && !form.get('origin_account').pristine">
                        Campo requerido
                    </div>
                </div>
            </div>


            <div *ngIf="account?.payment_method.code == 'TRANSFERENCIA' && account?.bank?.name != 'Zelle' && account?.type_customer != 'CUSTOMER'" class="form-group  col-12  mt-4">
                <label for="floatingref">Últimos 6 digitos del número de cuenta desde donde realizo el pago</label>

            </div>

            <div *ngIf="account?.payment_method.code == 'TRANSFERENCIA' && account?.bank?.name != 'Zelle' && account?.type_customer != 'CUSTOMER'" class="form-group  col-12  mt-3">

                <div class="form-floating mb-2">
                    <input (keyup)="validateAccountNumber()" [ngClass]="{'error': form.controls.accountNumber.invalid && form.controls.accountNumber.dirty}" formControlName="accountNumber" type="text" class="form-control" id="floatingref" placeholder="name@example.com">

                    <label for="floatingref">
                       
                        <span>
                            Número de cuenta (últimos 6)
                        </span>
                    </label>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form.get('accountNumber').errors">
                    <div *ngIf="form.get('accountNumber').errors['required'] && !form.get('accountNumber').pristine">
                        Campo requerido
                    </div>
                </div>

                <div *ngIf="!form.pristine && !validationAccountNumber" class="form-control-feedback help-block ">

                    Ingrese los últimos 6 digitos del número de cuenta
                </div>


            </div>

            <ng-container *ngIf="account">
                <div class="form-group  col-12  mt-3">
                    <!-- maxDuelbalance : {{maxDuelbalance()}} -->

                    <div class="form-floating ">
                        <input currencyMask (click)="moveCursorToEnd($event); validateTotalAmountMin()" [options]="{ prefix:account?.currency?.code+' ', thousands: ',', decimal: '.' }" type="text" [placeholder]="((amountF) | currency:'USD':'': '1.2-2') + 'USD'" (keyup)="changeMount(); validateTotalAmountMin()" [ngClass]="{'error': form.controls.total_amount.invalid && form.controls.total_amount.dirty}"
                            class="form-control text-capitalize" id="floatingmt" formControlName="total_amount">
                        <label for="floatingmt" class=" label font-weight-normal" style="word-wrap: break-word;">
              <span >Monto Total {{ validationMinAmount }}</span>
              

              
              
             </label>
                    </div>

                    
                        <div class="form-control-feedback help-block" *ngIf="!validationMinAmount">
                                <div>
                                    Monto debe ser mayor a {{ minAmount }}
                                </div>
                        </div>

                        <div class="form-control-feedback help-block" *ngIf="form.get('total_amount').errors ">
                            
                            <div *ngIf="form.get('total_amount').errors['required'] && !form.pristine">
                                Campo requerido
                            </div>

                            <div *ngIf="form.get('total_amount').errors['pattern']  && !form.pristine">
                                Monto inválido
                            </div>


                            <div class="form-control-feedback help-block" *ngIf="!(this.form?.getRawValue()?.total_amount > 0) && !form.pristine">
                                <div>
                                    Monto debe ser mayor a 0
                                </div>
                            </div>

                        </div>
<!-- 
                    <div class="form-control-feedback help-block" *ngIf="maxDuelbalance() ">
                        <div>
                            Monto maximo superado
                        </div>

                    </div>

-->

                    <!-- <div class="form-control-feedback help-block" *ngIf="pec() && !form.pristine">
                        <b> Monto Exedido</b> <br> En caso de haber excedido pago del monto total de la cuota, dicho monto sera aplicado a otra cuota faltante cuando sea conciliado dicho pago.
                    </div> -->

                </div>




                <div class="form-group  col-12  mt-3"  *ngIf=" account?.type_customer != 'CUSTOMER'">
                    <div class="form-floating mb-2">
                        

                        <ng-container  *ngIf="account?.bank?.name == 'Zelle'" >
                            <input [ngClass]="{'error': form.controls.reference.invalid && form.controls.reference.dirty}" formControlName="reference" type="text" class="form-control" id="floatingref"  placeholder="name@example.com">
                        </ng-container>

                        <ng-container  *ngIf="account?.bank?.name != 'Zelle'" >
                            <input (keypress)="validateNumericInput($event); validateNumberReference(this.reference_length, this.reference_length_max)"  (keyup)="validateNumericInput($event); validateNumberReference(this.reference_length, this.reference_length_max)" [ngClass]="{'error': form.controls.reference.invalid && form.controls.reference.dirty}" formControlName="reference" type="text" class="form-control" id="floatingref" [minlength]="reference_length"
                            [maxlength]="reference_length_max" placeholder="name@example.com" [pattern]="'^\\d*$'">
                        </ng-container>


                        
                        

                        <label for="floatingref">
                            <span  *ngIf="account?.bank?.name == 'Zelle'" >
                                Concepto colocado al realizar en la transacción
                            </span>
                            
                            <span  *ngIf="(account?.bank?.name != 'Zelle' ) ">
                                <span>
                                    Ultimos 8 digitos de la referencia del pago                                </span>
                            </span>

                            
                        </label>
                    </div>

                    <div class="form-control-feedback help-block" *ngIf="form.get('reference').errors">
                        <div *ngIf="form.get('reference').errors['required'] && !form.get('reference').pristine">
                            Campo requerido
                        </div>
                        <div *ngIf="form.controls.reference.errors?.minlength">Debe tener al menos {{reference_length}} caracteres.</div>
                        <div *ngIf="form.controls.reference.errors?.maxlength">No puede tener más de {{reference_length_max}} caracteres.</div>
                        <div *ngIf="form.controls.reference.errors?.pattern">Solo se permiten números.</div>
                    </div>


                    <!-- <span *ngIf="(account?.bank?.name != 'Zelle' &&  account?.payment_method.code != 'TRANSFERENCIA') ">
                        Ingrese la referencia
                    </span> -->

                </div>





                <div class="form-group  col-12  mt-3" *ngIf=" account?.type_customer != 'CUSTOMER'">
                    <label for="floatingref" *ngIf="account?.payment_method.code == 'TRANSFERENCIA' ">Identificación del dueño de la cuenta</label>
                </div>


                <div class="form-group  col-4  mt-1" *ngIf="account?.payment_method.code == 'TRANSFERENCIA' && account?.type_customer != 'CUSTOMER'">
                    <div class="form-floating">
                        <select [ngClass]="{'error': form.controls.code_identity.invalid && form.controls.code_identity.dirty}" class="form-select" id="code_identity" formControlName="code_identity" aria-label="Floating label select example">
                            <option  value="">Selecione</option>
                            <option  value="V">V</option>
                            <option  value="J">J</option>
                            <option  value="E">E</option>
                            <option  value="P">P</option>
                         </select>
                        <label for="code_identity">Tipo</label>
                    </div>

                    <div class="form-control-feedback help-block" *ngIf="form.get('code_identity').errors">
                        <div *ngIf="form.get('code_identity').errors['required'] && !form.get('code_identity').pristine">
                            Campo requerido
                        </div>
                    </div>
                </div>

                <div class="form-group  col-8  mt-1" *ngIf="account?.payment_method.code == 'TRANSFERENCIA' && account?.type_customer != 'CUSTOMER'">

                    <div class="form-floating ">
                        <input type="number" [ngClass]="{'error': form.controls.identity.invalid && form.controls.identity.dirty}" class="form-control text-capitalize" id="identity" formControlName="identity">
                        <label for="identity" class=" label font-weight-normal" style="word-wrap: break-word;">
              <span >Documento </span>
         </label>
                    </div>

                </div>


                <div *ngIf="account?.payment_method.code == 'PAGO_MOVIL' && account?.type_customer != 'CUSTOMER'" class="form-group  col-12  mt-4">
                    <label for="floatingref">Teléfono desde donde realizo el pago</label>
                </div>

                <div class="form-group  col-4  mt-1" *ngIf="account?.payment_method.code == 'PAGO_MOVIL'&& account?.type_customer != 'CUSTOMER'">
                    <div class="form-floating">
                        <select [ngClass]="{'error': form.controls.code_phone.invalid && form.controls.code_phone.dirty}" class="form-select" id="code_phone" formControlName="code_phone" aria-label="Floating label select example">
                            <option  value="">Selecione</option>
                            <option  value="0414">0414</option>
                            <option  value="0424">0424</option>
                            <option  value="0412">0412</option>
                            <option  value="0416">0416</option>
                            <option  value="0426">0426</option>
                            
                         </select>
                        <label for="code_phone">código</label>
                    </div>

                </div>

                <div *ngIf="account?.payment_method.code == 'PAGO_MOVIL' && account?.type_customer != 'CUSTOMER'" class="form-group  col-8  mt-1">

                    <div class="form-floating ">
                        <input (keyup)="validatePhone()" type="text" [ngClass]="{'error': form.controls.phone.invalid && form.controls.phone.dirty}" class="form-control text-capitalize" id="identity" formControlName="phone" placeholder="0000000">
                        <label for="phone" class=" label font-weight-normal" style="word-wrap: break-word;">
                        <span >Número </span>
         </label>
                    </div>
                </div>


                <div *ngIf="account?.payment_method.code == 'PAGO_MOVIL' &&  !form.pristine && !validationPhone && account?.type_customer != 'CUSTOMER'" class="form-control-feedback help-block ">

                    Número telefónico del dueño de la cuenta bancaria desde donde hizo el pago

                </div>


                <!-- <div class="form-group  mt-4">
                    <div class="form-floating">
                        <textarea [ngClass]="{'error': form.controls.description.invalid && form.controls.description.dirty}" class="form-control text-capitalize" id="description" formControlName="description" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                        <label for="floatingTextarea2">Descripción</label>
                    </div>
                </div> -->

            </ng-container>
        </div>
    </form>


    <ng-container *ngIf="account && !rate_error && account?.type_customer != 'CUSTOMER'">

        <div class=" mt-4">
            <mbsc-input [options]="mobileSettingsFile" (change)="onFileSelected($event)" type="file" placeholder="Adunte el soporte...">Adjunte el soporte</mbsc-input>
        </div>

        <div class="form-control-feedback help-block">
            <div *ngIf="!this.formDataUpload && !paymentEdit"  >
                El xlsx adjunto es obligatorio
            </div>


        </div>
    </ng-container>

</ng-template>


<ng-template #view2 let-data>
    <div class="text-secondary2 ">

        <ng-container>

            <div class="mb-4 text-center">

                Información del pago 

            </div>

            <div class="mb-4 text-center" *ngIf="account?.payment?.typeTransaction==='PENDING'">


            </div>

           

            <div class="mb-3 text-dark">

              Cuenta destino

            </div>

            <div class="d-flex ">
                <div>
                    <div>
                        {{account?.bank?.name}}
                    </div>

                    <div class="fz-12">
                        {{account?.payment_method?.name}} /
                        <span class=" " *ngIf="account?.number">
                            {{account?.number}}
                        </span>

                        <span class="" *ngIf="account?.phone">
                            {{account?.phone}}
                        </span>

                    </div>

                </div>
                <div class=" ms-auto text-dark fw-bold">
                    {{form?.getRawValue()?.total_amount}} {{account?.currency?.name}}
                </div>
            </div>

            <div class="mb-3 mt-4 text-dark">

               

            </div>


            <div class="mb-3 mt-4 text-dark">

                Detalle

            </div>



            <div class="d-flex mt-2" *ngIf="form?.getRawValue()?.origin_account">
                <div>
                    Codigo de cuenta origen
                </div>
                <div class="ms-auto fw-bold">
                    {{form?.getRawValue()?.origin_account}}
                </div>
            </div>



            <div class="d-flex mt-2" *ngIf="form?.getRawValue()?.identity">
                <div>
                    Identificación
                </div>
                <div class=" ms-auto fw-bold">
                    {{form?.getRawValue()?.code_identity}}-{{form?.getRawValue()?.identity}}
                </div>
            </div>


            <div class="d-flex mt-2" *ngIf="form?.getRawValue()?.accountNumber">
                <div>
                    Últimos 6 digitos del número de cuenta
                </div>
                <div class="ms-auto fw-bold">
                    {{form?.getRawValue()?.accountNumber}}
                </div>
            </div>

            <div class="d-flex mt-2" *ngIf="form?.getRawValue()?.phone">
                <div>
                   Teléfono
                </div>
                <div class="ms-auto fw-bold">
                    {{form?.getRawValue()?.code_phone}}  {{form?.getRawValue()?.phone}}
                </div>
            </div>

            <div class="d-flex mt-2" *ngIf="form?.getRawValue()?.reference">
                <div>
                   Referencia
                </div>
                <div class="ms-auto fw-bold">
                    {{form?.getRawValue()?.reference}}
                </div>
            </div>





        <div class="d-flex mt-2" *ngIf="form?.getRawValue()?.description">
            <div>
                Descripción
            </div>
            <div class="ms-auto ">

                {{form?.getRawValue()?.description}}
            </div>
        </div>

    </ng-container>

    </div>
</ng-template>


<ng-template #view4 let-data>
    <!-- <pre>{{ responseTransaction | json }}</pre> -->

    <div class="text-secondary2 ">

        <ng-container>

            <div class="mb-4 text-center">
                Verificación del pago
            </div>

            <div class="mb-3 text-center" *ngIf="responseTransaction?.type==='PENDING'">
                <div class="alert alert-warning p-2" style="background-color: #f8d7da3b" role="alert">
                    <span class="text-dark small"><span class="font-weight-bold">El pago está siendo validado, por favor espere unos minutos.</span>
                    </span>
                </div>
            </div>


            <div class="mb-3">

                <span class="badge text-bg-warning " *ngIf=" responseTransaction?.type == 'MANUAL' ">Pago por conciliar</span>
                <span class="badge text-bg-danger" *ngIf="responseTransaction?.type == 'AUTO'  &&  responseTransaction?.status == 'R'">Pago rechazado</span>
                <span class="badge text-bg-success" *ngIf="responseTransaction?.type == 'AUTO'  &&  responseTransaction?.status == 'A'">Pago aprobado</span>

                <div class="mb-3 text-dark fz-12 mt-2" *ngIf="responseTransaction?.descripcion">
                    {{responseTransaction?.descripcion}}
                </div>

            </div>


            <div class="mb-3 text-dark">

                Cuenta destino
  
              </div>
  
              <div class="d-flex ">
                  <div>
                      <div>
                          {{account?.bank?.name}}
                      </div>
  
                      <div class="fz-12">
                          {{account?.payment_method?.name}} /
                          <span class=" " *ngIf="account?.number">
                              {{account?.number}}
                          </span>
  
                          <span class="" *ngIf="account?.phone">
                              {{account?.phone}}
                          </span>
  
                      </div>
  
                  </div>
                  <div class=" ms-auto text-dark fw-bold">
                      {{form?.getRawValue()?.total_amount}} {{account?.currency?.name}}
                  </div>
              </div>
  
              <div class="mb-3 mt-4 text-dark">
  
                 
  
              </div>
  
  
              <div class="mb-3 mt-4 text-dark">
  
                  Detalle
  
              </div>
  
  
  
              <div class="d-flex mt-2" *ngIf="form?.getRawValue()?.origin_account">
                  <div>
                      Codigo de cuenta origen
                  </div>
                  <div class="ms-auto fw-bold">
                      {{form?.getRawValue()?.origin_account}}
                  </div>
              </div>
  
  
  
              <div class="d-flex mt-2" *ngIf="form?.getRawValue()?.identity">
                  <div>
                      Identificación
                  </div>
                  <div class=" ms-auto fw-bold">
                      {{form?.getRawValue()?.code_identity}}-{{form?.getRawValue()?.identity}}
                  </div>
              </div>
  
  
              <div class="d-flex mt-2" *ngIf="form?.getRawValue()?.accountNumber">
                  <div>
                      Últimos 6 digitos del número de cuenta
                  </div>
                  <div class="ms-auto fw-bold">
                      {{form?.getRawValue()?.accountNumber}}
                  </div>
              </div>
  
              <div class="d-flex mt-2" *ngIf="form?.getRawValue()?.phone">
                  <div>
                     Teléfono
                  </div>
                  <div class="ms-auto fw-bold">
                      {{form?.getRawValue()?.code_phone}}  {{form?.getRawValue()?.phone}}
                  </div>
              </div>
  
              <div class="d-flex mt-2" *ngIf="form?.getRawValue()?.reference">
                  <div>
                     Referencia
                  </div>
                  <div class="ms-auto fw-bold">
                      {{form?.getRawValue()?.reference}}
                  </div>
              </div>
  
  
  
  
  
          <div class="d-flex mt-2" *ngIf="form?.getRawValue()?.description">
              <div>
                  Descripción
              </div>
              <div class="ms-auto ">
  
                  {{form?.getRawValue()?.description}}
              </div>
          </div>
  



        </ng-container>


        <div class="d-flex mt-2" *ngIf="form?.getRawValue()?.description">
            <div>
                Descripción
            </div>
            <div class="ms-auto ">

                {{form?.getRawValue()?.description}}
            </div>
        </div>

        <div class="mb-3 mt-4 text-dark" *ngIf="responseTransaction?.voucher">
            Voucher
        </div>

        <div class="row" *ngIf="responseTransaction?.voucher">
            <div class="col-12 col-md-6">
                <div class="voucher p-2 text-dark text-uppercase" [innerHTML]="responseTransaction?.voucher">

                </div>

            </div>
        </div>

    </div>
</ng-template>











<ng-template #view5 let-data>
    <div class="conf">

        <ng-container *ngIf="account">

            <div class="titule-card-accion mt-4  text-capitalize text-primary w-100 fw-bold">
                {{account?.bank?.name}}
            </div>

            <div class="d-flex mt-4">
                <div class="text-secondary ">
                    Tipo
                </div>
                <div class="fw-bold  ms-auto">
                    {{account?.payment_method?.name}}
                </div>
            </div>

            <div class="d-flex mt-4">
                <div class="text-secondary ">
                    Moneda
                </div>
                <div class="fw-bold ms-auto">
                    {{account?.currency?.name}}
                </div>
            </div>
            <div class="d-flex mt-4" *ngIf="account?.taxId">
                <div class="text-secondary  ">
                    Rif
                </div>
                <div class="fw-bold ms-auto">
                    {{account?.taxId}}
                </div>
            </div>
            <div class="d-flex mt-4" *ngIf="account?.number">
                <div class="text-secondary  ">
                    Número
                </div>
                <div class="fw-bold fz-14 ms-auto">
                    {{account?.number}}
                </div>
            </div>

            <div class="d-flex mt-4" *ngIf="account?.phone">
                <div class="text-secondary  ">
                    Teléfono
                </div>
                <div class="fw-bold fz-14 ms-auto">
                    {{account?.phone}}
                </div>
            </div>

            <div class="d-flex mt-4" *ngIf="account?.email">
                <div class="text-secondary  ">
                    Email
                </div>
                <div class="fw-bold fz-14 ms-auto">
                    {{account?.email}}
                </div>
            </div>

        </ng-container>

        <div class="d-flex mt-4">
            <div>
                Monto Total </div>
            <div class="ms-auto text-start fw-bold">

                {{form?.getRawValue()?.total_amount}}
            </div>

        </div>


        <div class="d-flex mt-4">
            <div>
                Referencia
            </div>
            <div class="ms-auto text-start fw-bold" *ngIf="form?.getRawValue()?.reference">

                {{form?.getRawValue()?.reference}}
            </div>

        </div>


        <div class="d-flex mt-4">
            <div>
                Descripción
            </div>
            <div class="ms-auto text-start fw-bold" *ngIf="form?.getRawValue()?.description">

                {{form?.getRawValue()?.description}}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #view3 let-data>

    <div class="h5 mt-4 mb-3">Ordern confirmada</div>

    <div class="conf">

        <ng-container *ngIf="account">

            <div class="titule-card-accion mt-4  text-capitalize text-primary w-100 fw-bold">
                {{account?.bank?.name}}
            </div>

            <div class="d-flex mt-4">
                <div class="text-secondary ">
                    Tipo
                </div>
                <div class="fw-bold  ms-auto">
                    {{account?.payment_method?.name}}
                </div>
            </div>

            <div class="d-flex mt-4">
                <div class="text-secondary ">
                    Moneda
                </div>
                <div class="fw-bold ms-auto">
                    {{account?.currency?.name}}
                </div>
            </div>
            <div class="d-flex mt-4" *ngIf="account?.taxId">
                <div class="text-secondary  ">
                    Rif
                </div>
                <div class="fw-bold ms-auto">
                    {{account?.taxId}}
                </div>
            </div>
            <div class="d-flex mt-4" *ngIf="account?.number">
                <div class="text-secondary  ">
                    Número
                </div>
                <div class="fw-bold fz-14 ms-auto">
                    {{account?.number}}
                </div>
            </div>

            <div class="d-flex mt-4" *ngIf="account?.phone">
                <div class="text-secondary  ">
                    Teléfono
                </div>
                <div class="fw-bold fz-14 ms-auto">
                    {{account?.phone}}
                </div>
            </div>

            <div class="d-flex mt-4" *ngIf="account?.email">
                <div class="text-secondary  ">
                    Email
                </div>
                <div class="fw-bold fz-14 ms-auto">
                    {{account?.email}}
                </div>
            </div>

        </ng-container>

        <div class="d-flex mt-4">
            <div>
                Monto Total </div>
            <div class="ms-auto text-start fw-bold">

                {{form?.getRawValue()?.total_amount}}
            </div>

        </div>


        <div class="d-flex mt-4">
            <div>
                Referencia
            </div>
            <div class="ms-auto text-start fw-bold" *ngIf="form?.getRawValue()?.reference">

                {{form?.getRawValue()?.reference}}
            </div>

        </div>


        <div class="d-flex mt-4">
            <div>
                Descripción
            </div>
            <div class="ms-auto text-start fw-bold" *ngIf="form?.getRawValue()?.description">

                {{form?.getRawValue()?.description}}
            </div>
        </div>

        <div class="d-flex mt-4 text-success">
            <div>
                Código del pago
            </div>
            <div class="ms-auto text-start fw-bold">
                {{ordernumber}}

            </div>

        </div>
    </div>
</ng-template>



<div class="container  mt-2">

    <div class="row rmt-2" *ngIf="!loading">
        <div class="col-12  ">

            <h5 class="mb-1 text-secondary mb-4 text-center">
                <i class="  fas fa-user"></i> Reportar pago

               
            </h5>

            <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && !person?.person?.file_selfie" class="ms-auto me-auto mt-4 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                <img *ngIf="person?.person.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                <img *ngIf="person?.person.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">
            </div>

            <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && person?.person?.file_selfie" class="ms-auto me-auto mt-4 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                <img class="rounded-circle  " [src]="sanitize(person?.person?.file_selfie?.url)">
            </div>


            <div *ngIf="person?.person" class="text-center mb-3">
                <div class="text-capitalize h4 text-dark">{{person?.person?.name}} {{person?.person?.lastName}}</div>
                <div> {{getIdentity(person)}}</div>
                <div> {{getContact(person)}}</div>
            </div>


        </div>
    </div>



    <div class="row ">

        <div class="col-12 col-md-6 offset-md-3 text-dark mt-2 ">


            <div class="mt-4 my-3 p-3 bg-body r10 shadow-sm">

                <div class="text-secondary mt-2 mt-2 mb-3  ">
                    <ng-container *ngIf="selectView == 1">
                        <h5 class="fw-bolder text-dark  ">



                            <div class=" text-dark float-end fz-12 fw-normal" *ngIf="installment">
                                <div class=" text-secondary ">Fecha de pago</div>

                                {{installment?.scheduledPaymentDate | date: 'dd/MM/yyyy'}}

                            </div>

                            Registrar pago

                            <!-- <div class="alert-2 alert-warning-2  mt-2 p-2" role="alert" *ngIf="person?.resumen?.left_days">
                                <div>

                                    <div class="d-flex mt-2" >
                                        <div class="fw-bold  fz-12 pb-2">
                                            Cuota Vencida
                                            {{ person?.resumen?.left_days }}
                                        </div>
                                        
                                    </div>
                                </div>
                            </div> -->



                            <div class="alert-2 alert-warning-2  mt-2 p-2" role="alert" *ngIf="concept_deduction_apply.length>0">
                                


                               



                                <div>

                                    <div class="d-flex mt-2" *ngIf="person?.resumen?.monto_adeudado_vencido">
                                        <div class="fw-bold  fz-12">
                                            Deuda acumulada
                                        </div>
                                        <div class="fw-bold fz-14 ms-auto">
                                            <span class="text-secondary">{{ person?.resumen?.monto_adeudado_vencido.toFixed(2) }} USD</span>
                                        </div>
                                    </div>
                                    <div *ngFor="let fee of concept_deduction_apply">

                                        <div class="d-flex mt-2" >
                                            <div class="  fz-12">
                                                <span class="fw-bold">{{ fee?.fee?.concept_deduction?.name }}</span><br>
                                                <span class="text-secondary">{{ fee?.invoice }} / Cuota {{ fee?.order }}</span>
                                            </div>
                                            <div class="fw-bold fz-14 ms-auto">
                                                <span class="text-secondary" *ngIf="fee?.fee?.commission_type==='FIXED'">{{ fee?.fee?.fixed_amount }} USD</span>
                                            </div>
                                        </div>
    
                                       
                                    </div>



                                    

                                    


                                    <div class="d-flex mt-2" >
                                       
                                        <div class="text-dark  fz-16">
                                            Total
                                        </div>
                                        <div class="fw-bold fz-16 ms-auto">
                                            <span class="text-dark" *ngIf="account?.currency?.code != 'USD'"> Bs.{{ (rate?.amount.toFixed(2) * (deuda_total)) | currency:'BS':'': '1.2-2' }}</span>
                                            <span class="text-dark" *ngIf="account?.currency?.code == 'USD'">{{ deuda_total | currency:'USD':'': '1.2-2' }} USD  </span>
                                        </div>
                                    </div>


                                    <!-- <span class="text-dark">Monto adeudado  </span>
                                    
                                    <div>
                                        <span class="text-secondary fz-12 fw-bold ">  
                                            <span class="text-dark">{{ person?.resumen?.monto_adeudado_vencido }} USD</span>
                                        </span>
                                    </div> -->
                                </div>
                               
                               
        
                            </div>



                            





                            
                        
                            <div class="float-end"> 
                            <button *ngIf="responseTransaction != true && paymentEdit" 
                            type="button " class="btn btn-secondary btn-sm" (click)="goBack()">
                            <span>
                                Cancelar
                            </span>
                        
                            </button>
                        </div></h5>
                        <div class=" text-secondary fz-14 " *ngIf="installment ">

                            {{ installment?.invoice?.identifier}} / {{ installment?.order
                            < 1 ? 'Inicial': 'cuota '+ installment?.order}}</div>


                                <ng-container class="text-capitalize " *ngTemplateOutlet="view1 "></ng-container>
                    </ng-container>

                    
                    <ng-container *ngIf="selectView==4 ">
                        <a *ngIf="!paymentEdit && responseTransaction?.type != 'MANUAL'  &&  responseTransaction?.status != 'A' && responseTransaction?.type!=='PENDING'" class="text-primary mb-2" (click)="selectView=1">volver</a>
                        <ng-container *ngTemplateOutlet="view4"></ng-container>
                    </ng-container>


                      <ng-container *ngIf="selectView==2 ">
                        <ng-container *ngTemplateOutlet="view2"></ng-container>
                    </ng-container>

                    <div class=" mt-5 mb-5 ">
                        <div class="col-12  ">
                            <div class=" d-grid gap-2">

                                <ng-container *ngIf="!confirmLoading ">
                                    <button *ngIf="selectView == 1 && !confirm  || rate_error" [disabled]="
                                    !(form?.getRawValue()?.total_amount > 0 ) || 
                                   
                                    (account?.payment_method.code == 'PAGO_MOVIL' &&  !validationMinAmount && !validationPhone && account?.type_customer != 'CUSTOMER') || 
                                    (account?.bank?.name != 'Zelle' &&  !validationReference && account?.type_customer != 'CUSTOMER') || 
                                    (!this.formDataUpload && account?.type_customer != 'CUSTOMER' && !paymentEdit )
                                    
                                    ||
                                     rate_error
                           "
                                        [ngClass]="{ 'btn-success':equal(selectView,selectMaxView) , 'btn-primary':!equal(selectView,selectMaxView) } " type="button " class="btn " (click)="nextStep() ">

                                        <span >
                                             Siguiente {{selectView}}/{{selectMaxView}}
                                        </span>
                                      
                                    </button>

                                    <button *ngIf="selectView == 2  && !confirm " [disabled]="
                                    !(form?.getRawValue()?.total_amount > 0 ) || 
                                   
                                    (account?.payment_method.code == 'PAGO_MOVIL' &&  !validationPhone && !validationMinAmount && account?.type_customer != 'CUSTOMER') || 
                                    (account?.bank?.name != 'Zelle' &&  !validationReference && account?.type_customer != 'CUSTOMER') || 
                                    (!this.formDataUpload && account?.type_customer != 'CUSTOMER' && !paymentEdit ) ||
                                     rate_error "
                                        [ngClass]="{ 'btn-success':equal(selectView,selectMaxView) , 'btn-primary':!equal(selectView,selectMaxView) } " type="button " class="btn " (click)="nextStep() ">

                                        <span  >
                                            Confirmar {{selectView}}/{{selectMaxView}}
                                        </span>
                                    </button>

                                    <button *ngIf="confirm && responseTransaction?.status == 'A' ||  responseTransaction?.type == 'MANUAL' || responseTransaction?.type==='PENDING' " [disabled]="!this.formDataUpload && account?.type_customer != 'CUSTOMER'" [ngClass]="{ 'btn-success':equal(selectView,selectMaxView) , 'btn-primary':!equal(selectView,selectMaxView) } "
                                        type="button " class="btn " (click)="redirectTo()">
                                    
                                        <span  >
                                            Continuar
                                        </span>
                                    
                                    </button>

                                   
                                </ng-container>

                                <button *ngIf=" responseTransaction === true" [ngClass]="{ 'btn-success':equal(selectView,selectMaxView) , 'btn-primary':!equal(selectView,selectMaxView) } "
                                        type="button " class="btn " (click)="goBack()">
                                    
                                        <span>
                                            Continuar
                                        </span>
                                    
                                </button>

                                
                               




                                <ng-container *ngIf="confirmLoading ">
                                    <div class="w-100 text-center text-md-start ">
                                        <img src="./assets/public/images/loading.gif " alt=" ">
                                    </div>
                                </ng-container>
                            </div>

                        </div>

                        <div class="mt-4 text-dark small" *ngIf="!equal(selectView,selectMaxView) && !confirm ">
                            Si tienes algún problema con el reporte de pago, por favor contáctanos por WhatsApp al número <span (click)="gotoWhatsapp('+584128809225')" class="text-primary cursor-pounter">+58 412-880-9225</span>. Estamos aquí para ayudarte.
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <pre>{{ form.getRawValue() | json }}</pre> -->