<div class="row">
    <div class="col-12 mt-2">
        <div class="mt-2 mb-5">

            <form *ngIf="!loadForm" autocomplete="nope" novalidate [formGroup]="form" class="text-start">
                <div class="row">

                    <div class="form-group  col-12 ">
                        <div class="form-floating">
                            <select [ngClass]="{'error': form.controls.type.invalid && form.controls.type.dirty}" class="form-select" id="type" formControlName="type" aria-label="Floating label select example">
                                <option  value="">Selecione</option>
                                <option *ngFor="let item of type"  [value]="item.code">{{item.name}}</option> 

                            </select>

                            <label for="type">Tipo</label>
                        </div>

                        <div class="form-control-feedback help-block" *ngIf="form.get('type').errors">
                            <div *ngIf="form.get('type').errors['required'] && !form.get('type').pristine">
                                Campo requerido
                            </div>
                        </div>

                    </div>


                    <div class="form-group  col-12 mt-3">
                        <div class="form-floating">
                            <select [ngClass]="{'error': form.controls.country.invalid && form.controls.country.dirty}" class="form-select" id="country" formControlName="country" aria-label="Floating label select example">
                                <option  value="">Selecione</option>
                                 <option *ngFor="let item of country"  [value]="item.code">{{item.description}}</option> 
                            </select>

                            <label for="country">País</label>
                        </div>

                        <div class="form-control-feedback help-block" *ngIf="form.get('country').errors">
                            <div *ngIf="form.get('country').errors['required'] && !form.get('country').pristine">
                                Campo requerido
                            </div>
                        </div>
                    </div>

                    <div class="form-group  col-12 mt-3">
                        <div class="form-floating">
                            <select [ngClass]="{'error': form.controls.city.invalid && form.controls.city.dirty}" class="form-select" id="city" formControlName="city" aria-label="Floating label select example">
                                <option  value="">Selecione</option>
                                <option *ngFor="let item of city"  [value]="item.code">{{item.name}}</option> 
                            </select>

                            <label for="city">Ciudad</label>
                        </div>

                        <div class="form-control-feedback help-block" *ngIf="form.get('city').errors">
                            <div *ngIf="form.get('city').errors['required'] && !form.get('city').pristine">
                                Campo requerido
                            </div>
                        </div>

                    </div>

                    <div class="form-group  col-12 mt-3">
                        <div class="form-floating">
                            <select [ngClass]="{'error': form.controls.state.invalid && form.controls.state.dirty}" class="form-select" id="state" formControlName="state" aria-label="Floating label select example">
                                <option  value="">Selecione</option>
                                <option *ngFor="let item of state"  [value]="item.code">{{item.name}}</option> 
                            </select>

                            <label for="state">Estado</label>
                        </div>

                        <div class="form-control-feedback help-block" *ngIf="form.get('state').errors">
                            <div *ngIf="form.get('state').errors['required'] && !form.get('state').pristine">
                                Campo requerido
                            </div>
                        </div>

                    </div>

                    <div class="form-group  mt-3">
                        <div class="form-floating">
                            <textarea [ngClass]="{'error': form.controls.description.invalid && form.controls.description.dirty}" class="form-control " id="description" formControlName="description" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                            <label for="floatingTextarea2">Descripción</label>
                        </div>
                    </div>

                    <br>
                    <div class="form-group  col-12 mt-4 mb-2">
                        <div class=" float-end">
                            <button (click)="submit()" [disabled]="!form.valid || submit_disabled" class="btn btn-block btn-primary ">
                        <span >Agregar</span>
                        <span *ngIf="submit_disabled">
                          <i class="fa  fa-spinner fa-spin ms-2"></i>
                        </span>
                      </button>
                        </div>
                    </div>




                </div>

            </form>
            <ng-container *ngIf="loadForm || loadPerson_Address">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>
        </div>



        <div class="card mt-3" *ngFor="let item of personAddress; let i=index">
            <div class="card-body">

                <div class="d-flex">
                    <div>
                        <div class="card-title fw-bold">{{item?.address_type?.name}}</div>
                        <p class="card-text">
                            {{item?.address?.country?.description}}, {{item?.address?.state?.name}}, {{item?.address?.city?.name}}, {{item?.address?.description}}</p>
                    </div>

                    <div *ngIf="!estatus_approve || _auth.IsPermitid(['OWNER'])" class="ms-auto text-end mt-4">
                        <a class="btn btn-outline-danger btn-sm" (click)="delete_address_person(item?.code)">Eliminar</a>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>