<ng-template #content let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                                {{user_options?.person?.name}} {{user_options?.person?.lastName}}
                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 col-md-6 offset-md-3 mt-4">
                    <!-- <pre>{{ user_options | json }}</pre> -->


                    <div class="d-flex justify-content-between">

                        <div>
                            <div class=" fw-normal ">
                                Estatus <span class="text-secondary small">({{usQuote?"Activo":"Inactivo"}})</span>
                            </div>
                        </div>

                        <div>
                            <ng-toggle (change)="toogleStatus()" [(ngModel)]="usQuote" [value]="true" [color]="{
                                checked: '#01abce',
                                unchecked: '#dcdcdc'
                            }"></ng-toggle>

                        </div>


                    </div>

                    <div class="d-flex justify-content-between mt-4">

                        <div class="input-group ">

                            <div class="form-floating">
                                <select [(ngModel)]="shop" class="form-select" id="gender" aria-label="Floating label select example">
                                    <option  value="">Selecione</option>
                                    <option *ngFor="let item of shops"  [value]="item.code">{{item.name}}</option>

                                </select>
                                <label for="gender">Tienda</label>
                            </div>
                            <button (click)="changeShop()" [disabled]=" submit_disabled_shop" class="btn btn-primary">
                                Guardar
                                <span *ngIf="submit_disabled_shop">
                                <i class="fa  fa-spinner fa-spin"></i>
                                </span>
                            </button>

                        </div>

                    </div>

                    <div class="d-flex justify-content-between mt-4">

                        <div class="input-group">

                            <div class="form-floating ">
                                <input type="password" [(ngModel)]="password" class="form-control " id="contact" placeholder="******">
                                <label for="contact" class=" label font-weight-normal" style="word-wrap: break-word;">
                                    <span >Nuevo Password</span>
                                </label>
                            </div>
                            <button (click)="changePass()" [disabled]="!password || submit_disabled_contact" class="btn btn-primary">
                                Cambiar
                                <span *ngIf="submit_disabled_contact">
                                <i class="fa  fa-spinner fa-spin"></i>
                                </span>
                            </button>

                        </div>

                    </div>

                    <div class="d-flex justify-content-between mt-4">

                        <div class="input-group ">

                            <div class="form-floating">
                                <select [(ngModel)]="profile" class="form-select" id="gender" aria-label="Floating label select example">
                                    <option  value="">Selecione</option>
                                    <option  value="OWNER">Owner</option>
                                    <option  value="ANALIST">Analista</option>
                                    <option  value="EJECUTIVE">Promotor</option>
                                    <option  value="FINANCIAL">Financiero</option>
                                    <option  value="ADMIN">Administrador</option>
                                </select>
                                <label for="gender">Perfil</label>
                            </div>

                            <button (click)="changeProfile()" [disabled]=" submit_disabled_profile" class="btn btn-primary">
                                Guardar
                                <span *ngIf="submit_disabled_profile">
                                <i class="fa  fa-spinner fa-spin"></i>
                                </span>
                            </button>

                        </div>

                    </div>


                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="container">

    <div class="mt-md-3 p-3">

        <div class="row ">
            <div class=" col-12 col-md-6 offset-md-3  p-0 ">
                <h5 class="mb-4 text-secondary mb-5 text-center">
                    <i class="  fas fa-users"></i> Usuarios/Tablet
                    <!-- <div class="mt-1 float-md-end">
                        <button type="button " class="btn btn-outline-primary primary  " (click)="openBottom(content,{})">Crear Cliente</button>
                    </div> -->
                </h5>

                <!-- <div class="form-group col-12 mt-5 mb-3 ">
                    <br>

                    <div class="input-group ">

                        <div class="form-floating ">
                            <input (keyup)="search_form_method()" type="text" [(ngModel)]="search_form" class="form-control " id="contact" placeholder="name@example.com">
                            <label for="contact" class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span >Cédula, Nombe, Apellido
                            </span>
                        </label>
                        </div>
                       <button (click)="search_form_method()" [disabled]=" submit_disabled" class="btn btn-primary">
                        Buscar
                        <span *ngIf="submit_disabled">
                        <i class="fa  fa-spinner fa-spin"></i>
                        </span> 
                        </button>
                    </div>

                </div> -->



                <!-- <div>
                    <span class="me-3 text-dark cursor-pounter" [ngClass]="{'text-primary': role == 'USER_ROLE'}" (click)=" role = 'USER_ROLE'">Usuarios</span>
                    <span class="text-dark cursor-pounter" [ngClass]="{'text-primary': role == 'COMPUTER_ROLE'}" (click)=" role = 'COMPUTER_ROLE'">Tablet</span>
                </div> -->
                <div class="d-flex justify-content-between">
                    <div class="pt-3">
                        <span class=" pe-2 text-secondary cursor-pounter" [ngClass]="{'fw-bold': status_users}" (click)="status_users = true;getUsers(true,true)"> Activos</span>
                        <span class=" text-secondary cursor-pounter" [ngClass]="{'fw-bold': !status_users}" (click)="status_users = false;getUsers(true,true)"> Invactivos</span>

                    </div>
                    <div class="mb-2 text-end">
                        <button class="btn  btn-primary  " [routerLink]="['/register']">Nuevo User/Tablet</button>
                    </div>
                </div>



                <div *ngIf="people " class="mt-3 pt-4 my-3 p-3 bg-body rounded shadow-sm">

                    <h6 class="border-bottom pb-2 mb-0">Nombre

                        <div class="float-end text-secondary"> {{rows.length}} de {{people_total}}</div>
                    </h6>
                    <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScrollDown()" (scrolledUp)="onScrollUp()">
                        <ng-container>

                            <!-- <pre> {{ rows | json }}</pre> -->
                            <div *ngFor="let item of rows ; let i=index" class="d-flex text-muted pt-4">

                                <div *ngIf="item?.person?.file_selfie">

                                    <div [ngClass]="{'active': usersActives[item?.person?.code]}" style=" background-image: url({{item?.person?.file_selfie?.url}}); " class=" flex-shrink-0 me-2 base-img ">
                                    </div>
                                    <!-- <img [ngClass]="{'active': usersActives[item?.person?.code]}" class="bd-placeholder-img flex-shrink-0 me-2 base-img " [src]=""> -->
                                </div>

                                <div *ngIf="!item?.person?.file_selfie">

                                    <div *ngIf="item?.user?.role?.role == 'COMPUTER_ROLE'" [ngClass]="{'active': usersActives[item?.person?.code]}" style=" background-image: url(../assets/public/images/tablet.png); " class=" flex-shrink-0 me-2 base-img ">
                                    </div>

                                    <div *ngIf="item?.user?.role?.role == 'USER_ROLE'" [ngClass]="{'active': usersActives[item?.person?.code]}" style=" background-image: url(../assets/public/images/avatar.png); " class=" flex-shrink-0 me-2 base-img ">
                                    </div>

                                </div>

                                <div class="pb-3 mb-0 small lh-sm border-bottom w-100">

                                    <div class="d-flex justify-content-between mt-2">
                                        <div>
                                            <span class="text-dark fw-bold text-capitalize">{{item?.person?.name}} {{item?.person?.lastName}}  </span>

                                        </div>
                                        <span class="">
                                            
                                            <!-- {{item?.person?.date | date: 'dd/MM/yyyy'}}  -->
                                            {{getProfile(item?.user)? getProfile(item?.user)?.value :''}}

                                             <a class="text-primary cursor-pounter" (click)="openBottom(content,item)" ><i class="fas fa-ellipsis-v ps-2"></i></a>
                                        </span>




                                    </div>


                                    <div class="mt-1 mb-2">
                                        <div class="text-dark mb-2"> {{item?.user?.username}} </div>

                                    </div>

                                    <div class="d-flex justify-content-between mt-2 small ">
                                        <div>
                                            {{obj_shop[item?.user?.shop] && obj_shop[item?.user?.shop]?.name }}


                                            <!-- {{item?.user?.code}} -->
                                        </div>
                                    </div>

                                    <div class="mt-3 mb-2">

                                        <span [ngClass]="{'text-bg-dark': item?.user?.status,'text-bg-secondary': !item?.user?.status}" class="badge text-bg-secondary me-2"> {{item?.user?.status?'Activo':'Inactivo'}}</span>
                                        <span *ngIf="status_users" [ngClass]="{'text-bg-success': usersActives[item?.person?.code],'text-bg-danger': !usersActives[item?.person?.code]}" class="badge text-bg-secondary "> {{usersActives[item?.person?.code]?'Online':'Offline'}}</span>

                                    </div>


                                </div>
                            </div>
                        </ng-container>

                    </div>

                    <!-- 
                <small class="d-block text-end mt-3">
                              <a href="#">All suggestions</a>
                            </small> -->
                </div>


                <ng-container *ngIf="loading ">
                    <div class="w-100 text-center mt-2">
                        <img src="./assets/public/images/loading.gif  " alt=" ">
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>