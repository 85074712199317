import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormErrorService {

  errorMessage = {
    required: 'Este campo es obligatorio',
    email: 'Por favor ingrese una dirección de correo electrónico válida',
    minlength: 'El valor debe tener al menos {requiredLength} caracteres',
    maxlength: 'El valor no puede exceder los {requiredLength} caracteres',
    pattern: 'Solo se permiten números'
  };

  getErrorMessage(controlName: string, errors: any): string {
    let errorMessage = '';
    console.log(errors);
    if (errors.required) {
      errorMessage = this.errorMessage.required;
    } else if (errors.email) {
      errorMessage = this.errorMessage.email;
    } else if (errors.minlength) {
      errorMessage = this.errorMessage.minlength.replace('{requiredLength}', errors.minlength.requiredLength);
    } else if (errors.maxlength) {
      errorMessage = this.errorMessage.maxlength.replace('{requiredLength}', errors.maxlength.requiredLength);
    } else if (errors.pattern) {
      errorMessage = this.errorMessage.pattern;
    }

    return errorMessage;
  }
}
