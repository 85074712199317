<ng-template #content let-offcanvas>
    
    <div class="offcanvas-body">
        <div class=" container pt-3">

            <div class="row">
                <div class=" col-12 col-md-12 ">
                    <app-new-fee (close)="close()" [code_edit]="code_edit"></app-new-fee>
                </div>
            </div>
        </div>
    </div>
</ng-template>





<div class="mt-md-3 p-3">

    <div class="row ">
        <div class=" col-12 col-md-10 offset-md-1">

            <h5 class=" text-secondary   text-center"> Penalidades

               
            </h5>
            <div class="mb-5 mt-2 text-center" *ngIf="_auth.IsPermitid(['OWNER'])">
                <button (click)="addForm(content)" class="btn btn-block btn-primary ">
                <span>Nueva Penalidad</span>
                <!-- <span *ngIf="submit_disabled">
                  <i class="fa  fa-spinner fa-spin ms-2"></i>
                </span> -->
              </button>
            </div>
           
            <ng-container *ngIf="!plans_loading" class="pt-4">

                <!-- <div class="mb-2 text-start">
                    <button (click)="status_plans = true" [ngClass]="{'btn-secondary': status_plans == true,'btn-outline-secondary': status_plans == false}" class="btn btn-sm  me-3">
                       <span >Activos</span>
                    </button>

                    <button (click)="status_plans = false" [ngClass]="{'btn-outline-secondary': status_plans == true,'btn-secondary': status_plans == false}" class="btn   btn-sm ">
                        <span >Inactivos</span>
                     </button>
                </div> -->
                <div class=" mt-4 row">
                    <div  [ngClass]="{'d-none': item.status != status_plans, 'inactive-div': item?.paused }" *ngFor="let item of plans" class="col-12 col-md-6">
                        <div class="card mb-4"  style="min-height: 200px;" >
                            <div class="card-body">

                            <div class="d-flex">
                                <div class="text-secondary small fw-bold" style="font-size: 10px;" (click)="edit(content, item?.code)" *ngIf="_auth.IsPermitid(['OWNER'])">
                                    {{item?.code}}
                                </div>
                                <div class="ms-auto text-start small">
                                    <div class="cursor-pounter float-end ">
                                        <i  *ngIf="item.status == true && _auth.IsPermitid(['OWNER'])" (click)="delete(item?.code)" class="fas fa-trash text-danger " style="font-size: 18px;cursor: pointer;" title="Eliminar"></i>
                                        <!-- <i  *ngIf="item.status == false"(click)="activePlan(item?.code)" class="fas fa-redo-alt text-success " style="font-size: 18px;cursor: pointer;"></i> -->

                                        
                                    </div>
                                    <div class="me-4">
                                        {{item?.created_at | date: 'dd/MM/yyyy'}}

                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">

                            <div class="form-group col-12 mt-1">
                                <div class="cursor-pointer float-start" >
                                  <div class="d-flex align-items-center">
                                    &nbsp;&nbsp;<div class="ml-5 form-check form-switch pb-3">
                                      <input class="ml-5  form-check-input fs-5" 
                                          type="checkbox" 
                                          role="switch"
                                          [checked]="!item?.paused"
                                          (change)="changeStatus(item?.code, item?.paused)" >
                                    </div>      
                                  </div>
                                  </div>
                                </div>
                            </div>

                            <div class="mt-1 fw-bold text-start">
                                {{item?.code_concept}}  
                            </div>
                            <div class=" fw-bold text-start">
                                {{item?.name}}  
                            </div>
                            <div class="text-secondary small text-start">
                                {{item?.description}} 
                            </div>
                            

                            <!-- <div class="text-secondary small">
                                Inicio: {{item?.start_date | date: 'dd/MM/yyyy'}} 
                            </div>
                            <div class="text-secondary small">
                                Finalización: {{item?.start_date | date: 'dd/MM/yyyy'}} 
                            </div> -->
                            <!-- <pre>{{ item | json }}</pre> -->
                            <!-- <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Aplica : {{item?.apply==='ACCOUNT'? 'Cuenta': 'Cuota'}}</span>
                            <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Medida : {{item?.type==='credit'? 'Crédito': 'Dia'}}</span>
                             -->
                            <div class="mt-4 small">
                                <div class="fw-bold mb-2">Forma de aplicación</div>

                                

                                

                                        <ol class="list-group list-group-flush" *ngIf="item?.fees.length > 0">
                                            <ng-container  *ngFor="let elem of item.fees" class="font-weight-bold fz-14">
                                                
                                                
                                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                                        <span class="text-primary fw-bold">
                                                            <span class="text-secondary" style="font-size: 10px;">{{ elem.code ?? elem.uuid }}</span> <br>
                                                            <ng-container *ngIf="elem.withCondition==null || elem.withCondition==true">
                                                                {{ getValueByKey(list_fee_attributes,elem.attribute) }} {{ getValueByKey(list_comparison, elem.condition) }} <span *ngIf="elem.condition=='BETWEEN'">{{ elem.lower_limit }}  y {{ elem.upper_limit }}</span> <span *ngIf="elem.condition!='BETWEEN'">{{ elem.single_value }}</span>&nbsp;&nbsp;
                                                            </ng-container>
                                                            <ng-container *ngIf="elem.withCondition==false">
                                                                Se aplica a todos los casos sin condiciones
                                                            </ng-container>
                                                            
                                                            <span class="badge rounded-pill bg-success  small">Activo</span>&nbsp;&nbsp;<span class="badge rounded-pill bg-secondary2 " *ngIf="elem.start_date <= now">Programado</span>
                                                            <br>
                                                            <span class="text-secondary small">
                                                                {{ elem.start_date | date: 'dd/MM/yyyy' }} <span *ngIf="elem.end_date"> hasta {{ elem.end_date | date: 'dd/MM/yyyy' }}</span>
                                                            </span>  
                                                        </span>
                                                        <span class="ms-auto text-end  fw-bold">
                                                            <span *ngIf="elem.fixed_amount">{{ elem.currency }} {{ elem.fixed_amount }}</span> <span *ngIf="elem.percentage"><span *ngIf="elem.fixed_amount">+</span>  {{ elem.percentage }}% </span>
                                                        </span>
                                                    </li>
                                            </ng-container>

                                        </ol>

                                        <ol class="list-group " *ngIf="item?.fees.length === 0">
                                                
                                                
                                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                                        
                                                        <span class="text-secondary fw-bold">No se han agregado tarifas</span>
                                                    </li>

                                        </ol>

                                 
                            </div>

                            <div class="mt-4 small" *ngIf="item?.fee?.account_payment">
                                <span class="fw-bold">Cuenta contable SAP</span><br>
                                <span class="">Suscriptor: {{ item?.fee?.account_payment?.subscriptor_cuenta }} </span>; 
                                <span class="">Suscriptor simbolo: {{ item?.fee?.account_payment?.subscriptor_simbolo }}</span>; 
                                <span class="">Suscriptor MP: {{ item?.fee?.account_payment?.subscriptor_mp }}</span>; 
                            </div>
                            <div class="text-end small text-secondary">
                                Por: {{item?.user?.name }} {{item?.user?.lastName }} 
                            </div>

                            <!-- <pre>{{ item | json }}</pre> -->

                        </div>
                      </div>
                    </div>


                </div>

                


             


            </ng-container>


            <div *ngIf="!plans_loading && !plans">
                <div>No se encontraron planes </div>
            </div>

            <ng-container *ngIf="plans_loading ">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>


        </div>
    </div>

</div>