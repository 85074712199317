import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-credit-option',
  templateUrl: './credit-option.component.html',
  styleUrls: ['./credit-option.component.css']
})
export class CreditOptionComponent implements OnInit {
   clientAprov:any;

  constructor(
    public _auth : AuthService,

  ) { }

  ngOnInit(): void {
  }

}
