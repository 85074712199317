<div class="">
    <div class="row">
        <div class="col-12">

            <div (click)="$event.stopPropagation();" class="w-100 bg-white  body pt-3 pb-3" style="    overflow-y: auto; overflow-x: hidden; ">

                <div style="min-height: 206px; ">

                    <h4 class="font-weight-bold p-2 ">Ingresa el Código de Seguridad</h4>
                    <hr>
                    <ng-container *ngIf="loadingSentValidateTlf ">
                        <div class="container animate__animated animate__fadeIn text-center ">
                            Por favor espere Enviando mensaje...
                            <div class="mt-2 ">
                                <img src="./assets/public/images/loading.gif " alt=" ">
                            </div>
                        </div>
                    </ng-container>

                    <div *ngIf="!loadingSentValidateTlf && sentValidateTlf ">
                        <div class="mt-4 text-dark ">Se ha enviado un mensaje de texto al teléfono suministrado, con el código de seguridad. </div>
                        <div class="row mt-4 ">
                            <div class="col-12 col-md-5 ">
                                <div class="form-group ">
                                    <input type="text " min="0 " max="6 " minlength="0 " maxlength="6 " class="form-control " [(ngModel)]="code " psaceholder="código ">
                                    <label *ngIf="errorMsgValidatTel " class="form-check-label text-danger ">{{errorMsgValidatTel}}</label>
                                </div>

                            </div>
                            <div class="col-12 col-md-7 ">

                                <div class="font-weight-bold fz-14 ">Te enviamos un código: </div>
                                <div class="text-secondary fz-14 ">{{data}}</div>
                            </div>


                        </div>


                    </div>

                    <div *ngIf="!loadingSentValidateTlf && sentValidateErrorTlf " class="text-center ">
                        <div class="mt-4 text-dark ">Ocurrio un error al enviar el mensaje. Por favor revisa tu número telefónico, y vuelve a validar </div>

                        <div class=" w-100 ">
                            <div class="mt-3 ">
                                <i class=" text-warning ps-2 pr-2 fas fa-info-circle fa-3x " aria-hidden="true "></i>

                            </div>

                            <div class="mt-2 ">
                                <b>( {{data}} )</b><br>
                                <span class="text-primary active-cursor " (click)="sendMsgValidateTel(data) ">Validar</span>
                            </div>



                        </div>


                    </div>

                    <div *ngIf="!loadingSentValidateTlf && validateErrorFormat " class="text-center ">
                        <div class="mt-4 text-dark ">El número de teléfono no cumpse con el formato internacional. Por favor revisa tu número telefónico, y vuelve a validar. <br><br>Ejempso para Venezuela: <span class="font-weight-bold ">+58424XXXXXXX</span></div>

                        <div class=" w-100 ">
                            <div class="mt-3 ">
                                <i class=" text-warning ps-2 pr-2 fas fa-info-circle fa-3x " aria-hidden="true "></i>

                            </div>




                        </div>


                    </div>


                </div>

                <div class="mt-4 text-right " *ngIf="sentValidateTlf || sentValidateErrorTlf ">
                    <hr>
                    <span class=" text-primary active-cursor mt-2 " (click)="sendMsgValidateTel(data) ">Reenviar</span>

                    <button *ngIf="sentValidateTlf " [disabled]="code?.length < 6 || loadingValidateCode " class="btn btn-primary float-end " (click)="getCodeValidateTel(data,code) "> 
                       
                        <span *ngIf="!loadingValidateCode " >Continuar</span>
                       <span *ngIf="loadingValidateCode " >Enviando...</span>
               
                         <span *ngIf="!loadingValidateCode " class="fas fa-arrow-right ms-1 "></span>

                  </button>

                    <button class="btn btn-secondary me-1 float-end " (click)="setClose() "> 
                                Cancelar 
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>