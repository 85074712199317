import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DirectDebitService } from '../../../services/direct-debit/direct-debit.service';


@Component({
  selector: 'app-dnd-alliances',
  templateUrl: './dnd.component.html',
  styleUrls: ['./dnd.component.css']
})
export class DndAlliancesComponent implements OnInit {

  @Output() response = new EventEmitter<any>();
  @Output() reset = new EventEmitter<any>();
  // @Input() loading(_loading: boolean) {
  //   this._loading = _loading;
  // };

  @Input() set direct_debit(_direct_debit: any) {
    this._direct_debit = _direct_debit;
  };

  @Input() set ally(_ally: any) {
    this._ally = _ally;
  };

  _loading = false;
  _direct_debit: any;
  _ally: any;
  verified = false;
  error = false;

  detail_error = "";

  response_file = null;


  constructor(
    private directDebitService: DirectDebitService,
  ) { }

  ngOnInit(): void {
    
  }

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef | undefined;
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped(files: File[]) {
   
    this.prepareFilesList(files);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event: any) {
    debugger;
    let files = ($event.target as HTMLInputElement).files;
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      console.log("Upload in progress.");
      return;
    }
    this.files.splice(index, 1);
    this.verified = false;
    this.reset.emit(true);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
   
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
   
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: any) {
    this.error = false;
    this.verified = false;
    this._loading = true;
    this.files = [];
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    if (this.fileDropEl && this.fileDropEl.nativeElement) {
      this.fileDropEl.nativeElement.value = "";
    }
    this.uploadFilesSimulator(0);
    this.readFileService();
  }

  readFileService = async () => {
    this.verified = false;
    this.error = false;
    this.detail_error = "";
    this.response_file=null;
    console.log(this._direct_debit);
    try{
      debugger;
      let response = await this.directDebitService.readDirectDebitFileXlsx(this.files[0]);
      this._loading = false;
      if(response.response==="ok") {
        if(this._direct_debit.lote.padStart(10, '0').toString()===response.header['lote'].padStart(10, '0').toString()) { // aqui tendría que validar el lote
          if(this._ally.toString()===response.header['alianza']) { // aqui tendría que validar el lote
            //let fecha_valor = new Date(new Date(response.header['fecha_valor'].split('-').reverse().join('-')).setUTCHours(new Date(new Date(response.header['fecha_valor'].split('-').reverse().join('-'))).getUTCHours()));
            //let fecha = new Date(new Date(this._direct_debit.date).setUTCHours(new Date(this._direct_debit.date).getUTCHours() - 4));
            // console.log(fecha_valor);
            // console.log(fecha);
            // if(fecha_valor>=fecha){
              if(parseFloat(response.rate)===parseFloat(response.header['tasa bcv'])) {
                this.verified = true;
                this.response_file = response;
                this.response.emit(response);
              } else {
                this.detail_error = "La tasa declarada para la fecha en el archivo no corresponde con la tasa BCV de nuestras bases de datos, por favor revise. La tasa BCV para la fecha " + response.header['fecha_valor'] + " en la base de datos es " + response.rate +", en el archivo se declara con el monto " + response.header['tasa bcv'] + ".";
                this.error = true;
                this.response.emit({ response: "error" });
              }
            // } else {
            //   this.detail_error = "La tasa no debe ser de un dia anterior a la fecha de generación de la domiciliación, por favor verifique.";
            //   this.error = true;
            //   this.response.emit({ response: "error" });
            // }
          } else {
            this.detail_error = "El archivo que se está procesando no corresponde con el Aliado Comercial.";
            this.error = true;
            this.response.emit({ response: "error" });
          }
        } else {
          this.detail_error = "El lote no corresponde con el proceso de domiciliación que se está procesando.";
          this.error = true;
          this.response.emit({ response: "error" });
        }
      } else {
        this.detail_error = response.message;
        this.error = true;
        this.response.emit(response);
      }
    } catch(error) {
      this._loading = false;
      this.error = true;
      this.response.emit(null);
    }
  }

  getHeader = (obj: any) => {
    return obj.find((row: any) => row.TIPOREG==="01" );
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }


}
