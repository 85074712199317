import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-fee-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {
  @Input() code_fee: any;
  item: any;
  now: Date = new Date();
  list_comparison: any = [];
  list_fee_attributes: any = [];

  constructor(private _userService: UserService) { }

  ngOnInit(): void {
    this.getListComparison();
    this.getListFeeAttributes();
    this.getFeeDetail();
    
  }

  getFeeDetail = async () => {
    let response = await this._userService.getFeeApplyByCode({code: this.code_fee});
    this.item = response?.data[0];
  }

  getListComparison = async () => {
    try {  
      let response = await this._userService.getListComparison({});
      this.list_comparison = response.data;
      console.log(this.list_comparison);
    } catch (error: any) {
      this.list_comparison = [];
      console.log(error);
    }
  }

  getListFeeAttributes = async () => {
    try {  
      let response = await this._userService.getListFeeAttributes({ status: "ACTIVE"});
      this.list_fee_attributes = response.data;
      console.log(this.list_fee_attributes);
    } catch (error: any) {
      this.list_fee_attributes = [];
      console.log(error);
    }
  }

  getValueByKey = (obj: any, key: string) => {
    const foundItem = obj.find((item:any) => item.key === key);
    return foundItem ? foundItem.value : null;
  }

}
