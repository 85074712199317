<div class="container">

    <div class="mt-md-3 p-3">

        <div class="row ">
            <div class=" col-12 col-md-6 offset-md-3  p-0 ">
                <h5 class="mb-4 text-secondary"> <i class="  fas fa-users"></i> Consultar cupo
                </h5>

                <ng-container>

                    <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">

                        <form autocomplete="nope" novalidate [formGroup]="form" (ngSubmit)="submit()" class="text-start">
                            <div class="row mt-3">
                                <div class="form-group  col-12">

                                    <div class="form-floating">
                                        <select [ngClass]="{'error': form.controls.currency.invalid && form.controls.currency.dirty}" class="form-select" id="currency" formControlName="currency" aria-label="Floating label select example">
                                            <option  value="BS">BS</option>
                                            <option  value="USD">Dolares</option>

                                     </select>

                                        <label for="currency">Moneda</label>
                                    </div>

                                    <div class="form-control-feedback help-block" *ngIf="form.get('currency').errors">
                                        <div *ngIf="form.get('currency').errors['required'] && !form.get('currency').pristine">
                                            Campo requerido
                                        </div>
                                    </div>

                                </div>

                                <!-- <div class="form-group  col-12  mt-3" *ngIf="form.getRawValue().currency != 'BS'"> -->
                                <div class="form-group  col-12  mt-3">
                                    <div class="form-floating ">
                                        <input type="text" [ngClass]="{'error': form.controls.chage_rate.invalid && form.controls.chage_rate.dirty}" class="form-control text-capitalize" id="chage_rate" formControlName="chage_rate">
                                        <label for="chage_rate" class=" label font-weight-normal" style="word-wrap: break-word;">
                                    <span >Tasa de cambio </span>
                                 </label>
                                    </div>

                                    <div class="form-control-feedback help-block" *ngIf="form.get('chage_rate').errors">
                                        <div *ngIf="form.get('chage_rate').errors['required'] && !form.get('chage_rate').pristine">
                                            Campo requerido
                                        </div>
                                        <div *ngIf="form.get('chage_rate').hasError('minlength')  && !form.get('chage_rate').pristine">
                                            Mínimo 7 caracteres
                                        </div>

                                        <div *ngIf="form.get('chage_rate').hasError('maxlength')  && !form.get('chage_rate').pristine">
                                            Máximo 8 caracteres
                                        </div>

                                    </div>
                                </div>

                                <div class="form-group  col-12 mt-3">

                                    <div class="form-floating">
                                        <select [ngClass]="{'error': form.controls.type_identity.invalid && form.controls.type_identity.dirty}" class="form-select" id="type_identity" formControlName="type_identity" aria-label="Floating label select example">
                            <option  value="">Selecione</option>
                            <option *ngFor="let item of type_identity"  [value]="item.code">{{item.name}}</option>
                        </select>

                                        <label for="type_identity">Tipo de Documento</label>
                                    </div>

                                    <div class="form-control-feedback help-block" *ngIf="form.get('type_identity').errors">
                                        <div *ngIf="form.get('type_identity').errors['required'] && !form.get('type_identity').pristine">
                                            Campo requerido
                                        </div>
                                    </div>

                                </div>

                                <div class="form-group  col-4 mt-3">

                                    <div class="form-floating">
                                        <select [ngClass]="{'error': form.controls.code_identity.invalid && form.controls.code_identity.dirty}" class="form-select" id="code_identity" formControlName="code_identity" aria-label="Floating label select example">
                            <option  value="">Selecione</option>
                            <option *ngFor="let item of code_identity"  [value]="item.code">{{item.name}}</option>
                                     </select>



                                        <label for="code_identity">Tipo</label>
                                    </div>

                                    <div class="form-control-feedback help-block" *ngIf="form.get('code_identity').errors">
                                        <div *ngIf="form.get('code_identity').errors['required'] && !form.get('code_identity').pristine">
                                            Campo requerido
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group  col-8  mt-3">

                                    <div class="form-floating ">
                                        <input type="text" [ngClass]="{'error': form.controls.identity.invalid && form.controls.identity.dirty}" class="form-control text-capitalize" id="identity" formControlName="identity">
                                        <label for="identity" class=" label font-weight-normal" style="word-wrap: break-word;">
                          <span >Número </span>
                     </label>
                                    </div>

                                    <div class="form-control-feedback help-block" *ngIf="form.get('identity').errors">
                                        <div *ngIf="form.get('identity').errors['required'] && !form.get('identity').pristine">
                                            Campo requerido
                                        </div>
                                        <div *ngIf="form.get('identity').hasError('minlength')  && !form.get('identity').pristine">
                                            Mínimo 7 caracteres
                                        </div>

                                        <div *ngIf="form.get('identity').hasError('maxlength')  && !form.get('identity').pristine">
                                            Máximo 8 caracteres
                                        </div>

                                    </div>
                                </div>
                                <div class="form-group  col-12  mt-3">

                                    <div class="form-floating ">
                                        <input type="number" [ngClass]="{'error': form.controls.total_amount.invalid && form.controls.total_amount.dirty}" class="form-control text-capitalize" id="total_amount" formControlName="total_amount">
                                        <label for="total_amount" class=" label font-weight-normal" style="word-wrap: break-word;">
                                      <span >Monto Total (Dolares) </span>
                                 </label>
                                    </div>

                                    <div class="form-control-feedback help-block" *ngIf="form.get('total_amount').errors">
                                        <div *ngIf="form.get('total_amount').errors['required'] && !form.get('total_amount').pristine">
                                            Campo requerido
                                        </div>
                                        <div *ngIf="form.get('total_amount').hasError('min')  && !form.get('total_amount').pristine">
                                            Mínimo 20 Dolares
                                        </div>


                                    </div>
                                </div>
                                <br>
                                <div class="form-group  col-12 mt-4 mb-2">
                                    <div class=" float-end">

                                        <button type="submit" [disabled]="!form.valid || submit_disabled" class="btn btn-block btn-primary ">
                                        <span >Consultar</span>
                                        <span *ngIf="submit_disabled">
                                          <i class="fa  fa-spinner fa-spin ms-2"></i>
                                        </span>
                                      </button>

                                    </div>

                                </div>


                                <hr *ngIf="errorMsg" class="mt-2">
                                <div *ngIf="errorMsg" class="text-danger">
                                    {{errorMsg}}
                                </div>



                            </div>
                        </form>


                    </div>
                </ng-container>

                <ng-container *ngIf="searchPerson?.person">

                    <div class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                        <img *ngIf="searchPerson?.person?.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                        <img *ngIf="searchPerson?.person?.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">

                    </div>

                    <div class="text-center mb-5">
                        <div class="text-capitalize h4 text-dark">{{searchPerson?.person?.name}} {{searchPerson?.person?.lastName}}</div>
                        <div> {{identityResponse}}</div>
                    </div>

                    <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">


                        <div class="row rmt-2">
                            <div class="col-12">

                                <div class="float-end">

                                    <button (click)="currencyResponse = 'BS'" [ngClass]="{'btn-secondary': currencyResponse == 'BS','btn-outline-secondary': currencyResponse != 'BS'}" class=" me-2 btn btn-sm">
                                        <span >BS</span>
                                    </button>

                                    <button (click)="currencyResponse = 'USD'" [ngClass]="{'btn-secondary': currencyResponse == 'USD','btn-outline-secondary': currencyResponse != 'USD'}" class="btn btn-sm">
                                        <span >Dolares</span>
                                    </button>

                                </div>
                                <div class="small text-secondary">Compra financiada total</div>
                                <h5 class="fw-bolder text-dark">{{searchPerson?.total_currency[currencyResponse]?.total_amount }}</h5>
                            </div>
                            <div class="col-6">
                                <div class="small text-secondary">Cupo disponible</div>
                                <div class=" text-dark">{{searchPerson?.total_currency[currencyResponse]?.total_quota }}</div>
                            </div>

                            <div class="col-12 ">
                                <div class="small text-secondary">Plan</div>
                                <div class="text-dark">50% Inicial min.</div>
                            </div>
                            <div class="col-12">
                                <div class="small text-secondary">Cuotas</div>
                                <div class=" text-dark">3</div>
                            </div>
                            <div class="col-6 ">
                                <div class="small text-secondary">Inicial</div>
                                <div class=" text-dark">{{searchPerson?.total_currency[currencyResponse]?.initiala_amount }}</div>
                            </div>

                            <div class="col-6 text-end">
                                <div class="small text-secondary">Monto a financiar</div>
                                <div class=" text-dark">{{searchPerson?.total_currency[currencyResponse]?.outstanding_balance }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- <h5 class="mt-2 text-primary">Json Respuesta</h5>

                    <pre>{{ searchPerson | json }}</pre> -->
                </ng-container>


            </div>
        </div>
    </div>
</div>