import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth/auth.service';
@Injectable()
export class IsProfileGuard implements CanActivate {

    
    constructor(private router: Router, private _auth: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let {profiles} = route?.data;

        let sessionUser =  this._auth?.sessionUser?.profile || []; 

        const hasAllElems = sessionUser.some((elem:any) => profiles.includes(elem));

        if (hasAllElems) {
            return true;
        } else {
            this.router.navigate(['/logout'], {});
            return false;
        }
    }
}
