import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';
import { IdentGuard } from '../../guards/ident.guard';
import { error } from 'console';

@Component({
  selector: 'app-person-plans',
  templateUrl: './person-plans.component.html',
  styleUrls: ['./person-plans.component.css'],
  providers: [DatePipe],
})
export class ClientPlansComponent implements OnInit {
  getSection_transaction_by_person() {
    throw new Error('Method not implemented.');
  }
  getAnalysis(person: any) {
    throw new Error('Method not implemented.');
  }
  invoicesPending() {
    throw new Error('Method not implemented.');
  }
  plans: any[] = [];
  plans_loading: any;
  status_plans: any = true;
  plans_active: any;
  plans_inactive: any;
  queryParams: any;
  loading = false;
  person: any;
  selectedPlan: any | null = null;
  publicPlans: any;
  morePublicPlans: any;
  showAllPublicPlansToggle: any;
  privatePlans: any[] = [];
  selectedPrivatePlan: any;
  code: any;
  id: any;
  by_default: any;
  id_ejecutive: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    private offcanvasService: NgbOffcanvas,
    public _auth: AuthService
  ) {}

  ngOnInit(): void {
    this.getPlans();
    this.loadPrivatePlans();

    debugger;

    this.activatedRoute.params.subscribe((params) => {
      this.queryParams = params;
      if (this.queryParams?.code) {
        this.getPerson();
        this.getCustomerPlans(this.queryParams.code);
      }
    });
  }

  getPerson = async () => {
    this.loading = true;

    try {
      const resp = await this._user.getPersonbyCode({
        code: this.queryParams.code,
      });

      this.person = resp.resp;
      this.code = this.queryParams.code;

      // Por si acaso
      this.id = this.person._id;
    } catch (error) {
      console.log(error);
    }

    this.loading = false;
  };

  getIdentity(item: any) {
    if (!(item?.identitys?.length > 0)) return '';

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;
  }

  getContact(item: any) {
    if (!(item?.constacts?.length > 0)) return '';

    return `${item?.constacts[0]?.contact}`;
  }

  getEmailContact(item: any) {
    if (!(item?.constacts?.length > 0) || !item?.constacts[1]) return '';

    return `${item?.constacts[1]?.contact}`;
  }

  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getCustomerPlans = async (code: string) => {
    this.loading = true;

    try {
      const response = await this._user.getCustomerPlans(code);

      this.plans = response;
    } catch (err) {
      console.error('Failed to load customer plans:', err);
    } finally {
      this.loading = false;
    }
  };

  getPlans = async (plans_loading = true) => {
    try {
      this.plans_loading = plans_loading;

      let publicPlans = await this._user.getplans({});
      this.publicPlans = publicPlans?.res?.filter(
        (item: any) => item.public === true && item.by_default === true
      );

      this.morePublicPlans = publicPlans?.res?.filter(
        (item: any) => item.public === true && item.by_default === false
      );
    } catch (error) {
      console.log(error);
    }
    this.plans_loading = false;
  };

  loadPrivatePlans = async (plans_loading = true) => {
    try {
      this.plans_loading = plans_loading;

      let privatePlans = await this._user.getplans({});
      this.privatePlans = privatePlans?.res;

      this.privatePlans = this.privatePlans?.filter(
        (item: any) => !item.public
      );
    } catch (error) {
      console.log(error);
    }
    this.plans_loading = false;
  };

  setDefaultPlan = async (planData: any) => {
    const plan = planData.plan;

    if (!plan || !this.code) {
      return;
    }

    try {
      const response = await this._user.updateDefaultPlan(this.code, plan);

      if (response && response.success) {
        alertifyjs.success('Plan agregado por defecto!');
        this.getCustomerPlans(this.code); // Refresh the plans list
      } else {
        alertifyjs.error('Failed to set plan as default');
      }
    } catch (error) {
      alertifyjs.error('Error setting default plan');
    }
  };

  selectPlan(item: any) {
    this.selectedPrivatePlan = item?._id;

    if (!this.selectedPrivatePlan) {
      console.warn('No plan selected');
      return;
    }
    this.updateCustomerPlans('add');
  }

  removePlan(plan: any) {
    this.selectedPrivatePlan = plan.plan;

    if (this.selectedPrivatePlan && this.code) {
      this.updateCustomerPlans('remove');
    } else {
      console.warn('Plan ID or person code is missing');
    }
  }

  updateCustomerPlans = async (action: 'add' | 'remove') => {
    debugger;
    // Log the sessionUser object and id_ejecutive for debugging
    console.log('Session User:', this._auth.sessionUser);
    const id_ejecutive = this._auth.sessionUser?.uid;
    console.log('Executive ID (id_ejecutive):', id_ejecutive);

    if (this.selectedPrivatePlan && this.code) {
      try {
        // Log the plan and code for further debugging
        console.log('Selected Private Plan:', this.selectedPrivatePlan);
        console.log('Person Code:', this.code);
        console.log('Ejecutive id:', id_ejecutive)

        const response = await this._user.updateCustomerPlan(
          this.code,
          this.selectedPrivatePlan,
          action,
          id_ejecutive
        );

        // Log the response from the backend
        console.log('Response from updateCustomerPlan:', response);

        if (action === 'add') {
          alertifyjs.success('Plan agregado');
          this.offcanvasService.dismiss(this.getCustomerPlans);
        } else {
          alertifyjs.success('Plan eliminado');
        }

        this.getCustomerPlans(this.code);
      } catch (error) {
        console.error('Error updating customer plan:', error);
        alertifyjs.error(
          action === 'add'
            ? 'El plan ya está agregado'
            : 'Error eliminando el plan'
        );
      }
    } else {
      console.warn(
        'No se ha seleccionado ningun plan o el código de la persona no está definido'
      );
    }
  };

  isPlanAdded(planId: string): boolean {
    return this.plans.some((plan) => plan.plan === planId);
  }

  pocisionBottom = async (content: TemplateRef<any>) => {
    try {
      this.offcanvasService.open(content, {
        position: 'bottom',
        keyboard: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  details = async (plan: any) => {
    try {
      this.selectedPlan = this.selectedPlan === plan.code ? null : plan.code;
    } catch (error) {
      console.log(error);
    }
  };

  showAllPublicPlans = async (plan: any) => {
    try {
      this.showAllPublicPlansToggle = !this.showAllPublicPlansToggle;
    } catch (error) {
      console.log(error);
    }
  };
}
