<div class="mt-md-3 container-fluid">

    <div class="row mt-2">
        <div class=" col-12 col-md-8 offset-md-2">
            <div class="row mt-2">
                <div class="col-12  col-md-8 offset-md-2">
                    <h5 class=" text-secondary text-center">
                        <i class="  fas fa-user"></i> Cuotas
                    </h5>
                </div>
            </div>

            <ng-container>
                <div class="row">

                    <div class="col-12  col-md-8 offset-md-2 mt-5">

                        <!-- <div *ngIf="!env?.production || supUse" class="mt-2 mb-4">

                            <button (click)="installMentNextCuote()" class="btn btn-outline-primary">Enviar mensajes de próximas cuotas</button>

                        </div> -->

                        <div class="row">
                            <div class="mb-4 mt-2 col-12 col-md-6 ">
                                <div class="form-floating ">

                                    <select (change)="setTypeInstallMents()" [(ngModel)]="typeInstallMents" class="form-select " id="floatingSelect " aria-label="Floating label select example ">
                                  <option   value="">Todas las cuotas</option>
                                  <option   value="p">Próximas cuotas</option>
                                  <option   value="c">Cuotas conciliadas</option>
                                  <option   value="p-oll">Cuotas vencidas</option>
                                </select>
                                    <label for="floatingSelect ">Estatus</label>
                                </div>

                            </div>

                            <div class="mb-4 mt-2 col-12 col-md-6 " *ngIf="typeInstallMents == '' || typeInstallMents == 'c'">

                                <input style="
                                    padding: 16px 9px;
                                    background: white;
                                " id="demo-external" (onSet)="birthdaySet()" [(ngModel)]="range" mbsc-range class="form-control" [mbsc-options]="dateSetting" #externalRange="mobiscroll" />

                            </div>

                            <div class="mb-4 mt-2 col-12 col-md-6 " *ngIf="typeInstallMents == 'p'">

                                <input style="
                                padding: 16px 9px;
                                background: white;
                            " id="demo-external" (onSet)="birthdaySet()" [(ngModel)]="range" mbsc-range class="form-control" [mbsc-options]="dateSetting2" #externalRange="mobiscroll" />

                            </div>


                            <div class="mb-4 mt-2 col-12 col-md-6 " *ngIf="typeInstallMents == 'p-oll'">

                                <input style="
                            padding: 16px 9px;
                            background: white;
                        " id="demo-external" (onSet)="birthdaySet()" [(ngModel)]="range" mbsc-range class="form-control" [mbsc-options]="dateSetting3" #externalRange="mobiscroll" />

                            </div>
                        </div>

                        <ng-container>

                            <div class="row mb-4" *ngIf="!loading">
                                <div class="col-8">
                                    <span class="badge rounded-pill bg-secondary ms-2"> Clientes {{countClient}}</span>
                                    <span class="badge rounded-pill bg-secondary  ms-2"> Cuotas {{countInstallmente}}</span>
                                    <!-- <span class="badge rounded-pill bg-success ms-2"> Total {{sumInstallment}}$</span> -->
                                    <span class="badge rounded-pill bg-danger ms-2"> Total pendiente {{sumInstallmentFalta}}$</span>


                                </div>

                                <div class="text-end col-4">
                                    <button type="button " class="btn btn-outline-secondary btn-sm  " (click)="generate_installment_csv()">
                                        <span *ngIf="!loadingXls2">Exportar </span> 
                                        <span *ngIf="loadingXls2">Generando...</span> 
                                    </button>
                                </div>

                            </div>


                            <ng-container *ngFor="let item of installMents">
                                <div *ngIf="item?.installMents?.length > 0" class="mt-2  mb-4 p-3 bg-body rounded shadow-sm">
                                    <div class="row mt-2">

                                        <div class="col-12 ">

                                            <span class=" text-dark ">
                                         {{item?.invoice?.identifier}}
                                    </span>

                                        </div>

                                        <div class="col-6 mt-2">
                                            <div class="small text-secondary ">Cliente</div>

                                            <div class="fw-bold  cursor-pounter text-capitalize" (click)="externalProfile(item?.invoice?.customer?.code)">{{item?.invoice?.customer?.name}} {{item?.invoice?.customer?.lastName}}

                                                <span *ngIf="usersActives[item?.invoice?.customer?.code]" class="small text-success ms-1"> (online)</span>
                                            </div>
                                        </div>


                                        <div class="col-6 mt-2 text-end">
                                            <div class="small text-secondary ">Contactos</div>

                                            <ng-container *ngFor="let item of item?.contacts; let i=index">

                                                <div *ngIf="item?.type_contact.code =='PHONE_CELL'" (click)="gotoWhatsapp(item?.contact)" class=" text-primary cursor-pounter">{{item?.contact}}</div>
                                                <!-- <div *ngIf="item?.type_contact.code =='EMAIL'" class=" text-dark small ">{{item?.contact}}</div> -->
                                            </ng-container>
                                        </div>
                                    </div>

                                    <ng-container *ngFor="let item2 of item?.installMents; let i=index">
                                        <div class="col-12">
                                            <hr>
                                        </div>
                                        <div class="row">

                                            <div class="col-6   mt-2">

                                                <div class="small text-secondary">Cuota</div>
                                                <div class=" text-dark"> {{ item2?.order
                                                    < 1 ? 'Inicial':item2?.order }}</div>

                                                </div>

                                                <div class="col-6 mt-3 text-end">
                                                    <div class="small text-secondary ">Fecha de pago</div>
                                                    <div class=" text-dark"> {{item2?.scheduledPaymentDate | date: 'dd/MM/yyyy'}}</div>
                                                </div>



                                                <div class="col-6  mt-2 ">
                                                    <div class="small text-secondary ">Monto</div>
                                                    <div class=" text-dark fw-bold"> {{item2?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                                </div>

                                                <div class="col-6  mt-2 text-end">
                                                    <div class="small text-secondary">Monto faltante</div>
                                                    <div [ngClass]="{'text-dark': !(item2?.amount > item2?.dueBalance) ,'text-danger': (item2?.amount > item2?.dueBalance)}"> {{item2?.dueBalance | currency:'USD':'': '1.2-2'}} USD</div>
                                                </div>


                                                <div class="col-6 mt-3" *ngIf="item2?.payment_status?.code != 'PaymentComplete'">
                                                    <span class="badge bg-dark">{{item2?.payment_status?.name}}</span>
                                                </div>


                                                <div class="col-6 mt-3" *ngIf="item2?.payment_status?.code == 'PaymentComplete'">
                                                    <span class="badge bg-dark">{{item2?.payment_status?.name}}</span>
                                                </div>




                                                <!-- 
                                        <div *ngIf="item2?.payment_status?.code != 'PaymentComplete'" class="col-6 text-end mt-3">
                                            <button type="button" class="btn btn-outline-primary mt-2 btn-sm" [routerLink]="['/console/payment/'+item?.invoice?.code+'/'+item2?.code]"> Pagar {{item2?.dueBalance | currency:'USD':'': '1.2-2'}} USD faltante </button>
                                        </div>
 -->



                                            </div>
                                    </ng-container>


                                    <!-- <pre>{{ item | json }}</pre> -->



                                    </div>
                            </ng-container>

                            <div *ngIf="!loading && installMents?.length > 1" class="mt-4 text-center">

                                <button type="button " class="btn btn-primary primary  " (click)="nextRows()">Siguientes + </button>

                            </div>


                        </ng-container>
                        </div>

                    </div>

            </ng-container>

            <div *ngIf="!loading && installMents?.length < 1" class="col-12  col-md-8 offset-md-2">
                <div class="mt-5 my-3 p-3 bg-body rounded shadow-sm">
                    <div>No se encontraron </div>
                </div>
            </div>


            <ng-container *ngIf="loading ">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>
            </div>
        </div>

    </div>