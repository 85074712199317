<ng-template #content let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                                Nueva tasa de cambio

                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)=" offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 col-md-6 offset-md-3">

                    <div class="d-block d-md-none mt-4 mb-3">
                        <mbsc-date (onSet)="dateSet($event)" [(ngModel)]="date_base" [options]="mobileSettings" placeholder="dd/mm/aaaa">Fecha</mbsc-date>
                    </div>
                    <div class="d-none d-md-block mt-4 mb-3">
                        <mbsc-date (onSet)="dateSet($event)" [(ngModel)]="date_base" [options]="desktopSettings" placeholder="dd/mm/aaaa">Fecha</mbsc-date>
                    </div>

                    <form autocomplete="nope" novalidate [formGroup]="form" class="text-start">

                        <div class="form-group  col-12  mt-1">
                            <div class="form-floating">
                                <select [ngClass]="{'error': form.controls.type.invalid && form.controls.type.dirty}" class="form-select" id="type" formControlName="type" aria-label="Floating label select example">
                                    <option  value="BS">Bolivares</option>
                                   
                                 </select>
                                <label for="type">Tipo</label>
                            </div>

                            <div class="form-control-feedback help-block" *ngIf="form.get('type').errors">
                                <div *ngIf="form.get('type').errors['required'] && !form.get('type').pristine">
                                    Campo requerido
                                </div>
                            </div>
                        </div>

                        <div class="form-group  col-12  mt-3">
                            <div class="form-floating ">
                                <input currencyMask (click)="moveCursorToEnd($event)" [options]="{ precision: 3, prefix:'BS ', thousands: ',', decimal: '.' }" placeholder="" type="text" [ngClass]="{'error': form.controls.amount.invalid && form.controls.amount.dirty}" class="form-control text-capitalize"
                                    id="floatingmt" formControlName="amount">
                                <label for="floatingmt" class=" label font-weight-normal" style="word-wrap: break-word;">
                      <span >Monto de la tasa </span>
        
                     </label>
                            </div>

                            <div class="form-control-feedback help-block" *ngIf="form.get('amount').errors">
                                <div *ngIf="form.get('amount').errors['required'] && !form.pristine">
                                    Campo requerido
                                </div>

                                <div *ngIf="form.get('amount').errors['pattern']  && !form.pristine">
                                    Monto inválido
                                </div>

                            </div>


                        </div>

                        <div class="form-group  col-12 mt-4 mb-2">
                            <div class=" float-end">
                                <button (click)="submit()" [disabled]="!form.valid || submit_disabled" class="btn btn-block btn-primary ">
                            <span >Agregar</span>
                            <span *ngIf="submit_disabled">
                              <i class="fa  fa-spinner fa-spin ms-2"></i>
                            </span>
                          </button>
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="mt-md-3 container-fluid">

    <div class="row mt-2">
        <div class=" col-12 col-md-8 offset-md-2">
            <div class="row rmt-2">
                <div class="col-12  col-md-8 offset-md-2">


                    <div class="row rmt-2">
                        <div class="col-12  col-md-8 offset-md-2">
                            <h5 class="mb-1 text-secondary mb-4 text-center">
                                <i class="fas fa-dollar-sign"></i> Tasas de Cambio
                            </h5>
                        </div>
                    </div>


                    <div class="col-12  col-md-7  mt-3 mb-5">
                        <input style="
                        padding: 16px 9px;
                        background: white;
                    " id="demo-external" (onSet)="birthdaySet()" [(ngModel)]="range" mbsc-range class="form-control" [mbsc-options]="dateSetting3" #externalRange="mobiscroll" />

                    </div>


                    <ng-container *ngIf="loading">
                        <div class="w-100 text-center mt-2">
                            <img src="./assets/public/images/loading.gif" alt=" ">
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!loading">


                        <div class="d-flex justify-content-between">
                            <div class="pt-3">
                                <span class="fw-bold h5  mt-4 "> Bolivares</span>

                            </div>
                            <div class="mb-2 text-end">
                                <button class="btn  btn-primary  " (click)="pocisionBottom(content)">Nueva tasa</button>
                            </div>
                        </div>

                        <div class=" text-dark"></div>

                        <div *ngIf="rates?.length
                        < 1 && !loading ">No se encontraron tasas a esta fecha</div>

                        <div *ngIf="rates?.length
                        > 0  && !loading " class="cursor-pounter mt-3 my-3 p-3 bg-body rounded shadow-sm">


                            <div class=" d-flex text-dark pt-3 " *ngFor="let item of rates">
                                <div class="pb-3 mb-0 small lh-sm border-bottom  w-100">
                                    <div class="d-flex justify-content-between">
                                        <div class="">

                                            <div class="text-secondary text-capitalize fw-bold "> {{item?.ejecutive?.name }} {{item?.ejecutive?.lastName }} </div>
                                            <div class="text-secondary text-capitalize small pt-1"> {{item?.date_consult | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                        </div>
                                        <div class="ms-auto">

                                            <div class=" fw-bold">{{item?.amount}} </div>
                                            <div class="">{{item?.date | date: 'dd/MM/yyyy'}}</div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </ng-container>


                    <!-- <pre>{{ rates | json }}</pre> -->
                </div>
            </div>
        </div>
    </div>
</div>