

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import { mobiscroll, MbscCalendarOptions } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import * as alertifyjs from 'alertifyjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light'
};


@Component({
  selector: 'app-consult-quote',
  templateUrl: './consult-quote.component.html',
  styleUrls: ['./consult-quote.component.css']
})
export class ConsultQuoteComponent implements OnInit {
  @Output() close = new EventEmitter<any>();

  dataJson = {};

  dateSetting: MbscCalendarOptions = {
    responsive: {
      small: {
          controls: ['calendar'],
          display: 'center'
      },
      medium: {
          display: 'bubble',
      }
  },
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light'
  };

  birthday:any;
  birthdayString:any ="";
  form:any;
  form2:any;
  form3:any;

  submit_disabled:any;
  person:any;
  searchPerson:any;
  step:any = 1;
  validateNumber:any;

  type_identity:any = [];
  code_identity:any = [];
  marital_status:any ;


  result:any;
  buro:any={};
  constructor(
    private formBuilder: FormBuilder,
    private _user:UserService,
    private router: Router,
    public _auth: AuthService


  ) { }

  ngOnInit(): void {

    this.birthday =  new Date(`06/02/1988`);


    localStorage.removeItem('new_person');
    this.searchPerson =null;

    this.form = this.formBuilder.group({

      type_identity: ['CI',[Validators.required]],
      code_identity: ['V',[Validators.required]],
      identity: ['',[Validators.required,Validators.minLength(7),Validators.maxLength(8)],],
      total_amount: [0,[Validators.required,Validators.min(20)]],

  });

  
  this.getLists();
  
  }

  getLists = async () =>  {
    const [marital_status, type_identity,code_identity] = await Promise.all([
      await this._user.getPersonMaritalStatus(),
      await this._user.getPersonTypeIdentity(),
      await this._user.getPersonCodeIdentity()
  ]);

    this.marital_status = marital_status.resp;
    this.type_identity = type_identity.resp;
    this.code_identity = code_identity.resp;
  }

  submit = async () =>  {
    this.result=false;
    this.person = null;
    if ( this.form.valid) {
        this.submit_disabled = true;
        const data = this.form.getRawValue();

      let params = {
        "subscriber": {
          "subscriber": 1,
          "shop": "Onboarding",
          "cash_register": "Onboarding",
          "user": "******"
        },
        "client": {
          "type_identity": data.type_identity ,
          "code_identity": data.code_identity,
          "identity": data.identity
        },
        "params": {
          "currency": "USD",
          "total_amount": data.total_amount,
          "chage_rate": 20.23
        }
      };

      this.dataJson = params;


    try {
         
      let resp = await this._user.clientQuota(params);

      this.searchPerson =resp;
    
      this.submit_disabled = false;

     } catch (error:any) {

        this.submit_disabled = false;
     }
  }
}




}
