import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import { mobiscroll, MbscDatetimeOptions, MbscFormOptions } from '@mobiscroll/angular';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import * as alertifyjs from 'alertifyjs';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};

const now = new Date();

//const week = [ new Date(now.getFullYear(),0, 1),now];
let week = [ new Date() ,new Date()];

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.css'],
  providers: [DatePipe]
})
export class RatesComponent implements OnInit {

  loading:any;
  rates:any = [];
  submit_disabled:any;
  errorMsg:any;


 range: Array < Date > = week;
 date: Array < Date > = [];
 nonForm: Array < Date > = [];
 external = week;
 d1:any;
 d2:any; 
 form:any;


 dateSetting3: MbscDatetimeOptions = {
   lang: 'es',
   theme: 'ios',
   themeVariant: 'light',
   responsive: {
     small: {
         controls: ['calendar'],
         display: 'center'
     },
     medium: {
         display: 'bubble',
     }
 }
 
 };

 birthday = new Date();
date_base: any = new Date;
date_base_string :any;

mobileSettings: MbscDatetimeOptions = {
 display: 'bottom',
 themeVariant: 'dark',
};


desktopSettings: MbscDatetimeOptions = {
 display: 'bubble',
 touchUi: false
};


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    private offcanvasService: NgbOffcanvas,
    private formBuilder: FormBuilder,


  ) { }

  ngOnInit(): void {
    this.date_base_string = this.datePipe.transform(this.date_base, 'MM/dd/yyyy');

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');

    this.getHistoryRates();


    this.form = this.formBuilder.group({

        amount: ['',[Validators.required]],
        type: ['BS',[Validators.required]],

    });
    
  }




  
  getHistoryRates = async () => {
    try {
      this.loading = true;


      const resp = await this.getHistoryRatesServer();

      this.rates = resp?.res;

   

    } catch (error) {
      console.error(error);



    } 
    this.loading = false;

   


  }

  getHistoryRatesServer = async () => {
    try{
       let query ={
        
        init:this.d1 ,
        end:this.d2 

       }

        return this._user.getHistoryRates(query);

      } catch (error) {
        console.error(error);
        
      }
  }



  birthdaySet() {  

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');
    this.getHistoryRates();
      
}

pocisionBottom = async (content: TemplateRef<any>,data:any= {}) =>  {

    try {
    
        this.offcanvasService.open(content, { position: 'bottom',keyboard: false} );
    
    } catch (error) {
      console.log(error);
      
    }
       
  }


submit = async () =>  {

    if ( this.form.valid ) {
        this.submit_disabled = true;
        const data = this.form.getRawValue();
  
      let params = {
        "subscriptor":1,
        "type":data.type,
        date:this.date_base_string,
        amount:data.amount
    };

    try {
         
      let resp = await this._user.setHistoryRate(params);
      alertifyjs.success('Registro exitoso');
  
      location.reload();
       // this.getHistoryRates();





  
      this.submit_disabled = false;
      this.offcanvasService.dismiss();

    this.form = this.formBuilder.group({

        amount: ['',[Validators.required]],
        type: ['BS',[Validators.required]],

    });

  
     }  catch (error:any) {
  
          const params = error?.error?.error;
          if(params?.code){
            this.errorMsg = params?.msg;
          }
  
        this.submit_disabled = false;
     }
  }
  
  }
  
dateSet(e: any) {

    let date_base =  e.valueText;
    
    let  temp = date_base? date_base.split("/") : [];
    this.date_base_string = `${temp[1]}/${temp[0]}/${temp[2]}`;
  
  }
  
  
  moveCursorToEnd(event: any) {
    const input = event.target;
    input.selectionStart = input.selectionEnd = input.value.length;
  }

}
