<ng-template #forms>
        <div class="container">
            <div class="row">


                
                <!-- <div class="col-12 mt-4">
                    <h4 class="text-secondary">Templates</h4>
                </div> -->

                <!-- *ngIf="!loading" -->
                <div class="col-12 col-md-6 offset-md-3 p-0 mt-4">
            <div class="row">
                <div class="col-8 h5 pt-2 pb-2 text-muted">
            {{ formType === 'template' ? 'Agregar' : 'Agregar' }}
            </div>
        </div>

        <!-- <app-modal [formType]="formType"></app-modal> -->
        <app-modal (refresh)="update()" [formType]="formType" [selectedItem]="selectedItem" [actionType]="actionType"></app-modal>







        </div>
    </div>
    </div>
</ng-template>

<ng-template #queue>
    <div class="container">
        <div class="row">

            <!-- *ngIf="!loading" -->
            <div class="col-12 col-md-6 offset-md-3 p-0 mt-4">
    <div class="row">
        <div class="col-8 h5 pt-2 pb-2 text-muted">
            Notificaciones Automáticas
        </div>  
        
    </div>

</div>

        <div class="scrollable-container">
    <div *ngFor="let item of queues" class="col-12 col-md-6 offset-md-3 p-0 mt-3 pt-4 pb-4 my-3 p-3 bg-body rounded shadow-sm border">
            <ng-container>
                <div class="search-results">
                            <div class="ms-auto text-end small">
                            <div class="cursor-pointer float-end" >
                                <div class="d-flex align-items-center">
                                            <div class="form-check form-switch">
                                </div>
                                <i class="fas fa-pause-circle text-secondary ms-2" style="font-size: 18px; cursor: pointer" (click)="pauseQueue(item?.group, item?.status)"></i>
                                <i class="far fa-trash-alt text-danger ms-2" style="font-size: 18px; cursor: pointer;" (click)="cancelQueue(item?.group, item?.status)"></i>
                            </div>
    
                </div>
                </div>


                        </div>
                        
                        <div class="text-secondary small">
                            <small>{{item?.group}}</small><br>
                            <span class="text-secondary" [innerHTML]="item?.event[0].description"></span> </div>
                        <div>
                            <div class="mt-2">
                                <span class="badge bg-success  me-2 mt-2" *ngIf="item?.status==='PENDING'">En proceso de envio</span>
                                <span class="badge bg-warning  me-2 mt-2" *ngIf="item?.status==='PAUSED'">Pausado</span>


                                <span class="badge bg-light text-muted me-2 mt-2" *ngIf="item?.status!=='PAUSED'">{{(item?.totalCount-item?.pendingCount) }} / {{(item?.totalCount) }}</span>


                            </div>
                        </div>
            </ng-container>
        </div>

        </div>

        <!-- *ngIf="plans.length === 0" -->
       <div  class="col-12 col-md-6 offset-md-3 p-0 mt-3 pt-4 my-3 p-3 bg-body rounded shadow-sm" *ngIf="queues.length === 0" >
        <p>No hay procesos activo de notificaciones.</p>
    </div>

        </div>
    </div>
</ng-template>

<ng-template #template>
    <div class="container">
        <div class="row">

            <!-- *ngIf="!loading" -->
            <div class="col-12 col-md-6 offset-md-3 p-0 mt-4">
                <div class="row">
                    <div class="col-8 h5 pt-2 pb-2 text-muted">
                        Templates
                    </div>  
                    <div class="col-4 text-end">
                        <!-- *ngIf="_auth.IsPermitid(['OWNER'])" -->
                        <button class="btn btn-primary btn-sm" (click)="openModal('create', forms)">
                        Agregar
                        </button>
                    </div>
                </div>

            </div>
            <div class="col-12 col-md-6 offset-md-3 p-0 mt-4">
                <div class="row">
                    <div class="col-12 h5 pt-2 pb-2 text-muted">
                       
            <div class="form-floating mt-3">
                <select class="form-select bg-body" 
                        id="floatingSelect" 
                        aria-label="Floating label select example" 
                        formControlName="filterChannel"
                        (change)="filterTemplates($event)"
                        [(ngModel)]="filterChannel"
                        required>
                        <option [value]="''" selected="true">-- seleccione --</option>

                    <ng-container *ngFor="let item of channels">
                        <option [value]="item?._id">{{item?.description}}</option>
                    </ng-container>
                </select>
                <label for="floatingSelect">Canal</label>
                <div *ngIf="formNotifications.get('channel')?.invalid && formNotifications.get('channel')?.touched" class="text-danger">
                    Campo requerido
                </div>
            </div> 
        </div>  </div></div>


            



        <div class="scrollable-container">

            
    <div *ngFor="let item of templates" class="col-12 col-md-6 offset-md-3 p-0 mt-3 pt-4 pb-4 my-3 p-3 bg-body rounded shadow-sm border">
            <ng-container>
                <div class="search-results">
                            <div class="ms-auto text-end small">
  <div class="cursor-pointer float-end" >
     <div class="d-flex align-items-center">
                    <div class="form-check form-switch">
    <!-- <input class="form-check-input fs-5" 
           type="checkbox" 
           role="switch" 
           id="flexSwitchCheckDefault" 
           [checked]="item?.status === 'ACTIVE'"
           [disabled]="_auth.IsPermitid(['OWNER'])"
           > -->
    <!-- <label class="form-check-label" for="flexSwitchCheckDefault">
        {{ item?.channel?.status === 'ACTIVE' ? 'ACTIVO' : 'INACTIVO' }}
    </label> -->
</div>
                    <i class="far fa-edit text-secondary ms-2" style="font-size: 18px; cursor: pointer"  (click)="openModal('edit', forms, item)"></i>
                    <i class="far fa-trash-alt text-danger ms-2" style="font-size: 18px; cursor: pointer;"></i>
                </div>
    <div class="mt-2" style="text-align: right;">
      <!-- <span class="view-more" (click)="toggleViewMore(item)">Ver más</span> -->
    </div>
  </div>
</div>


                        </div>
                        <div class="text-primary fw-bold">{{item?.code}} - {{item?.description}}
                        </div>
                        <div class="text-secondary small"><span class="text-secondary" [innerHTML]="item?.channelIcon"></span> {{item?.channelName}}</div>
                        <div>
                            <div class="mt-2">
                                <span class="badge bg-light text-muted me-2 mt-2">Código: {{item?.code}}</span>
                            </div>
                            <!--*ngIf="selectedTemplate === item?.code" -->
                            <div class="mt-4">
                                <span *ngIf="item?.subject !== '' " class="text-dark fw-bold">{{item?.subject}}</span>
                            <div class="mt-3"></div>
  <span class="break-word pt-2" [innerHTML]="getSanitizedMessage(item?.message)"></span>
</div>

                </div>
            </ng-container>
        </div>

        </div>

        <!-- *ngIf="plans.length === 0" -->
       <div  class="col-12 col-md-6 offset-md-3 p-0 mt-3 pt-4 my-3 p-3 bg-body rounded shadow-sm">
        <p>No hay templates agregados.</p>
    </div>

        </div>
    </div>
</ng-template>

<ng-template #showChannels>
    <div class="container">
        <div class="row">

            <!-- *ngIf="!loading" -->
            <div class="col-12 col-md-6 offset-md-3 p-0 mt-4">
                <div class="row">
                    <div class="col-8 h5 pt-2 pb-2 text-muted">
                    Canales
                    </div>

                    <!-- <div class="col-4 text-end">
                        <button class="btn btn-primary btn-sm">
                        Agregar canal
                        </button>
                    </div> -->
                </div>
            </div>

    <div *ngFor="let channel of channels; let i = index" class="col-12 col-md-6 offset-md-3 p-0 mt-3 pt-4 pb-4 my-3 p-3 bg-body rounded shadow-sm border">
    <ng-container>
        <div class="search-results">
            <div class="d-flex justify-content-between align-items-center">
                <div class="text-dark fw-bold"><span class="text-secondary" [innerHTML]="channel?.icon"></span>  {{channel?.description}}</div>
                <div class="d-flex align-items-center">
                    <div class="form-check form-switch">
    <input class="form-check-input fs-5" 
           type="checkbox" 
           role="switch" 
           id="flexSwitchCheckDefault-{{i}}" 
           [checked]="channel?.status === 'ACTIVE'"
           (change)="changeStatusChannel(channel._id, $event)">
    <!-- <label class="form-check-label" for="flexSwitchCheckDefault">
        {{ item?.channel?.status === 'ACTIVE' ? 'ACTIVO' : 'INACTIVO' }}
    </label> -->
</div>
                    <i class="far fa-trash-alt text-danger ms-2" style="font-size: 18px; cursor: pointer;"></i>
                </div>
            </div>
            <div>
                <span  
                [ngClass]="channel.status === 'ACTIVE' ? 'badge bg-success' : 'badge bg-light text-muted'" 
                class="me-2 mt-2">
                {{channel?.status}}
                </span>
                <span *ngIf="channel.type" class="badge bg-light me-2 mt-2 text-muted">Tipo: {{channel?.type}}</span>
                <span *ngIf="channel.max_characters" class="badge bg-light me-2 mt-2 text-muted">Caracteres Max.: {{channel?.max_characters}}</span>
            </div>
        </div>
    </ng-container>
</div>

<div *ngIf="channels.length === 0" class="col-12 col-md-6 offset-md-3 p-0 mt-4">
    <p>No hay canales agregados.</p>
</div>


        </div>
        </div>
</ng-template>

<ng-template #showEvents>
    <div class="container">
        <div class="row">
            <!-- <div class="col-12 mt-4">
                <h4 class="text-secondary">Templates</h4>
            </div> -->

            <!-- *ngIf="!loading" -->
            <div class="col-12 col-md-6 offset-md-3 p-0 mt-4">
                <div class="row">
                    <div class="col-8 h5 pt-2 pb-2 text-muted">
                    Eventos
                    </div>

                    <div class="col-4 text-end">
                        <button class="btn btn-primary btn-sm" (click)="toggleForm('event', forms)">
                        Agregar evento
                        </button>
                    </div>
                </div>
</div>
            </div>

            <div class="scrollable-container">
    <div *ngFor="let item of events; let i = index" class="col-12 col-md-6 offset-md-3 p-0 mt-3 pt-4 pb-4 my-3 p-3 bg-body rounded shadow-sm border">
    <ng-container>
        <div class="search-results">
            <div class="d-flex justify-content-between align-items-center">
                <div class="text-dark fw-bold">{{item?.code}} - {{item?.description}}</div>
                <div class="d-flex align-items-center">
                    <div class="form-check form-switch">
    <input class="form-check-input fs-5" 
           type="checkbox" 
           role="switch" 
           id="flexSwitchCheckDefault-{{i}}" 
           [checked]="item?.status === 'ACTIVE'"
           (change)="changeStatusEvent(item._id, $event)">
    <!-- <label class="form-check-label" for="flexSwitchCheckDefault">
        {{ item?.channel?.status === 'ACTIVE' ? 'ACTIVO' : 'INACTIVO' }}
    </label> -->
</div>
                    <i class="far fa-trash-alt text-danger ms-2" style="font-size: 18px; cursor: pointer;"></i>
                </div>
            </div>
            <div>
                <span  
                [ngClass]="item.status === 'ACTIVE' ? 'badge bg-success' : 'badge bg-light text-muted'" 
                class="me-2 mt-2">
                 {{item?.status}}
                </span>
                <span *ngIf="item.created_at" class="badge bg-light me-2 mt-2 text-muted">Creado el: {{ item?.created_at | date: 'd / M / yy' }}</span>
            </div>
        </div>
    </ng-container>
</div>
</div>

<div *ngIf="channels.length === 0">
    <p>No hay templates agregados.</p>
</div>


        </div>

    </ng-template>

<ng-template #addNotification>
    <div class="container">
        <div class="row">

            <!-- *ngIf="!loading" -->
            <div class="col-12 col-md-6 offset-md-3 p-0 mt-4">
    <div class="row">
        <div class="col-8 h5 pt-2 pb-2 text-muted">
            Agregar Notificación
        </div>  
    </div>

    <!-- Form for creating a new template -->
    <div>
    <form [formGroup]="formNotifications" (ngSubmit)="saveNewNotification()">
        <div class="form-floating mt-3">
                            <select class="form-select bg-body" 
                                    id="floatingSelect" 
                                    aria-label="Floating label select example" 
                                    formControlName="event"
                                    required
                                    >
                                    <option value="">Seleccione</option>
                                <ng-container *ngFor="let item of events">
                                    <option [value]="item?._id">
                                        {{item?.description}}
                                    </option>
                                </ng-container>
                            </select>
                            <label for="floatingSelect">Evento</label>
                        </div>

        <div class="form-floating mt-3">
            <select class="form-select bg-body" 
                    id="floatingSelect" 
                    aria-label="Floating label select example" 
                    formControlName="channel"
                    (change)="onChannelChange()"
                    required>
                <option value="">Seleccione</option>
                <ng-container *ngFor="let item of availableChannels">
                    <option [value]="item?._id">{{item?.description}}</option>
                </ng-container>
            </select>
            <label for="floatingSelect">Canal</label>
            <div *ngIf="formNotifications.get('channel')?.invalid && formNotifications.get('channel')?.touched" class="text-danger">
                Campo requerido
            </div>
        </div>

        <div class="scrollable-container-notifications">
    <div *ngFor="let item of filteredTemplates" class=" mt-3 pt-4 pb-4 my-3 p-3 bg-body rounded shadow-sm border" 
         [ngClass]="{'border-primary': formNotifications.get('template')?.value === item?._id}" 
         (click)="selectTemplate(item._id)" style="cursor: pointer;">
        <ng-container>
            <div class="search-results">
                <!-- <div class="ms-auto text-end small">
                    <div class="cursor-pointer float-end">
                        <div class="mt-2" style="text-align: right;">
                            <span class="view-more" (click)="toggleViewMore(item)">Ver más</span>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="text-primary fw-bold">{{item?.description}}</div>
            <div class="text-secondary small">{{item?.channelName}}</div>
            <div>
                <div class="mt-2">
                    <span class="badge bg-light text-muted me-2 mt-2">Código: {{item?.code}}</span>
                </div>
                <div *ngIf="formNotifications.get('template')?.value === item?._id" class="mt-4">
                    <span *ngIf="item?.subject !== '' " class="text-dark fw-bold">{{item?.subject}}</span>
                    <div class="mt-3"></div>
                    <span class="break-word pt-2" [innerHTML]="getSanitizedMessage(item?.message)"></span>
                </div>
            </div>
        </ng-container>
    </div>
<div class="text-end">
            <button type="submit" class="btn btn-primary btn-sm p-2 mt-4" [disabled]="formNotifications.invalid">
                Agregar
            </button>
        </div>
</div>


        <!-- <div class="form-floating mt-3">
            <select class="form-select bg-body" 
                    id="templateSelect" 
                    aria-label="Floating label select example" 
                    formControlName="template" 
                    required>
                <option value="">Seleccione</option>
                <ng-container *ngFor="let item of filteredTemplates">
                    <option [value]="item?._id">{{item?.description}}</option>
                </ng-container>
            </select>
            <label for="templateSelect">Template</label>
            <div *ngIf="formNotifications.get('template')?.invalid && formNotifications.get('template')?.touched" class="text-danger">
                Campo requerido
            </div>
        </div> -->

        
    </form>
    <!-- <div class="col-12 border-bottom mt-4 mb-4"></div> -->
</div>

</div>

        </div>
    </div>
</ng-template>



<div class="container">
    <div class="mt-md-3 p-3">
        <div class="row">
            <div class="col-12 col-md-6 offset-md-3 p-0">
                <h5 class="mb-1 text-secondary mb-4 text-center">
                    <i class="  fas fa-comments"></i>
                    Gestión de Notificaciones</h5>


                <div>

                    <div class="col-12 mt-3">
                        <button class="btn btn-outline-warning me-2 btn-blo mt-2" (click)="toggleSettings()">
                        <span class="mb-4 text-center">
                        <i class="fas fa-ellipsis-v me-2"></i>
                        Carga Masiva
                        </span>
                    </button>
                    <div *ngIf="showSettings">
                                        <div class="row mt-3">
                        

                        <ng-container>

                            <div>
                                <mbsc-input (change)="onFileSelected($event)" [options]="mobileSettingsFile" type="file" placeholder="Adjunte el soporte...">Carga masiva</mbsc-input>
                            </div>

                            <!-- <div class="form-control-feedback help-block">
                                <div *ngIf="!this.formDataUpload">
                                    El adjunto es obligatorio
                                </div>

                            </div> -->
                        </ng-container>



                    </div>
                    <div class="row mt-4">
                        <div class="col-12 mt-2 ">
                            <div class="d-grid gap-2 ">
                                <button [disabled]="!formDataUpload " (click)="submitFile()" type="button " class="btn btn-warning ">
                                    <span *ngIf="!loadingDoc">Cargar </span> 
                                    <span *ngIf="loadingDoc">Cargando...</span> 
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3 p-3 bg-body rounded shadow-sm card-button2">
                            <!-- [routerLink]="['/console/online']" ARRIBA -->
                            <div class="card-body">
                                <button class="button-style btn d-flex justify-content-between align-items-center w-100">
                                    Descargar template notificaciones
                                    <i class="fas fa-file-download text-warning"></i>
                                </button>
                            </div>
                        </div>

                        <div class="col-12 border-bottom mt-4 mb-0"></div>

                    </div>

                    <div>

                        <div class="mt-4 p-3 bg-body rounded shadow-sm card-button">
                            <!-- [routerLink]="['/console/online']" ARRIBA -->
                            <div class="card-body">
                                <button class="button-style btn d-flex justify-content-between align-items-center w-100" (click)="modal(queue)">
                                    Notificaciones automáticas
                                    <i class="float-end fas fa-arrow-right text-primary"></i>
                                </button>
                            </div>
                        </div>

                        <div class="mt-4 p-3 bg-body rounded shadow-sm card-button">
                            <!-- [routerLink]="['/console/online']" ARRIBA -->
                            <div class="card-body">
                                <button class="button-style btn d-flex justify-content-between align-items-center w-100" (click)="modal(template)">
                                    Templates
                                    <i class="float-end fas fa-arrow-right text-primary"></i>
                                </button>
                            </div>
                        </div> 

                        <div class="mt-4 p-3 bg-body rounded shadow-sm card-button">
                            <!-- [routerLink]="['/console/online']" ARRIBA -->
                            <div class="card-body">
                                <button class="button-style btn d-flex justify-content-between align-items-center w-100" (click)="modal(showChannels)">
                                    Canales
                                    <i class="float-end fas fa-arrow-right text-primary"></i>
                                </button>
                            </div>
                        </div>

                        <div class="mt-4 p-3 bg-body rounded shadow-sm card-button">
                            <!-- [routerLink]="['/console/online']" ARRIBA -->
                            <div class="card-body">
                                <button class="button-style btn d-flex justify-content-between align-items-center w-100" (click)="modal(showEvents)">
                                    Eventos
                                    <i class="float-end fas fa-arrow-right text-primary"></i>
                                </button>
                            </div>
                        </div>

                        </div>

                        <div class="col-12 border-bottom mt-4 mb-0"></div>

                        <div class="form-floating mt-4">
                            <select class="form-select select bg-body shadow-sm" id="floatingSelect" aria-label="Floating label select example" [(ngModel)]="selectedEventCode" (change)="onChangeEvent()">
                                <option value="df" selected>Seleccione</option>
                                <ng-container *ngFor="let item of events">
                                <option value="{{item?.code}}">{{item?.code}} - {{item?.description}}</option>
                                </ng-container>
                            </select>
                            <label for="floatingSelect">Evento</label>
                        </div>


                    </div>
                </div>
            


                <div class="mt-3 pt-4 my-3 p-3 bg-body rounded shadow-sm">

                    <div class="col-12 border-bottom pb-3 mb-0">
                <div class="row">
                    <h6 class="col-8 pt-1 text-muted">
                    Notificaciones asociadas
                    </h6>

                    <div class="col-4 text-end">
                        <button *ngIf="selectedEventCode !== 'df' " class="btn btn-primary btn-sm" (click)="modal(addNotification)">
                        Agregar notificación
                        </button>
                    </div>
                </div>
            </div>

                    <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="50">
                        <ng-container>
                            <div class="d-flex text-muted pt-4">
<div class="pb-3 mb-0 small lh-sm w-100">
  <div *ngFor="let item of selectedEvent; let i = index">
    <div class="d-flex justify-content-between align-items-start mb-3">
    <div class="me-3 w-100">
        <span class="text-primary fw-bold text-capitalize">
            <span class="text-secondary" [innerHTML]="item?.channel?.icon"></span>
           
             {{item?.channel?.description}} <span class="text-danger" *ngIf="item?.channel?.status!=='ACTIVE'"> <span
                class="me-2 text-danger text-lowercase fw-normal ">
                <span>-</span>
                {{ item?.channel?.status}}
            </span></span></span>

        <div class="mt-2">
            <span
                [ngClass]="item?.status === 'ACTIVE' ? 'badge bg-success' : 'badge bg-light text-muted'" 
                class="me-2">
                {{item?.status}}
            </span>
            <span *ngIf="item.channel.max_characters" class="badge bg-light text-muted">
                Caracteres Max.: {{item?.channel.max_characters}}
            </span>
        </div>
        
        <div class="mt-3">
            <!-- <span class="d-block text-dark view-more" (click)="toggleViewMoreNotifications(item)">Ver template</span> -->
            <!-- *ngIf="selectedTemplateNotif === item?.template._id"  -->
            <div class="mt-3">
                
                    <!-- <div class="d-flex w-100">
                        <i class="far fa-edit text-secondary" style="font-size: 18px; cursor: pointer" (click)="editTemplate('edit', forms, item.template, item)"></i>
                    </div> -->
                    <div class="pb-2">
                    <span class="break-word pt-3 text-dark" *ngIf="item?.template?.subject"><span class="fw-bold">Asunto:</span> 
                        {{item?.template?.subject}}
                    </span>
                    </div>

                <div>
                    <span class="break-word pt-2 text-dark" [innerHTML]="getSanitizedMessage(item?.template.message)"></span>
                </div>
                    
  
</div>

        </div>
        <div class="mt-3">
            <span (click)="editTemplate('edit', forms, item.template, item)" class=" text-primary text-decoration-underline cursor-pointer">
                Editar template
            </span>
        </div>

    </div>

    <div class="d-flex align-items-center">
        <div class="form-check form-switch">
            <input class="form-check-input fs-5" 
                   type="checkbox" 
                   role="switch" 
                   id="flexSwitchCheckDefault-{{i}}" 
                   [checked]="item?.status === 'ACTIVE'"
                   (change)="changeStatusNotification(item._id, item?.event._id, $event)">
        </div>

        <i class="far fa-trash-alt text-danger ms-2" style="font-size: 18px; cursor: pointer;" 
           (click)="deleteNotification(item._id)"></i>
    </div>
    
</div>




    <div *ngIf="i < selectedEvent.length - 1" class="border-bottom my-4"></div>
  </div>

  <ng-container>
    <div class="mt-2">
      <div *ngIf="selectedEventCode !== 'df' && selectedEvent.length === 0" class="text-dark h6">
        No se encontraron registros.
      </div>
      <div *ngIf="selectedEventCode === 'df'" class="text-dark h6 d-flex align-items-center">
        <i class="fas fa-lightbulb me-2 text-warning"></i>
        Seleccione un evento para ver las notificaciones asociadas.
        </div>

    </div>
  </ng-container>
</div>


</div>

                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <pre>{{ eventsByChannel | json }}</pre> -->
