<div class="row">
    <div class="col-12 mt-2">
        <div class="mt-2 mb-5">

            <form *ngIf="!loadForm" autocomplete="nope" novalidate [formGroup]="form" class="text-start">
                <div class="row">

                    <div class="form-group  col-12 ">
                        <div class="form-floating">
                            <select  disabled="disabled"  class="form-select" id="bank"  aria-label="Floating label select example">
                                <option value=""  >{{ bank_name }}</option> 

                            </select>


                            <label for="bank">Banco</label>
                        </div>

                        
                    </div>


                    <!-- <div class="form-group  col-12 mt-3">
                        <div class="form-floating">
                            <select [ngClass]="{'error': form.controls.payment_method.invalid && form.controls.payment_method.dirty}" class="form-select" id="payment_method" formControlName="payment_method" aria-label="Floating label select example">
                                <option  value="">Selecione</option>
                                <option   value="640b83e26522a425711e006f">Trasferencia</option> 
                            </select>

                            <label for="payment_method">Tipo</label>
                        </div>

                        <div class="form-control-feedback help-block" *ngIf="form.get('payment_method').errors">
                            <div *ngIf="form.get('payment_method').errors['required'] && !form.get('payment_method').pristine">
                                Campo requerido
                            </div>
                        </div>
                    </div> -->

                    <div class="form-group  col-12 mt-3">
                        <div class="form-floating">
                            <select [ngClass]="{'error': form.controls.currency.invalid && form.controls.currency.dirty}" class="form-select" id="currency" formControlName="currency" aria-label="Floating label select example">
                                <option  value="">Selecione</option>
                                <option   value="63ffb65dbb023a686c40ab8c">BS</option> 
                                
                            </select>

                            <label for="currency">Moneda</label>
                        </div>

                        <div class="form-control-feedback help-block" *ngIf="form.get('currency').errors">
                            <div *ngIf="form.get('currency').errors['required'] && !form.get('currency').pristine">
                                Campo requerido
                            </div>
                        </div>

                    </div>

                    <div class="form-group  col-12 mt-3">
                        <div class="form-floating">
                            <select [ngClass]="{'error': form.controls.account_type.invalid && form.controls.account_type.dirty}" class="form-select" id="account_type" formControlName="account_type" aria-label="Floating label select example">
                                <option  value="">Selecione</option>
                                <option  value="00">Corriente</option> 
                                <option  value="01">Ahorro</option> 
                                
                            </select>

                            <label for="currency">Tipo de Cuenta</label>
                        </div>

                        <div class="form-control-feedback help-block" *ngIf="form.get('currency').errors">
                            <div *ngIf="form.get('currency').errors['required'] && !form.get('currency').pristine">
                                Campo requerido
                            </div>
                        </div>

                    </div>

                    <div class="mt-3 ">

                        
                        <div >
                            <div class="form-group ">

                                <div class="form-floating ">
                                    <input (keyup)="validate_account_number()"  
                                    maxlength="20"
                                    (input)="onChange($any($event))"
                                    type="text" [ngClass]="{'error': form.controls.account_number.invalid && form.controls.account_number.dirty}" class="form-control text-capitalize" id="account_number" formControlName="account_number" appNumbersOnly #miInput>
                                    <label for="account_number" class=" label font-weight-normal" style="word-wrap: break-word;">
                                  <span >Número de cuenta</span>
                             </label>
                                </div>

                                <div class="form-control-feedback help-block" *ngIf="form.get('account_number').errors">
                                    <div *ngIf="form.get('account_number').errors['required'] && !form.get('account_number').pristine">
                                        Campo requerido
                                    </div>

                                </div>

                                <div *ngIf="!validation_account_number && !form.get('account_number').pristine" class="form-control-feedback help-block ">

                                    <span>
                                       Deben ser 20 dígitos
                                    </span>

                                </div>
                            </div>
                        </div>

                    </div>


                    <div class=" mt-4">             
                        <mbsc-input #miInput [options]="mobileSettingsFile" (change)="onFileSelected($event)" type="file" placeholder="Select file...">Soporte</mbsc-input>
                    </div>

                    <br>
                    <div class="form-group col-12 mt-4 mb-2 ">
                        <div class=" float-end ">
                            <button (click)="submit() " [disabled]="!form.valid || submit_disabled || !validation_account_number " class="btn btn-block btn-primary ">
                        <span >Guardar</span>
                        <span *ngIf="submit_disabled ">
                          <i class="fa fa-spinner fa-spin ms-2 "></i>
                        </span>
                      </button>
                        </div>
                    </div>
                </div>

            </form>
            <ng-container *ngIf="loadForm ">
                <div class="w-100 text-center mt-2 ">
                    <img src="./assets/public/images/loading.gif " alt=" ">
                </div>
            </ng-container>
        </div>
    </div>
</div>
