
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import * as alertifyjs from 'alertifyjs';

@Component({
  selector: 'app-approve-conditions',
  templateUrl: './approve-conditions.component.html',
  styleUrls: ['./approve-conditions.component.css']
})
export class ApproveConditionsComponent implements OnInit {

  queryParams: any;
  person: any;
  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  loading = false;
  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;
  

  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
   type_approved: any;
   clientQuota:any;
   hidenApro = true;
   acept:any = true;
   msg:any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,


  ) { }

  ngOnInit(): void {


    this.activatedRoute.params.subscribe(params => {
      this.queryParams = params;
      if (this.queryParams?.code) {
        this.getPerson();
      }else{
        this.rdrMarca();

      }

    });

  }

  getPerson = async () => {
    this.loading = true;

    try {
      const resp = await this._user.getPersonbyCode({ code: this.queryParams.code });
      this.person = resp.resp;
      if(!(this.person?.person) || !this.person?.person?.status_terms_and_conditions){

        this.rdrMarca();

      }else{

        this.acept = false;
        this.hidenApro = false;
        this.loading = false;

      }

    } catch (error) {
      this.rdrMarca();
      console.error(error);
      this.loading = false;

    }


  }


  personApproveConditionsPost = async () => {
      try {
    const data =  {
       code_person :this.queryParams?.code
      }
  
      let result = await this._user.personApproveConditionsPost(data);
  
      if (!(result?.error)) {
            this.msg = true;
            alertifyjs.success('Aceptacion exitosa exitosa');
            this.acept = true;
           // this.rdrMarca();
  
          }
  
  } catch (error) {
    console.error(error);
    
    
  }

}



rdrMarca(){
  window.location.href = environment.BASE_FORNT;

}


getContact(item: any) {

  if (!(item?.constacts?.length > 0))
    return "";

  return `${item?.constacts[0]?.contact}`;


}

getIdentity(item: any) {

  if (!(item?.identitys?.length > 0))
    return "";

  return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;




}


sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}

}
