
<!--  -->
<div class="container" appDnd (fileDropped)="onFileDropped($event)" *ngIf="files.length===0">
    <!-- -->
    <input type="file" #fileDropRef id="fileDropRef" multiple  (change)="fileBrowseHandler($event)"/>
    <img src="assets/public/images/ic-upload-file.svg" width="35px" alt="">
    <h3>Arrastre y suelte el archivo aquí</h3>
    <h3>ó</h3>
    <label for="fileDropRef">Buscar archivo</label>
  </div>
  <div class="files-list">
    <div class="single-file" *ngFor="let file of files; let i = index">
      <img src="assets/public/images/ic-file.svg" width="45px" alt="file">
      <div class="info">
        <h4 class="name">
          {{ file?.name }}
        </h4>
        <p class="size" *ngIf="_loading">
          <i class="fas fa-spinner fa-spin"></i> Analizando... <!--{{ formatBytes(file?.size) }}-->
        </p>
        <p class="size" *ngIf="verified">
          <i class="fas fa-check color-check"></i> &nbsp;<span class="font-weight-bold">Verificado</span> <br>
          <ng-container class="pl-5" *ngIf="response_file">
            <div class="pl-5">
              Fecha Valor: {{ response_file["header"]["fecha_valor"] }}<br>
              Tasa BCV: {{ response_file["rate"] }}<br>
              Total Pagado USD: {{ response_file["total_usd"] }}<br>
              Total Pagado Bs: {{ response_file["total_bs"] }}<br>
            </div>
          </ng-container>
        </p>
        <p class="size" *ngIf="error">
          <i class="fas fa-exclamation-triangle color-red"></i> <b> Archivo  no valido o no corresponde.</b><br>
          {{ detail_error?"Detalle: " +detail_error: "" }}
        </p>
        <!-- <app-progress-dnd [progress]="file?.progress"></app-progress-dnd> -->
      </div>
      <img src="assets/public/images/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
    </div>
  </div>