import { Component, Input, Output,  SimpleChanges, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/services/notification.service';
import * as alertifyjs from 'alertifyjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-modal',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],

})
export class ModalComponent {
  private _selectedItem: any;
  htmlContent: string = '';

  @Input() formType: any;
  @Input() actionType: any;
  @Input() selectedItem: any;
  @Output() refresh: any = new EventEmitter<any>();

  editorConfig = {
    height: '150px',
    placeholder: 'Enter text here...',
    toolbarGroups: [
      ['clipboard', ['cut', 'copy', 'paste']],
      ['basicstyles', ['bold', 'italic', 'underline', 'strikethrough']]
    ]
  };

  
  form: FormGroup;
  formEvents: FormGroup;
  editForm: FormGroup;

  templateId: any;

  selectedEventCode: any = 'df';
  maxCharacters: any;
  channels: any[] = [];

  sessionUser: any;

  constructor(
    private offcanvasService: NgbOffcanvas,
    private NotificationService: NotificationService,
    private formBuilder: FormBuilder,
    public _auth: AuthService
  ) {
    this.form = this.formBuilder.group({
      description: ['', Validators.required],
      message: ['', Validators.required],
      channel: ['', Validators.required],
      code: ['', Validators.required],
    });

    this.editForm = this.formBuilder.group({
      description: ['', Validators.required],
      subject: [''],
      message: ['', Validators.required],
    });

    this.formEvents = this.formBuilder.group({
      description: ['', Validators.required],
      // limit: ['', Validators.required],
      code: ['', Validators.required],
    });


  }

  ngOnInit(): void {
    this.loadActiveChannels();
    this.form.get('channel')?.valueChanges.subscribe((channelId) => {
      this.updateValidators(channelId);
    });

    this.sessionUser = this._auth.sessionUser;
    this.adjustHeightOnInit();
  }

  closeModal() {
    this.offcanvasService.dismiss();
  }

  ngOnChanges(changes: SimpleChanges) {
    debugger;
    console.log(this.selectedItem);
    if (changes['selectedItem'] && changes['selectedItem'].currentValue) {
      console.log(
        'Selected item changed:',
        changes['selectedItem'].currentValue
      );
      this.populateForm(); // Call populateForm only if the new value is defined
    }
  }

  populateForm() {
    debugger;
    console.log('Populating form with:', this.selectedItem);
    if (this.actionType === 'edit' && this.selectedItem) {
      this.templateId = this.selectedItem._id;
      this.editForm.patchValue({
        description: this.selectedItem.description || '',
        subject: this.selectedItem.subject || '',
        message: this.selectedItem.message || '',
      });
      console.log('After patching:', this.editForm.value);
      setTimeout(()=> {
        this.adjustHeightOnInit();

      }, 300);

    } else {
      this.editForm.reset(); // Clear the form if creating a new template
    }
  }

  adjustHeightOnInit(): void {
    const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
    if (textarea) {
      textarea.style.height = 'auto'; // Resetea la altura
      textarea.style.height = `${textarea.scrollHeight}px`; // Ajusta la altura al contenido
    }
  }

  async saveEditedTemplate() {
    // if (!this.checkOwnerRole()) {
    //   console.error('Error: User is not OWNER');
    //   alertifyjs.error('Esta acción solo la puede ejecutar un OWNER');
    // }

    if (this.editForm.valid) {
      const body = {
        template: this.templateId,
        description: this.editForm.get('description')?.value,
        subject: this.editForm.get('subject')?.value,
        message: this.editForm.get('message')?.value,
      };

      try {
        const newTemplate = await this.NotificationService.editTemplate(body);
        console.log('New template saved:', newTemplate);
        alertifyjs.success('Modificado con éxito!');
        this.refresh.emit(true);
        this.form.reset();
        this.closeModal();
      } catch (error) {
        console.error('Error saving new template:', error);
      }
    } else {
      console.warn('Form is invalid:', this.editForm.errors);
    }
  }

  // Helper function to check if the form has changes

  async loadActiveChannels() {
    debugger;
    try {
      const activeChannelData =
        await this.NotificationService.getActiveChannels();
      this.channels = activeChannelData.response;

      console.log('This is the channel data:', this.channels);

      console.log();
    } catch (error) {
      console.error('Error loading channel:', error);
    }
  }

  async saveNewTemplate() {
    // if (!this.checkOwnerRole()) {
    //   console.error('Error: User is not OWNER');
    //   alertifyjs.error('Esta acción solo la puede ejecutar un OWNER');
    // }

    if (this.form.valid) {
      const body = {
        description: this.form.get('description')?.value,
        message: this.form.get('message')?.value,
        channel: this.form.get('channel')?.value,
        code: this.form.get('code')?.value,
      };

      console.log('This is the body:', body);

      try {
        const editedTemplate = await this.NotificationService.saveNewTemplate(
          body
        );
        console.log('New template saved:', editedTemplate);
        alertifyjs.success(`Agregado con éxito!`);
        this.form.reset();
        this.closeModal();
      } catch (error) {
        console.error('Error saving new template:', error);
      }
    } else {
      console.warn('Form is invalid:', this.form.errors);
    }
  }

  updateValidators(channelId: string) {
    const selectedChannel = this.channels.find(
      (channel) => channel._id === channelId
    );

    if (selectedChannel) {
      this.maxCharacters = selectedChannel.max_characters || null;

      // Clear previous validators
      this.form.get('message')?.clearValidators();

      // Set validators based on channel type
      if (selectedChannel.type === 'PLAIN') {
        this.form
          .get('message')
          ?.setValidators([this.plainMessageValidator, Validators.required]);
      } else if (
        selectedChannel.type === 'ENRICHED' ||
        selectedChannel.type === 'HTML'
      ) {
        this.form.get('message')?.setValidators([Validators.required]);
      }

      // Add max character validator if it exists
      if (this.maxCharacters) {
        this.form.get('message')?.setValidators([
          Validators.maxLength(this.maxCharacters),
          Validators.required, // Ensure it's still required
        ]);
      }

      // Update the validity of the form control
      this.form.get('message')?.updateValueAndValidity();
    }
  }

  // Custom validator for plain message (to disallow emojis or special characters)
  plainMessageValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const isValid = /^[a-zA-Z0-9\s]*$/.test(control.value); // Modify regex as needed
    return isValid ? null : { invalidCharacter: true };
  }

  saveTemplate() {
    if (this.actionType === 'create') {
      this.saveNewTemplate();
    } else {
      this.saveEditedTemplate();
    }
  }

  async saveNewEvent() {
    debugger;
    // if (!this.checkOwnerRole()) {
    //   console.error('Error: User is not OWNER');
    //   alertifyjs.error('Esta acción solo la puede ejecutar un OWNER');
    // }

    console.log('Form value:', this.formEvents.value); // This will log the flat form values

    // Manually build the structured data
    const body = {
      description: this.formEvents.get('description')?.value,
      code: this.formEvents.get('code')?.value,
      // params: [
      //   {
      //     key: 'code',
      //     limit: this.formEvents.get('limit')?.value,
      //   },
      // ],
    };

    console.log('Structured body:', body); // This will log the structured body

    if (this.formEvents.valid) {
      try {
        const newEvent = await this.NotificationService.saveNewEvent(body);
        console.log('New event saved:', newEvent);
        // alertifyjs.success('Evento agregado con éxito!');
        this.formEvents.reset(); // Reset the correct form group
        this.closeModal();
      } catch (error) {
        console.error('Error saving new event:', error);
      }
    } else {
      console.warn('Form is invalid:', this.formEvents.errors);
    }
  }

  adjustHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto'; // Resetea la altura
    textarea.style.height = `${textarea.scrollHeight}px`; // Ajusta la altura al contenido
  }


}
