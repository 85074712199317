import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  rate$: BehaviorSubject<any> = new BehaviorSubject(false);
  env: any = environment;

  constructor(public httpClient: HttpClient) {}

  get baseUrl() {
    return this.env.BASE_LA;
  }

  getActiveEvents = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          `${this.baseUrl}/notifications/events`,
          {
            headers: headers,
            params: request,
          }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getActiveQueues = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          `${this.baseUrl}/notifications/queues`,
          {
            headers: headers,
            params: request,
          }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getActiveChannels = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          `${this.baseUrl}/notifications/channels`,
          {
            headers: headers,
            params: request,
          }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getActiveTemplates = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(`${this.baseUrl}/notifications/templates`, {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  getActiveEventsByChannel = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };
    const request = params;

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          `${this.baseUrl}/notifications/events-channels-status`,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getSingleEventChannel = async (channel: any, event: any) => {
    const headers = { 'content-type': 'application/json' };

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          `${this.baseUrl}/notifications/find-event-channel?channel=` +
            channel +
            `&event=` +
            event,
          { headers: headers }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getEventById = async (id: '66f2f50e7360595aabe48159') => {
    const headers = { 'content-type': 'application/json' };

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          `${this.baseUrl}/notifications/event?id=` + id,
          { headers: headers }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  getChannelById = async (id: '66f2f1967360595aabe48154') => {
    const headers = { 'content-type': 'application/json' };

    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          `${this.baseUrl}/notifications/channel?id=` + id,
          { headers: headers }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  saveNewTemplate = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/notifications/template`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  sendNotification = async (params: any = {}) => {
    const headers = { 'content-type': 'application/json' };

    try {
      return await lastValueFrom(
        this.httpClient.get<any>(`${this.baseUrl}/api/notifications/send`, {
          headers: headers,
          params: params,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  changeStatusNotification = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.put<any>(
          `${this.baseUrl}/notifications/change_status_notification`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  changeStatusEvent = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.put<any>(
          `${this.baseUrl}/notifications/change_status_event`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  changeStatusChannel = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.put<any>(
          `${this.baseUrl}/notifications/change_status_channel`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  deleteNotification = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return await lastValueFrom(
        this.httpClient.delete<any>(
          `${this.baseUrl}/notifications/notification`,
          { body, headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  saveNewEvent = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return await lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/notifications/event`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  saveNewNotification = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return await lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/notifications/config-event-by-channel`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  bulkUploadNotifications = async (body: any) => {
    const headers = {};
    const request = {};

    try {
      return await lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/notifications/bulk_upload_notification`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  editTemplate = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.put<any>(
          `${this.baseUrl}/notifications/template`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  updateQueue = async (body: any) => {
    const headers = { 'content-type': 'application/json' };
    const request = {};

    try {
      return lastValueFrom(
        this.httpClient.put<any>(
          `${this.baseUrl}/notifications/queue`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };
}