<div class="row">
    <div class="col-12 mt-2">
        <div class="mt-2 mb-5">

            <form *ngIf="!loadForm" autocomplete="nope" novalidate [formGroup]="form" class="text-start">
                <div class="row">

                    <div class="form-group  col-12 ">
                        <div class="form-floating">
                            <select [ngClass]="{'error': form.controls.condition.invalid && form.controls.condition.dirty}" class="form-select" id="condition" formControlName="condition" aria-label="Floating label select example">
                                <option  value="">Selecione</option>
                                <option  value="Empleado">Empleado</option>
                                <option  value="Empleador">Empleador</option>
                                <option  value="Negocio Propio">Negocio Propio</option>
                                <option  value="Retirado">Retirado</option>
                                <option  value="Otro">Otro</option>
                            </select>

                            <label for="condition">Condición Laboral</label>
                        </div>

                        <div class="form-control-feedback help-block" *ngIf="form.get('condition').errors">
                            <div *ngIf="form.get('condition').errors['required'] && !form.get('condition').pristine">
                                Campo requerido
                            </div>
                        </div>

                    </div>

                    <div class="form-group  mt-3" *ngIf="this.form?.getRawValue()?.condition == 'Otro'">
                        <div class="form-floating">
                            <textarea [ngClass]="{'error': form.controls.description.invalid && form.controls.description.dirty}" class="form-control " id="description" formControlName="description" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                            <label for="floatingTextarea2">Descripción</label>
                        </div>
                    </div>

                    <br>
                    <div class="form-group  col-12 mt-4 mb-2">
                        <div class=" float-end">
                            <button (click)="submit()" [disabled]="!form.valid || submit_disabled" class="btn btn-block btn-primary ">
                        <span >Guardar</span>
                        <span *ngIf="submit_disabled">
                          <i class="fa  fa-spinner fa-spin ms-2"></i>
                        </span>
                      </button>
                        </div>
                    </div>




                </div>

            </form>
            <ng-container *ngIf="loadForm ">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>
        </div>
    </div>
</div>