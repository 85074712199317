import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../services/notification.service';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import * as alertifyjs from 'alertifyjs';
import { AuthService } from '../services/auth/auth.service';
import { MbscDatetimeOptions } from '@mobiscroll/angular';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit {
  filterChannel: any = "";
  selectedEventCode: any = 'df';
  event: any;
  channel: any;
  templates: any[] = [];
  queues: any[] = [];
  channels: any[] = [];
  events: any[] = [];
  length: any;
  eventsByChannel: any[] = [];
  selectedEvent: any[] = [];
  selectedTemplateNotificationId: any;
  selectedTemplateNotif: any;
  selectedTemplate: any;
  matchedTemplate: any;
  selectedChannel: any;
  filteredTemplates: any[] = [];
  availableChannels: any[] = [];

  formType: any;
  showModal = false;

  selectedItem: any;
  actionType: any;

  formNotifications: FormGroup;
  selectedChannelId: any;
  showForm: boolean = false;
  maxCharacters: any;
  sessionUser: any;
  showSettings: any;

  fileName = '';
  formDataUpload: any = null;
  loadingDoc: any;
  mobileSettingsFile: MbscDatetimeOptions = {
    display: 'bottom',
  };

  viewMore: any;

  notificationSelected: any;

  constructor(
    private offcanvasService: NgbOffcanvas,
    private NotificationService: NotificationService,
    private formBuilder: FormBuilder,
    public _auth: AuthService,
    private sanitizer: DomSanitizer
  ) {
    this.formNotifications = this.formBuilder.group({
      event: [
        this.getEventIdByCode(this.selectedEventCode),
        Validators.required,
      ],
      channel: ['', Validators.required],
      template: ['', Validators.required],
    });
  }

  filterTemplates(event: any) {}
  ngOnInit(): void {
    // If your component needs to load data from an API or service when it is first displayed,
    // it's common to do so in ngOnInit.

    const id = '66f2f50e7360595aabe48159';
    this.loadEventById(id);

    const id2 = '66f2f1967360595aabe48154';
    this.loadChannelById(id2);

    this.loadActiveChannels();

    this.loadActiveEvents();

    this.loadActiveTemplates();

    this.loadActiveEventsByChannel();

    this.loadActiveQueues();

    this.sessionUser = this._auth.sessionUser;
  }

  update = async() => {
    await this.loadActiveEventsByChannel();
    this.onChangeEvent();
  }
  // checkOwnerRole(): boolean {
  //   return this.sessionUser?.role === 'OWNER';
  // }

  getSanitizedMessage(message: string) {
    return this.sanitizer.bypassSecurityTrustHtml(message);
  }

  getEventIdByCode(selectedEventCode: string): string | null {
    const selectedEvent = this.events.find(
      (event) => event?.code === selectedEventCode
    );
    return selectedEvent ? selectedEvent._id : '';
  }

  async modal(content: TemplateRef<any>, data: any = {}) {
    try {
      this.formNotifications.patchValue({
        event: this.getEventIdByCode(this.selectedEventCode),
      });
      this.offcanvasService.open(content, {
        position: 'bottom',
        keyboard: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  modalClose() {
    this.offcanvasService.dismiss();
  }

  editTemplate = (actionType: 'create' | 'edit', content: TemplateRef<any>, item: any = null, notification: any) => {
    this.notificationSelected = notification;
    this.openModal(actionType, content, item);
  }

  openModal(
    actionType: 'create' | 'edit',
    content: TemplateRef<any>,
    item: any = null
  ) {
    this.selectedItem = item;
    this.actionType = actionType;

    this.toggleForm('template', content);
  }


  toggleForm(type: string, content: TemplateRef<any>) {
    this.formType = type;
    this.modal(content);
  }

  toggleSettings() {
    this.showSettings = !this.showSettings;
  }

  toggleViewMore(item: any) {
    try {
      this.selectedTemplate =
        this.selectedTemplate === item.code ? null : item.code;
    } catch (error) {
      console.log(error);
    }
  }

  toggleViewMoreNotifications(item: any) {
    try {
      this.selectedTemplateNotif =
        this.selectedTemplateNotif === item.template._id
          ? null
          : item.template._id;
    } catch (error) {
      console.log(error);
    }
  }

  selectedTemplateId(templateId: any, template: any) {
    this.selectedTemplateNotificationId = templateId;

    this.modal(template);
  }

  onChangeEvent() {
    debugger;
    if (this.selectedEventCode) {
      this.availableChannels=[];
      const events = this.eventsByChannel.filter(
        (event) => event.event.code === this.selectedEventCode
      );

      if (events && events.length > 0) {
        this.selectedEvent = events.map((event) => {
          const matchedTemplate = this.templates.find(
            (template) => template._id === event.template._id
          );

          // Return the event with the matched template name
          return {
            ...event,
            templateName: matchedTemplate ? matchedTemplate.description : '',
          };
        });

        // Step 1: Extract the associated channels from the selectedEvent
        const associatedChannelIds = this.selectedEvent.map(
          (event) => event.channel._id
        );

        // Step 2: Filter out the associated channels from the full channels list
        this.availableChannels = this.channels.filter(
          (channel) => !associatedChannelIds.includes(channel._id)
        );
      } else {
        console.warn('No events found for the selected code');
        this.selectedEvent = [];
        this.availableChannels = [...this.channels]; // Reset available channels if no event found
      }
    }
  }

  onChannelChange() {
    this.selectedChannel = this.formNotifications.get('channel')?.value;

    this.filteredTemplates = this.templates.filter(
      (template) => template.channel === this.selectedChannel
    );

    if (
      !this.filteredTemplates.some(
        (template) =>
          template._id === this.formNotifications.get('template')?.value
      )
    ) {
      this.formNotifications.get('template')?.setValue('');
    }
  }

  selectTemplate(templateId: string) {
    this.formNotifications.get('template')?.setValue(templateId);
  }

  async loadEventById(eventId: any) {
    if (eventId) {
      try {
        const eventData = await this.NotificationService.getEventById(eventId);
        this.event = eventData;
      } catch (error) {
        console.error('Error loading event:', error);
      }
    }
  }

  async loadChannelById(channelId: any) {
    if (channelId) {
      try {
        const channelData = await this.NotificationService.getChannelById(
          channelId
        );
        this.channel = channelData;

        console.log(this.channel);
      } catch (error) {
        console.error('Error loading channel:', error);
      }
    }
  }

  async loadActiveChannels() {
    try {
      const activeChannelData =
        await this.NotificationService.getActiveChannels();
      this.channels = activeChannelData.response;

      console.log();
    } catch (error) {
      console.error('Error loading channel:', error);
    }
  }

  async loadActiveEvents() {
    try {
      const activeEventsData = await this.NotificationService.getActiveEvents();
      this.events = activeEventsData.response;
    } catch (error) {
      console.error('Error loading channel:', error);
    }
  }

  async loadActiveQueues() {
    try {
      const activeData = await this.NotificationService.getActiveQueues();
      this.queues = activeData.response;
    } catch (error) {
      console.error('Error loading channel:', error);
    }
  }

  async loadActiveTemplates() {
    try {
      const activeTemplatesData =
        await this.NotificationService.getActiveTemplates();
      this.templates = activeTemplatesData.response;

      if (this.channels) {
        this.templates = this.templates.map((template) => {
          const matchedChannel = this.channels.find(
            (channel) => channel._id === template.channel
          );
          return {
            ...template,
            channelName: matchedChannel ? matchedChannel.description : '',
            channelIcon: matchedChannel ? matchedChannel.icon : '',
          };
        });
      } else {
        console.warn('Channels are not loaded yet');
      }
    } catch (error) {
      console.error('Error loading channel:', error);
    }
  }

  async loadActiveEventsByChannel() {
    try {
      const activeEventsByChannelData =
        await this.NotificationService.getActiveEventsByChannel();
      this.eventsByChannel = activeEventsByChannelData.response;
    } catch (error) {
      console.error('Error loading channel:', error);
    }
  }

  async changeStatusNotification(itemId: string, eventId: any, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const newStatus = isChecked ? 'ACTIVE' : 'INACTIVE';

    const itemIndex = this.selectedEvent.findIndex(
      (item) => item._id === itemId
    );

    const previousStatus = this.selectedEvent[itemIndex].status;

    const body = {
      notification: itemId,
      status: newStatus,
    };

    try {
      await this.NotificationService.changeStatusNotification(body);
      this.selectedEvent[itemIndex].status = newStatus;
      this.update();
    } catch (error) {
      console.error('Error updating status:', error);
      this.selectedEvent[itemIndex].status = previousStatus;
    }
  }

  async changeStatusEvent(itemId: string, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const newStatus = isChecked ? 'ACTIVE' : 'INACTIVE';

    const body = {
      event: itemId,
      status: newStatus,
    };

    try {
      await this.NotificationService.changeStatusEvent(body);
      console.log('Status updated successfully');
      this.loadActiveEvents();
      this.update();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  }

  async changeStatusChannel(itemId: string, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;

    const newStatus = isChecked ? 'ACTIVE' : 'INACTIVE';

    const body = {
      channel: itemId,
      status: newStatus,
    };

    try {
      await this.NotificationService.changeStatusChannel(body);
      alertifyjs.success('Actualización exitosa!');
      this.loadActiveChannels();
      this.update();
    } catch (error) {
      console.error('Error updating status:', error); // Debug: Log error
    }
  }

  async deleteNotification(itemId: any) {
    const body = {
      notification: itemId,
    };

    const result = await Swal.fire({
      title: '¿Deseas eliminar esta notificación?',
      text: '¡No podrás revertir esto!',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    });

    if (result.isConfirmed) {
      try {
        await this.NotificationService.deleteNotification(body);
        this.update();
        Swal.fire({
          icon: 'success',
          title: 'Eliminado!',
          text: 'La notificación ha sido eliminada.',
        });
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al eliminar la notificación.',
        });
      }
    }
  }

  onFileSelected = async (event: any) => {
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      this.formDataUpload = file;
    } else {
      console.warn('No file selected');
    }
  };

  submitFile = async () => {
    this.loadingDoc = true;

    try {
      const formDataBulkUpload = new FormData();

      if (this.formDataUpload) {
        formDataBulkUpload.append('file', this.formDataUpload);
      } else {
        console.warn('No file available for upload');
      }

      let resp = await this.NotificationService.bulkUploadNotifications(
        formDataBulkUpload
      );

      alertifyjs.success(`Carga exitosa!`);
    } catch (error: any) {
      console.error('Error during file upload:', error);

      if (error.status == 400) {
        alertifyjs.error(error.error.msg);
      } else {
        alertifyjs.error(`Error al cargar documento`);
      }
    }

    this.loadingDoc = false;
  };

  pauseQueue = async (group: any, status: any) => {
    if(status!=='CANCELLED') {
      let question = status==='PENDING'? '¿Deseas pausar esta notificación?': '¿Deseas reactivar esta notificación?'
      const result = await Swal.fire({
        title: question,
        text: '',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: 'Sí!',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      });

      if (result.isConfirmed) {
        try {
          let body;
          if(status==='PENDING'){
            body = {
              group,
              status: 'PAUSED'
            };
          } else {
            body = {
              group,
              status: 'PENDING'
            };
          }
          await this.NotificationService.updateQueue(body);
          await this.loadActiveQueues();
          Swal.fire({
            icon: 'success',
            title: 'Exito!',
            text: 'La cola ha sido actualizada.',
          });
        } catch (error) {
          console.error(error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Ocurrió un error al actualizar la cola.',
          });
        }
      }
    }
  }

  cancelQueue = async (group: any, status: any) => {
    let question = '¿Deseas cancelar esta notificación?'
    const result = await Swal.fire({
      title: question,
      text: 'Esta acción no podrá ser devuelta',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Sí!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    });

    if (result.isConfirmed) {
      try {
        let body;
        
        body = {
            group,
            status: 'CANCELLED'
        };
        
        await this.NotificationService.updateQueue(body);
        await this.loadActiveQueues();
        Swal.fire({
          icon: 'success',
          title: 'Exito!',
          text: 'La cola ha sido actualizada.',
        });
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al actualizar la cola.',
        });
      }
    }
  }

  async saveNewNotification() {
    if (this.formNotifications.valid) {
      const body = {
        event: this.formNotifications.get('event')?.value,
        channel: this.formNotifications.get('channel')?.value,
        template: this.formNotifications.get('template')?.value,
      };

      try {
        await this.NotificationService.saveNewNotification(body);
        this.update();
        this.modalClose();
        this.formNotifications.reset();
      } catch (error) {
        console.error('Error saving new template:', error);
      }
    } else {
      console.warn('Form is invalid:', this.formNotifications.errors);
    }
  }
}


