<ng-template #showPrivatePlans let-offcanvas>
  <div class="p-5">
    <div class="h5 mb-4">
      Seleccione un plan para agregar:
    </div>
    <div class="modal-body">
      <div *ngFor="let item of privatePlans" 
           class="select-box mt-3 pt-4 my-3 p-3 bg-body rounded shadow-sm border" (click)="selectPlan(item)" [ngClass]="{ 'opaque-plan': isPlanAdded(item._id) }">
        <ng-container>
          <div class="text-primary fw-bold">{{ item.name }}</div>
          <div class="text-secondary small">{{ item.description }}</div>
          <div class="mt-4">
            <span class="badge bg-secondary2 me-2 mt-2">Monto mínimo: {{ item.min_amount }} USD</span>
            <span class="badge bg-secondary2 me-2 mt-2">Cuotas mínimas: {{ item.min_installments }}</span>
            <span class="badge bg-secondary2 me-2 mt-2">Inicial: {{ item.initial_installMent ? 'Sí' : 'No' }}</span>
            <span class="badge bg-secondary2 me-2 mt-2" *ngIf="item.initialInstallMent">Monto inicial: {{ item.amount_initital_installment }}{{ item.type_amount_initital_installment == 'P' ? '%' : ' USD' }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<div class="mt-md-3 p-3">
<div class=" col-12 col-md-8 offset-md-2">

            <h5 class="mb-1 text-secondary  mb-4 text-center"> <i class="  fas fa-user"></i> Planes
            </h5>



            <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && !person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                <img *ngIf="person?.person.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                <img *ngIf="person?.person.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">

            </div>


            <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                <img class="rounded-circle  " [src]="sanitize(person?.person?.file_selfie?.url)">
            </div>


           

            <div *ngIf="!loading && person?.person" class="text-center mb-3">
                <div class="text-capitalize h4 text-dark">{{person?.person?.name}} {{person?.person?.lastName}}</div>
                <div> {{getIdentity(person)}}</div>
                <div> {{getContact(person)}}</div>
                <div> {{getEmailContact(person)}}</div>


</div>
        
<div *ngIf="loading" class="w-100 text-center mt-2">
    <img src="./assets/public/images/loading.gif  " alt=" ">
</div>

<div *ngIf="!loading" class="col-12 mt-5 mb-3">
    <div class="row">
            <div class="col-8 h5 pt-2 pb-2 text-muted">
                Privados
            </div>

        <!-- revisar esto -->
        <div class="col-4 text-end">
            <button class="btn btn-primary btn-sm" (click)="pocisionBottom(showPrivatePlans)">
                Agregar plan
            </button>
        </div>
    </div>
</div>

    <div class="mt-3 pt-4 pb-4 my-3 p-3 bg-body rounded shadow-sm" *ngFor="let plan of plans">
            <ng-container>
                <div class="search-results">
                            <div class="ms-auto text-end small">
  <div class="cursor-pointer float-end">
    <span
      *ngIf="plan?.by_default === plan?.plan"
      class="badge bg-success"
    >
      Por defecto
    </span>
    <i
      *ngIf="plan?.by_default !== plan?.plan"
      class="fas fa-plus text-success"
      style="font-size: 18px; cursor: pointer !important;"
      (click)="setDefaultPlan(plan)"
    ></i>
    &nbsp;
    <i
      class="far fa-trash-alt text-danger"
      style="font-size: 18px; cursor: pointer !important;"
      (click)="removePlan(plan)"
    ></i>
    <div class="mt-1" style="text-align: right;">
      <span class="view-more" (click)="details(plan)">Ver más</span>
    </div>
  </div>
</div>


                        </div>
                        <div class="text-primary fw-bold">{{plan.planName}}
                        </div>
                        <div class="text-secondary small">{{plan?.planDescription}}</div>
                        <div *ngIf="selectedPlan === plan?.code">
                            <div class="mt-4">
                                <span class="badge bg-secondary2 me-2 mt-2">Ejecutivo: {{ plan?.ejecutiveName }} {{ plan?.ejecutiveLastname }}</span>
                                <span class="badge bg-secondary2 me-2 mt-2">Monto mínimo: {{plan?.minAmount}} USD</span>
                                <span class="badge bg-secondary2 me-2 mt-2">Cuotas mínimas: {{plan?.minInstallments}}</span>
                                <span class="badge bg-secondary2 me-2 mt-2">Inicial: {{plan?.initial_installMent ? 'Sí' : 'No'}}</span>
                                <span class="badge bg-secondary2 me-2 mt-2" *ngIf="plan?.initialInstallMent">Monto inicial: {{plan?.inititalInstallment}}{{plan?.typeAmountInititalInstallment == 'P' ? '%' : ' USD'}}</span>
                                <span class="badge bg-secondary2 me-2 mt-2" *ngIf="!plan?.plan?.calendarTime">Cuota cada: {{plan?.calendarTime}} D</span>
                                <span class="badge bg-secondary2 me-2 mt-2" *ngIf="!plan?.plan?.planPublic">Público: {{plan?.planPublic ? 'Sí' : 'No'}}</span>
                            </div>
                </div>
            </ng-container>
        </div>

       <div *ngIf="plans.length === 0" class="mt-3 pt-4 my-3 p-3 bg-body rounded shadow-sm">
        <p>Este cliente no tiene planes privados asociados.</p>
    </div>

<div *ngIf="!loading" class="col-12 mt-5 mb-3">
    <div class="input_group">
        <div class="form-floating">
            <div class="h5 text-muted">
                Públicos
            </div>
        </div>
    </div>

    <div class="mt-3 pt-4 my-3 p-3 bg-body rounded shadow-sm">
        <ng-container *ngFor="let item of publicPlans">
            <div class="search-results">
                <div class="ms-auto text-end small">
                    <div class="float-end">
                            <span class="badge bg-success mt-2">Por defecto</span>
                        <div class="mt-1" style="text-align: right;">
                            <span class="view-more float-end" (click)="details(item)">Ver más</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-primary fw-bold">{{item.name}}</div>
            <div class="text-secondary small">{{item?.description}}</div>
            <div *ngIf="selectedPlan === item?.code">
                <div class="mt-4">
                    <span class="badge bg-secondary2 me-2 mt-2">Monto mínimo: {{item?.min_amount}} USD</span>
                    <span class="badge bg-secondary2 me-2 mt-2">Cuotas mínimas: {{item?.min_number_installment}}</span>
                    <span class="badge bg-secondary2 me-2 mt-2">Inicial: {{item?.initial_installMent ? 'Si' : 'No'}}</span>
                    <span class="badge bg-secondary2 me-2 mt-2" *ngIf="item?.initial_installMent">Monto inicial: {{item?.amount_initial_installMent}}{{item?.type_amount_initial_installMent == 'P' ? '%' : ' USD'}}</span>
                </div>
            </div>
            <div class="border-bottom pb-4 mb-4"></div>
        </ng-container>

        <ng-container *ngIf="showAllPublicPlansToggle">
            <ng-container *ngFor="let item of morePublicPlans">
                <div class="search-results">
                    <div class="ms-auto text-end small">
                        <div class="cursor-pointer float-end">
                            <div class="mt-4" style="text-align: right;">
                                <span class="view-more" (click)="details(item)">Ver más</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-primary fw-bold">{{item.name}}</div>
                <div class="text-secondary small">{{item?.description}}</div>
                <div *ngIf="selectedPlan === item?.code">
                    <div class="mt-4">
                        <span class="badge bg-secondary2 me-2 mt-2">Monto mínimo: {{item?.min_amount}} USD</span>
                        <span class="badge bg-secondary2 me-2 mt-2">Cuotas mínimas: {{item?.min_number_installment}}</span>
                        <span class="badge bg-secondary2 me-2 mt-2">Inicial: {{item?.initial_installMent ? 'Si' : 'No'}}</span>
                        <span class="badge bg-secondary2 me-2 mt-2" *ngIf="item?.initial_installMent">Monto inicial: {{item?.amount_initial_installMent}}{{item?.type_amount_initial_installMent == 'P' ? '%' : ' USD'}}</span>
                    </div>
                </div>
                <div class="border-bottom pb-4 mb-4"></div>
            </ng-container>
        </ng-container>

        <div class="text-center mt-4">
            <span class="view-more" (click)="showAllPublicPlans(morePublicPlans)">
                {{ showAllPublicPlansToggle ? 'Ocultar planes públicos' : 'Ver todos los planes públicos' }}
            </span>
        </div>
    </div>
</div>



