<div class="w-100 small" *ngIf="trace_documets?.length > 0">
    
    <div class="text-dark  " *ngFor="let item of trace_documets">
        <div class="row">
            <div class="col-12">
                <div class="text-end mt-1" *ngIf="!item?.date_end"> 
                    <i (click)="restart()" class="fas fa-sync-alt"></i>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card w-100">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">

                            <div class="text-capitalize fw-bold ">
                                <span *ngIf="item?.state == 'COMPLETE'" class="text-success">Completado</span>
                                <span *ngIf="item?.state == 'PENDING'" class="text-warning">Pendiente</span>
                                <span *ngIf="item?.state == 'ERROR'" class="text-danger">Error al generar</span>

                            </div>

                            <div class="ms-auto">
                                <!-- <div *ngIf="item?.url" (click)="dowloader_document(item?.url)" class="text-primary cursor-pounter">Descargar</div>

                                <div> -->

                            
                                <div *ngIf="!item?.url" class="small text-secondary cursor-pounter ">Tiempo aproximado <b>{{item?.time_aprox}}Min</b></div>

                            </div>

                        </div>
                        <div class="d-flex justify-content-between text-dark small">
                            <div class="">
                                <div class=" text-capitalize fw-bold">Iniciado</div>
                            </div>
                            <div class="ms-auto">

                                <div class="">{{item?.date_start | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                            </div>
                        </div>

                        <div *ngIf="item?.date_end" class="d-flex justify-content-between text-dark mt-1 small">
                            <div class="">
                                <div class=" text-capitalize  fw-bold">Finalizado</div>
                            </div>
                            <div class="ms-auto">
                                <div class="">{{item?.date_end | date: 'dd/MM/yyyy h:mm:ss a'}} </div>
                            </div>
                        </div>

                        <div *ngIf="item?.date_end && item?.date_end" class="d-flex justify-content-between text-dark mt-1 small">
                            <div class="">
                                <div class=" text-capitalize  fw-bold">Duración</div>
                            </div>
                            <div class="ms-auto">
                                <div class=""> {{ diffDatesMinute(item?.date_start, item?.date_end) }}</div>
                            </div>
                        </div>

                        <div *ngIf="item?.state == 'ERROR'" class="d-flex justify-content-between text-dark mt-1 small">
                            <div class="">
                                <div class=" text-capitalize  fw-bold">Details</div>
                            </div>
                            <div class="ms-auto">
                                <span  class="text-secondary small">{{item?.description}}</span>

                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>


    </div>

    <!-- <pre>{{ trace_documets | json }}</pre> -->

</div>