import { Component, OnDestroy, OnInit, TemplateRef, VERSION } from '@angular/core';
import { SwPush } from "@angular/service-worker";

import { Router } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
import { SocketioService } from './services/socket/socketio.service';
import { ConnectionService } from 'ng-connection-service';
import { mobiscroll, MbscOptionlistOptions, MbscDatetimeOptions, MbscNavOptions, MbscListviewOptions } from '@mobiscroll/angular';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { RedirecFormService } from './services/redirec-form.service';
import { environment } from 'src/environments/environment';
import { WebcamImage } from 'ngx-webcam';
import { UserService } from './services/user/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import * as alertifyjs from 'alertifyjs';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit ,OnDestroy{

  readonly VAPID_PUBLIC_KEY = 'BFdx5lgvzDO_03ODeqguT-92Yv55yWLXg2fnQwPgVnWFaQRoMTti7LAFXxS9F3X8L7XFfSrtVldsDy2QV8C2loo';

  name = "Angular " + VERSION.major;
  
  env =environment;

  closeResult: string = "";
  usersActives:any = [];
  usersMsg:any = [];
  tokenNotifications:any;
  isAuth:any;
  menuOption:any;
  title = 'kredit';
  status;
  isConnected;
  isIdentity:any;
  activeMenu:any;
  currentTab = 'downloaded';
  rate:any;
  settings: MbscOptionlistOptions = {
    responsive: {
      small: {
          controls: ['calendar'],
          display: 'center'
      },
      medium: {
          display: 'bubble',
      }
  },
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light',
    display: 'bottom',
    select: 'single',
    layout: 'fixed',
    itemWidth: 90,
    onInit: (event, inst) => {
        // Your custom event handler goes here
    },
    onMarkupReady: (event, inst) => {
        // Your custom event handler goes here
    },
    onMove: (event, inst) => {
        // Your custom event handler goes here
    },
    onItemTap: (event, inst) => {
        // Your custom event handler goes here
    },
    onAnimationStart: (event, inst) => {
        // Your custom event handler goes here
    },
    onAnimationEnd: (event, inst) => {
        // Your custom event handler goes here
    },
    onGestureStart: (event, inst) => {
        // Your custom event handler goes here
    },
    onGestureEnd: (event, inst) => {
        // Your custom event handler goes here
    }
};
form:any  = {
  user:"",
  mensaje:"",
}

uid:any;
showNav:any;
biometria:any;
config:any = {};
  constructor(
    private sanitizer: DomSanitizer,

    public _auth : AuthService,
    public router: Router,
    private connectionService: ConnectionService,
    private offcanvasService: NgbOffcanvas,
    public socketService: SocketioService,
    private redirecFormService:RedirecFormService,
    private _user:UserService,
    public httpClient: HttpClient,
    private swPush: SwPush


 ) {
  //window.location.href = "https://kredit.akeela.co";

  var MyDate = new Date();
  console.log(MyDate);
  
   
  this._auth.isAuth;

  if(navigator.onLine) {

    this.isConnected = true;
    this.status = "ONLINE";

} else {
    this.isConnected = false;
    this.status = "OFFLINE";

}



this.connectionService.monitor().subscribe(isConnected => {

    if (isConnected) {

        this.status = "ONLINE";

        setTimeout(() => {
            this.isConnected = isConnected;
        }, 5000);

    }
    else {
        this.status = "OFFLINE";
        this.isConnected = isConnected;
    }
});


}


  ngOnInit () {
    this.requestSubscription();

    this.reloadVersion();
  
    this._user.biometria$.subscribe((response) => {

      this.biometria = response;

     });



    this._user.nav$.subscribe((response) => {

     this.showNav = response;

    });
 

    this._auth.auth$.subscribe((rep: any) => {

      if(rep){

        this.uid = this._auth.sessionUser?.uid;

        if(this.uid && this.tokenNotifications ){
          this.setTokenNotifications(this.uid,this.tokenNotifications);

        }

      }
      this.isAuth = rep;

      if (rep) {
        let sessionUser = rep? this._auth.sessionUser: null; 

        if(sessionUser?.role != 'COMPUTER_ROLE'){

          this.activeMenu = true;
        }
      }

    });

    this._auth.ident$.subscribe((rep: any) => {
      this.isIdentity = rep;

    });


    this._auth.menuOption.subscribe((rep: any) => {
      
      this.menuOption = rep;

    });

  }





  reloadVersion = async () => {
    try {


     let config_package = require('../../package.json');
     console.log('Akeela Versión: '+ config_package.version);

     if(localStorage.getItem('msg_version')){

      alertifyjs.success(`Nueva versión actualizada ${config_package.version}`);
      localStorage.removeItem('msg_version');

     } 


      let consult = true;
  
      // Leer el contenido de package.json sin caché
      try {
        
        let data:any = await this._auth.getVersion();
        this.config = data;
        console.log(this.config);
    } catch (error) {
      consult = false;
        console.error('Error al cargar package.json:', error);
    }
  
     
    let config = this.config;
     console.log("************************************************");
     console.log("Creditotal System Version: "+ config.version);
     console.log("************************************************");
     const currentVersion = config.version;
     const previousVersion = localStorage.getItem('version');


   
     if (consult && (config_package?.version != currentVersion) && (previousVersion === null || previousVersion !== currentVersion)) {
   
       console.log("*************************************************");
       console.log("*New Version: "+config.version+". Will be reload*");
       console.log("*************************************************");
       localStorage.setItem('version', currentVersion);
       localStorage.setItem('msg_version', currentVersion);

           
       // Generar un timestamp único
            let timestamp = new Date().getTime();
            // Obtener la URL actual
            let urlString = window.location.href;
        
            // Verificar si la URL ya tiene parámetros
            if (urlString.indexOf('?') !== -1) {
                // Si la URL ya tiene parámetros, añadir el timestamp al final
                window.location.href = urlString + '&cache=' + timestamp;
            } else {
                // Si la URL no tiene parámetros, agregar el timestamp como el primer parámetro
                window.location.href = urlString + '?cache=' + timestamp;
            }
  
      }else{
        
        localStorage.setItem('version', currentVersion);

      }
   
   } catch (error) {
     console.log(error);
     
   }
   };

  forceReload() {
          //if(environment.production) {
          if(true){
              const form = document.createElement('form');
              form.method = "POST";
              form.action = location.href;
              document.body.appendChild(form);
              form.submit();
          } else {
              window.location.reload();
          }
      }
  
  
  ngOnDestroy() {
  // this.socketService.disconnect();
  }

setOptionMenu = (opt:any) => {

  this._auth.setmenuOption(opt);
   
}

closeOffcanvas(){
  this.offcanvasService.dismiss('Cross click')
}

enviar_psicometrico(){

 // document.psicometrico.submit()
}



requestSubscription = () => {

  if (!this.swPush.isEnabled) {
    console.log("Notification is not enabled.");
    return;
  }

  this.swPush.requestSubscription({

    serverPublicKey: this.VAPID_PUBLIC_KEY

  }).then((token) => {

   // console.log(JSON.stringify(token:any));

    this.tokenNotifications = token;


    if(this.uid && this.tokenNotifications ){
      this.setTokenNotifications(this.uid,this.tokenNotifications);

    }

  }).catch((error) => {
    console.log(error)
  
  });
}


setTokenNotifications = async (uid:any,token:any) => {

  try{

   let resp = await this._user.saveTokenNotifications({token, uid});

  } catch (error:any) {

    console.log(error);
  

}

}


sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}

}