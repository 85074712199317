

import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import { mobiscroll, MbscCalendarOptions } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import * as alertifyjs from 'alertifyjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light'
};

@Component({
  selector: 'app-credit-calculator',
  templateUrl: './credit-calculator.component.html',
  styleUrls: ['./credit-calculator.component.css']
})

export class CreditCalculatorComponent implements OnInit {

  @Output() close = new EventEmitter<any>();

  dataJson = {};

  dateSetting: MbscCalendarOptions = {
    responsive: {
      small: {
          controls: ['calendar'],
          display: 'center'
      },
      medium: {
          display: 'bubble',
      }
  },
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light'
  };

  birthday:any;
  birthdayString:any ="";
  form:any;
  form2:any;
  form3:any;

  submit_disabled:any;
  person:any;
  searchPerson:any;
  step:any = 1;
  validateNumber:any;

  type_identity:any = [];
  code_identity:any = [];
  marital_status:any ;
  identityResponse:any;
  currencyResponse:any;
  result:any;
  buro:any={};
  errorMsg:any;
  rate:any;

  constructor(
    private formBuilder: FormBuilder,
    private _user:UserService,
    private router: Router,
    public _auth: AuthService,
    private sanitizer: DomSanitizer,
    private offcanvasService: NgbOffcanvas



  ) { }

  ngOnInit(): void {

    this.birthday =  new Date(`06/02/1988`);


    localStorage.removeItem('new_person');
    this.searchPerson =null;

    this.form = this.formBuilder.group({

      type_identity: ['CI',[Validators.required]],
      code_identity: ['V',[Validators.required]],
      currency: ['USD',[Validators.required]],
      identity: ['',[Validators.required,Validators.minLength(7),Validators.maxLength(8)],],
      total_amount: ['',[Validators.required]],
    
  });

  
  this.getLists();
  this.getRate();

  
  }

  getLists = async () =>  {
    const [marital_status, type_identity,code_identity] = await Promise.all([
      await this._user.getPersonMaritalStatus(),
      await this._user.getPersonTypeIdentity(),
      await this._user.getPersonCodeIdentity()
  ]);

    this.marital_status = marital_status.resp;
    this.type_identity = type_identity.resp;
    this.code_identity = code_identity.resp;
  }

  submit = async () =>  {

    return new Promise( async (resolve, reject)  => {

    this.searchPerson =null;

    this.result=false;
    this.person = null;
    if ( this.form.valid ) {
      
        this.submit_disabled = true;
        const data = this.form.getRawValue();
        this.identityResponse = data.code_identity+data.identity;

        let params = {
          "subscriber": {
            "subscriber": 1,
            "shop": '1020',
            "cash_register": 1,
            "user": "V19163767"
          },
          "client": {
            "type_identity": data.type_identity ,
            "code_identity": data.code_identity,
            "identity": data.identity
          },
          "params": {
            "plan_id":"63dc1738b5e22028eedb0521",
            "currency": data.currency,
            "total_amount": data.total_amount,
            "chage_rate": this.rate?.amount
          },
          "calculator":true
        };


    try {
         
      let resp = await this._user.clientQuota(params);


      this.currencyResponse = data.currency;

      this.searchPerson =resp?.res;
      

      this.submit_disabled = false;
      resolve(true);

     } catch (error:any) {

      const params = error?.error?.error;
      if(params?.code){
        this.errorMsg = params?.msg;
      }

        this.submit_disabled = false;
        reject(error);

     }
  }
});
}



sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}



pocisionBottom = async (content: TemplateRef<any>,data:any= {}) =>  {

  await this.submit();

  if(!this.errorMsg){
  
      this.offcanvasService.open(content, { position: 'bottom',keyboard: false} );
  
    
  }
 
}

setStringDate(date:any){
  
const fecha = new Date("2023-02-21T04:00:00.000Z");

  return fecha;

}

getRate = async () => {
  try {
    let data =  { "subscriptor":1};
    const  respose  = await this._user.getRate(data);
    let rate = respose?.res;
    this.rate = rate;
  } catch (error) {
    console.log(error);
  }
  
};

}
