import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-direct-debit-trace-document',
  templateUrl: './trace-document.component.html',
  styleUrls: ['./trace-document.component.css']
})
export class TraceDocumentComponent implements OnInit {
  trace_documets:any  =[]; 
  type: string = '';

  @Input()
  set setType(value: string) {
      this.type = value;
  }

  @Output() trace = new EventEmitter<any>();

  @Output() response = new EventEmitter<any>();


  constructor(
    private _user: UserService
  ) { }

  ngOnInit(): void {
    this.getTrace_documets();
  }

  restart = () => {
    this.getTrace_documets();
    this.trace.emit(true);
  }

  getTrace_documets =  async () =>{
    if(this.type!==''){
      try {
        let result = await this._user.traceDocumentsGet({type: this.type});
        this.trace_documets = result.resp;  
        this.response.emit(this.trace_documets);  
      } catch (error) {
        this.trace_documets = [];
        this.response.emit([]); 
        console.log(error);
      }
    } else {
      this.trace_documets = [];
      this.response.emit([]); 
    }
  }

  diffDatesMinute = (from: string, to: string) => {
    try {
      const date1: any = new Date(from);
      const date2: any= new Date(to); // Asegúrate de cambiar esta fecha para el fin del período
      const diffInMs = Math.abs(date2 - date1);
      const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

      if (diffInMinutes < 1) {
        const diffInSeconds = Math.floor(diffInMs / 1000);
        return diffInSeconds + ' Seg.';
    } else {
        return diffInMinutes + ' Min.';
    }

      
    } catch(error) {
      return null;
    }
  }
}
