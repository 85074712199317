
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import { mobiscroll, MbscCalendarOptions, MbscFormOptions, MbscDatetimeOptions } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import * as alertifyjs from 'alertifyjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light'
};


@Component({
  selector: 'app-new-plan',
  templateUrl: './new-plan.component.html',
  styleUrls: ['./new-plan.component.css'],
  providers: [DatePipe]

})
export class NewPlanComponent implements OnInit {


  @Output() close = new EventEmitter<any>();

  dateSetting: MbscCalendarOptions = {
    responsive: {
      small: {
          controls: ['calendar'],
          display: 'center'
      },
      medium: {
          display: 'bubble',
      }
  },
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light'
  };

  birthday:any;
  birthdayString:any ="";
  form:any;
  form2:any;
  form3:any;

  submit_disabled:any;
  person:any;
  searchPerson:any;
  step:any = 1;
  validateNumber:any;

  type_identity:any = [];
  code_identity:any = [];
  marital_status:any ;
  result:any;
  buro:any={};
  loading:any;

  submit_disabled_contact:any;
  validate_contact:any;

  activeValidateTlf = false;
  dataValidateTlf:any;
  email = "^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$";
  buro_loading:any;

  date_base: any = new Date;
  date_base_string :any;
  

  mobileSettingsFile: MbscDatetimeOptions = {
    display: 'bottom'
};

  mobileSettings: MbscDatetimeOptions = {
      display: 'bottom',
      themeVariant: 'dark',
  };
  

  desktopSettings: MbscDatetimeOptions = {
      display: 'bubble',
      touchUi: false
  };

  formSettings: MbscFormOptions = {
      inputStyle: 'box'
  };
  env = environment;
  show_data_buro:any;
  
  constructor(
    private formBuilder: FormBuilder,
    private _user:UserService,
    private datePipe: DatePipe,
    private router: Router,
    public _auth: AuthService


  ) { }

  ngOnInit(): void {

    this.date_base_string = this.datePipe.transform(this.date_base, 'MM/dd/yyyy');
    this.birthday =  new Date(`06/02/1988`);


      this.form = this.formBuilder.group({
        code: ['',[Validators.required]],
        name: ['',[Validators.required]],
        description: [''],
        min_amount: ['',[Validators.required]],
        min_number_installment: [''],
        calendar: ['false',[Validators.required]],
        calendar_time: [''],
        calendar_type: [''],
        initial_installMent: ['',[Validators.required]],
        type_amount_initial_installMent: ['P'],
        amount_initial_installMent: [''],
        public: [true,[Validators.required]]


      });


     //this.getLists();
  
  }



  // simple handler without any parameters
  birthdaySet() {
    const date: any = this.datePipe.transform(this.birthday, 'dd/MM/yyyy');
    this.form3.patchValue({
      birth_date: date
   });
  }


  submit = async () => {

          console.clear();
      if ( this.form.valid) {
          this.submit_disabled = true;
          let form_resp = this.form.getRawValue();

         form_resp = {
           ...form_resp,
           //subscriber:this.env?.subscriber
           subscriber:"63dc1737b5e22028eedb051e"

        }
      
          try {
            let resp = await this._user.createPlan(form_resp);
            this.close.emit(true);

            alertifyjs.success('Creacion exitosa');
           //  this.router.navigate([environment.loginRoute], {});
             
           } catch (error:any) {

            alertifyjs.error(error?.error?.msg);
              console.error(error);
              this.submit_disabled = false;
           }
      }   
  }

getCodeBuroIdentity(code:any){
  let resp;
switch (code) {
  case "V":
    resp = 1;
    break;
    case "CP":
      resp = 2;
      break;
      case "E":
        resp = 3;
        break;
        case "P":
          resp = 4;
          break;

}

return resp;
}


 jsDateToEpoch = (d:any) => {

  // d = javascript date obj
  // returns epoch timestamp
  return (d.getTime())
}


  showValidateTel = () =>{

    let contact =  this.form2.getRawValue()?.contact;

   // contact = "+584241724412";
   this.step = 4;

   this.activeValidateTlf = true;
   this.dataValidateTlf = `+58${contact}`;

    this._user.setShowValidateTel(`+58${contact}`);
      
  }



  showValidateTel2 = () =>{
    this.loading = true;
    let contact =  this.form2.getRawValue()?.contact;
   this.dataValidateTlf = `+58${contact}`;

  }


  closeValidateTlf = (e:any) => {

    this.activeValidateTlf = false;
    this.step = 2;


}

setValidateTlf = (e:any) => {
    let datas = this._user.tlfValidates$.getValue();

    datas[e] = true;

    this._user.setTlfValidates(datas);

}
  



  sendNoteClient = async (msg:any,phone:any) => {
    
    try {
      const data =  {
          phone,
          msg,
          "client": "CrediTotal"
        }
    
            let result = await this._user.sendsmsGeneral(data);
    
            if (!(result?.error)) {
    
    
            }
    
    } catch (error) {
      console.error(error);
      
      
    }
  
    



 }

  
dateSet(e: any) {

  let date_base =  e.valueText;
  
  let  temp = date_base? date_base.split("/") : [];
  this.date_base_string = `${temp[1]}/${temp[0]}/${temp[2]}`;
  




}



}

