<div class="mt-md-3 container-fluid">

    <div class="row mt-2">
        <div class=" col-12 col-md-8 offset-md-2">
            <div class="row rmt-2">
                <div class="col-12  col-md-8 offset-md-2">
                    <h5 class=" text-secondary text-center mb-5">
                        Aumento masivo de cupos
                    </h5>


                    <div class="row">


                        <ng-container>

                            <div>
                                <mbsc-input [options]="mobileSettingsFile" (change)="onFileSelected($event)" type="file" placeholder="Select file...">Adjunte el soporte</mbsc-input>
                            </div>

                            <div class="form-control-feedback help-block">
                                <div *ngIf="!this.formDataUpload">
                                    El adjunto es obligatorio
                                </div>

                            </div>
                        </ng-container>



                    </div>
                    <div class="row mt-4">
                        <div class="col-12 mt-2 ">
                            <div class="d-grid gap-2 ">
                                <button [disabled]="!formDataUpload " (click)="submit()" type="button " class="btn btn-primary  ">
                                    <span *ngIf="!loadingXls">Cargar </span>
                                    <span *ngIf="loadingXls">Cargando...</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="pt-4" *ngIf="trace_documets?.length > 0">

                        <div class="text-end mt-2">
                            <i (click)="reload_noti()" class="fas fa-sync-alt"></i>
                        </div>

                        <div class=" d-flex text-dark  " *ngFor="let item of trace_documets">

                            <div class="card w-100 mt-3">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between">

                                        <div class="text-capitalize fw-bold ">
                                            <span *ngIf="item?.state == 'COMPLETE'" class="text-success">Completado</span>
                                            <span *ngIf="item?.state == 'PENDING'" class="text-warning">Pendiente</span>
                                            <span *ngIf="item?.state == 'ERROR'" class="text-danger">Error al generar</span>

                                        </div>

                                        <div class="ms-auto">
                                            <div *ngIf="item?.url" (click)="dowloader_document(item?.url)" class="text-primary cursor-pounter" >Descargar</div>

                                            <div>

                                            </div>
                                            <div *ngIf="!item?.url" class="small text-secondary cursor-pounter ">Tiempo aprox. <b>{{item?.time_aprox}}Min</b></div>

                                        </div>

                                    </div>

                                    <div class="d-flex justify-content-between text-dark mt-3 small">
                                        <div class="">
                                            <div class=" text-capitalize pt-1 fw-bold">Iniciado</div>
                                        </div>
                                        <div class="ms-auto">

                                            <div class="">{{item?.date_start | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                        </div>
                                    </div>

                                    <div *ngIf="item?.date_end" class="d-flex justify-content-between text-dark mt-1 small">
                                        <div class="">
                                            <div class=" text-capitalize pt-1 fw-bold">Finalizado</div>
                                        </div>
                                        <div class="ms-auto">
                                            <div class="">{{item?.date_end | date: 'dd/MM/yyyy h:mm:ss a'}}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>

                        <!-- <pre>{{ trace_documets | json }}</pre> -->

                    </div>



                </div>



            </div>
        </div>

    </div>
</div>
