<div class="container mt-2  mt-md-4">

    <ng-container *ngIf="loading">
        <div class="w-100 text-center">
            <img src="./assets/public/images/loading.gif  " alt=" ">
        </div>
    </ng-container>

    <!-- 

    <div class="  box p-3 mt-2 mt-md-5 mb-3 pb-4 " *ngIf="!loadingdashboardLA && notClient">

        <div class="mt-4 ">
            <div class="h3 text-center text-primary">
                Hola {{sessionUser?.nombre}}
            </div>

            <div>

                <div class="mt-5 h4 text-center">
                    Bienvenido a <br> kredit Casa de Bolsa
                </div>

                <div class="mt-4 text-center  text-md-justify">
                    Hemos detectado que aún no posees cuenta de inversión registrada y aprobada. Te invitamos a que hagas tu registro a través de nuestro Onboarding Digital
                </div>

                <div class="mt-5 text-center">
                    <button (click)="goToUrl()" type="button" class="btn btn-primary">Abrir cuenta</button>

                </div>

            </div>
        </div>



    </div>


    <div class="  box p-3 mt-2 mt-md-5 mb-3 pb-4 " *ngIf="!loadingdashboardLA && errorClient">

        <div class="mt-4 ">
            <div class="h3 text-center text-primary">
                Hola {{sessionUser?.nombre}}
            </div>

            <div>

                <div class="mt-5 h4 text-center">
                    Bienvenido a <br> kredit Casa de Bolsa
                </div>

                <div class="mt-4 text-center  text-md-justify">
                    Ocurrió un error al consultar tu información, haz click en reintentar
                </div>

                <div class="mt-5 text-center">
                    <button (click)="reintentar()" type="button" class="btn btn-primary">Reintentar</button>

                </div>

            </div>
        </div>



    </div> -->

</div>