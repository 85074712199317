<!-- <pre>Buro {{ buro | json }}</pre> -->

<div class="container ">
    <div class="row">
        <div class="text-start col-12 col-md-6 offset-md-3 mt-3 mb-3">
            <code>https://kredit-api.akeela.co/api/invoice-credit/client-quota</code>

        </div>

        <form autocomplete="nope" novalidate [formGroup]="form" (ngSubmit)="submit()" class="text-start col-12 col-md-6 offset-3">
            <div class="row mt-3">
                <div class="form-group  col-12">

                    <div class="form-floating">
                        <select [ngClass]="{'error': form.controls.type_identity.invalid && form.controls.type_identity.dirty}" class="form-select" id="type_identity" formControlName="type_identity" aria-label="Floating label select example">
                        <option  value="">Selecione</option>
                        <option *ngFor="let item of type_identity"  [value]="item.code">{{item.name}}</option>
                    </select>

                        <label for="type_identity">Tipo de Documento</label>
                    </div>

                    <div class="form-control-feedback help-block" *ngIf="form.get('type_identity').errors">
                        <div *ngIf="form.get('type_identity').errors['required'] && !form.get('type_identity').pristine">
                            Campo requerido
                        </div>
                    </div>

                </div>

                <div class="form-group  col-4 mt-3">

                    <div class="form-floating">
                        <select [ngClass]="{'error': form.controls.code_identity.invalid && form.controls.code_identity.dirty}" class="form-select" id="code_identity" formControlName="code_identity" aria-label="Floating label select example">
                        <option  value="">Selecione</option>
                        <option *ngFor="let item of code_identity"  [value]="item.code">{{item.name}}</option>
                                 </select>



                        <label for="code_identity">Tipo</label>
                    </div>

                    <div class="form-control-feedback help-block" *ngIf="form.get('code_identity').errors">
                        <div *ngIf="form.get('code_identity').errors['required'] && !form.get('code_identity').pristine">
                            Campo requerido
                        </div>
                    </div>
                </div>

                <div class="form-group  col-8  mt-3">

                    <div class="form-floating ">
                        <input type="text" [ngClass]="{'error': form.controls.identity.invalid && form.controls.identity.dirty}" class="form-control text-capitalize" id="identity" formControlName="identity">
                        <label for="identity" class=" label font-weight-normal" style="word-wrap: break-word;">
                      <span >Documento </span>
                 </label>
                    </div>

                    <div class="form-control-feedback help-block" *ngIf="form.get('identity').errors">
                        <div *ngIf="form.get('identity').errors['required'] && !form.get('identity').pristine">
                            Campo requerido
                        </div>
                        <div *ngIf="form.get('identity').hasError('minlength')  && !form.get('identity').pristine">
                            Mínimo 7 caracteres
                        </div>

                        <div *ngIf="form.get('identity').hasError('maxlength')  && !form.get('identity').pristine">
                            Máximo 8 caracteres
                        </div>

                    </div>
                </div>
                <div class="form-group  col-12  mt-3">

                    <div class="form-floating ">
                        <input type="number" [ngClass]="{'error': form.controls.total_amount.invalid && form.controls.total_amount.dirty}" class="form-control text-capitalize" id="total_amount" formControlName="total_amount">
                        <label for="total_amount" class=" label font-weight-normal" style="word-wrap: break-word;">
                      <span >Monto Total (Dolares) </span>
                 </label>
                    </div>

                    <div class="form-control-feedback help-block" *ngIf="form.get('total_amount').errors">
                        <div *ngIf="form.get('total_amount').errors['required'] && !form.get('total_amount').pristine">
                            Campo requerido
                        </div>
                        <div *ngIf="form.get('total_amount').hasError('min')  && !form.get('total_amount').pristine">
                            Mínimo 20 Dolares
                        </div>


                    </div>
                </div>

                <br>
                <div class="form-group  col-12 mt-4 mb-2">
                    <div class=" float-end">

                        <button type="submit" [disabled]="!form.valid || submit_disabled" class="btn btn-block btn-primary ">
                                    <span >Consultar</span>
                                    <span *ngIf="submit_disabled">
                                      <i class="fa  fa-spinner fa-spin ms-2"></i>
                                    </span>
                                  </button>

                    </div>

                </div>

            </div>
        </form>

    </div>

    <div class="mt-3 text-center" *ngIf="searchPerson">

        <h5>Json enviado(Post)</h5>

        <pre>
        {{ dataJson | json }}
            
    </pre>

        <h5 class="mt-2 text-primary">Json Respuesta</h5>

        <pre>{{ searchPerson | json }}</pre>
    </div>
</div>