import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MbscDatetimeOptions } from '@mobiscroll/angular';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';

@Component({
  selector: 'app-notification-xlsx',
  templateUrl: './notification-xlsx.component.html',
  styleUrls: ['./notification-xlsx.component.css']
})
export class NotificationXlsxComponent implements OnInit {
  loadingXls:any;
  type:any = '';
  fileName = '';
  formDataUpload:any = null;
  trace_documets:any  =[]; 
  loadingXls2:any;


  mobileSettingsFile: MbscDatetimeOptions = {
    display: 'bottom'
};

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    public socketService: SocketioService


  ) { }



  ngOnInit(): void {

    this.getTrace_documets('NOTIFICATION_1');

  }

  reload_noti(){
    this.getTrace_documets('NOTIFICATION_1');


  }

  onFileSelected  = async  (event:any) => {
    const file:File = event.target.files[0];
  /*
    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    
    console.log(`El tamaño del archivo es de ${fileSizeInMB.toFixed(2)} MB`);
  */
  
    if (file) {
        this.fileName = file.name;
        this.formDataUpload = file;
  
       /* const formData = new FormData();
  
        formData.append("archivo", file);
        
        formData.append("params", JSON.stringify({
           nombre:"juan soto"
        })
  
        
      );*/
       // const resp = await this._LaService.setUpload(formData);
  
    }
  
  
  }



  submit = async () => {

    this.loadingXls = true;
    try {


      const formData = new FormData();

      if (this.formDataUpload && this.type != '') {

       formData.append("file", this.formDataUpload);
       formData.append("type", this.type);

     }

     let resp = await this._user.send_notification(formData);
     this.reload_noti();

        alertifyjs.success(`Solicitud de notificación de mensajes exitosa`);
        this.loadingXls = false;

 
    } catch (error:any) {

      if(error.status == 400 ){

        alertifyjs.error(error.error.msg);



      }else{
        alertifyjs.error(`Error al enviar  notificación de mensajes`);


      }

      }
  }


public getTrace_documets   =  async (type:any) =>{

  try {
    try {

      let result = await this._user.traceDocumentsGet({type});

      this.trace_documets = result.resp;
          
    } catch (error) {
      console.log(error);
      
      
    }

  } catch (error) {
    
    this.loadingXls2 = false;


  }

}


  
}

