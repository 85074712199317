import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import { CommonService } from 'src/app/services/common.service';
import { MbscDatetimeOptions, mobiscroll } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};

const now = new Date();

//const week = [ new Date(now.getFullYear(),0, 1),now];
let week = [ new Date() ,new Date()];


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
  providers: [DatePipe]

})
export class ReportComponent implements OnInit {
  loading:any;
  buro_stadistic:any = [];


 range: Array < Date > = week;
 date: Array < Date > = [];
 nonForm: Array < Date > = [];
 external = week;
 d1:any;
 d2:any; 


 dateSetting3: MbscDatetimeOptions = {
   lang: 'es',
   theme: 'ios',
   themeVariant: 'light',
   max:now,
   responsive: {
     small: {
         controls: ['calendar'],
         display: 'center'
     },
     medium: {
         display: 'bubble',
     }
 }
 
 };

 birthday = new Date();
date_base: any = new Date;
date_base_string :any;

mobileSettings: MbscDatetimeOptions = {
 display: 'bottom',
 themeVariant: 'dark',
};


desktopSettings: MbscDatetimeOptions = {
 display: 'bubble',
 touchUi: false
};


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _common:CommonService,
    public socketService: SocketioService,
    private datePipe: DatePipe,
    

  ) { }

  ngOnInit(): void {


    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');
  
    this.socketService.resetstadistic_approvated.subscribe((stadistic: any) => {

       

      if(!stadistic){
        this.getDataBuroSimple(true);

      }else{

        this.getDataBuroSimple(false);


      }


    });

  }


  getDataBuroSimple = async (load:any=true) => {
    this.loading = load;

    try {

      let query =  {
        init:this.d1,
        end:this.d2
      };


      
      const buro_stadistic = await this._common.getScore_buro_stadistic(query);
      this.buro_stadistic = buro_stadistic?.resp;
      this.loading = false;

    } catch (error) {
      console.log(error);
      this.loading = false;

      
    }

    
    

  }


  birthdaySet() {  
    
    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');
    this.getDataBuroSimple(false);
    
}
  

}
