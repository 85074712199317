<ng-template #pocision let-offcanvas>

    <div class="offcanvas-header">
        <div class=" w-100">
            <div class="col-12 col-md-6 offset-md-3 ">
                <div class="row">
                    <div class="col-10">
                        <div class="text-capitalize h4 text-secondary mt-2 ">
                            Consulta cupo
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>

    <div class="offcanvas-body">
        <div class="row">
            <div class="col-12 col-md-6 offset-md-3 ">
                <ng-container *ngIf="searchPerson && !submit_disabled">


                    <div class="ms-auto me-auto mt-3 mb-3 bg-body ro&& !submit_disabledunded shadow-sm rounded-circle avatar">
                        <img *ngIf="searchPerson?.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                        <img *ngIf="searchPerson?.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">

                    </div>
                    <div class="text-center mb-1">
                        <div class="text-capitalize h4 text-dark">{{searchPerson?.name}} {{searchPerson?.lastName}}</div>
                        <div> {{identityResponse}}</div>
                    </div>

                    <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">


                        <div class="row rmt-2">
                            <div class="col-12">
                                <div class="col-12 text-end mt-4">

                                    <!-- <button (click)="restClient()" class="btn  btn-outline-dark btn-sm ">
                                        <span >Restablecer Cliente</span>
                                       
                                    </button> -->
                                </div>
                                <div class="small text-secondary">Cupo</div>
                                <h5 class="fw-bolder text-dark">{{searchPerson?.current_quota? searchPerson?.current_quota : base_amount }} USD</h5>
                            </div>

                            <div class="col-12 ">
                                <div class="small text-secondary">Disponible</div>
                                <div class="text-dark">{{searchPerson?.quota | currency:'USD':'': '1.2-2' }} USD</div>
                            </div>
                            <div class="col-6">
                                <div class="small text-secondary">Estatus</div>
                                <div class=" text-dark">{{searchPerson?.quota_active ? 'Activo':'Inactivo'}}</div>
                            </div>
                            <div class="col-6 text-end">
                                <div class="small text-secondary ">Habilitado</div>
                                <div class=" text-dark">{{searchPerson?.quota_client_active ? 'Si':'No'}}</div>
                            </div>

                            <div class="col-6 ">
                                <div class="small text-secondary">Empresa</div>
                                <div class=" text-dark">Mundo Total</div>
                            </div>

                            <div class="col-6 text-end">
                                <div class="small text-secondary">Notas activas</div>
                                <div (click)="offcanvasServiceClose()" [routerLink]="['/console/person/financial-payments/',searchPerson?.code]" class=" text-dark">
                                    <a class="text-primary">{{countInvoice}}</a> </div>
                            </div>

                        </div>
                    </div>
                </ng-container>


            </div>
        </div>

    </div>
</ng-template>


<div class="container">

    <div class="mt-md-3 p-3">

        <div class="row ">
            <div class=" col-12 col-md-6 offset-md-3  p-0 ">
                <h5 class="mb-4 text-secondary"> <i class="  fas fa-users"></i> Consultar cupo
                </h5>

                <ng-container>


                    <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">

                        <form autocomplete="nope" novalidate [formGroup]="form" class="text-start">
                            <div class="row">
                                <div class="form-group  col-12">
                                    <div class="form-floating">
                                        <select [ngClass]="{'error': form.controls.type_identity.invalid && form.controls.type_identity.dirty}" class="form-select" id="type_identity" formControlName="type_identity" aria-label="Floating label select example">
                            <option  value="">Selecione</option>
                            <option *ngFor="let item of type_identity"  [value]="item.code">{{item.name}}</option>
                        </select>

                                        <label for="type_identity">Tipo de Documento</label>
                                    </div>

                                    <div class="form-control-feedback help-block" *ngIf="form.get('type_identity').errors">
                                        <div *ngIf="form.get('type_identity').errors['required'] && !form.get('type_identity').pristine">
                                            Campo requerido
                                        </div>
                                    </div>

                                </div>

                                <div class="form-group  col-4 mt-3">

                                    <div class="form-floating">
                                        <select [ngClass]="{'error': form.controls.code_identity.invalid && form.controls.code_identity.dirty}" class="form-select" id="code_identity" formControlName="code_identity" aria-label="Floating label select example">
                            <option  value="">Selecione</option>
                            <option *ngFor="let item of code_identity"  [value]="item.code">{{item.name}}</option>
                                     </select>



                                        <label for="code_identity">Tipo</label>
                                    </div>

                                    <div class="form-control-feedback help-block" *ngIf="form.get('code_identity').errors">
                                        <div *ngIf="form.get('code_identity').errors['required'] && !form.get('code_identity').pristine">
                                            Campo requerido
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group  col-8  mt-3">

                                    <div class="form-floating ">
                                        <input type="text" [ngClass]="{'error': form.controls.identity.invalid && form.controls.identity.dirty}" class="form-control text-capitalize" id="identity" formControlName="identity">
                                        <label for="identity" class=" label font-weight-normal" style="word-wrap: break-word;">
                          <span >Documento </span>
                     </label>
                                    </div>

                                    <div class="form-control-feedback help-block" *ngIf="form.get('identity').errors">
                                        <div *ngIf="form.get('identity').errors['required'] && !form.get('identity').pristine">
                                            Campo requerido
                                        </div>
                                        <div *ngIf="form.get('identity').hasError('minlength')  && !form.get('identity').pristine">
                                            Mínimo 7 caracteres
                                        </div>

                                        <div *ngIf="form.get('identity').hasError('maxlength')  && !form.get('identity').pristine">
                                            Máximo 8 caracteres
                                        </div>

                                    </div>
                                </div>
                                <br>
                                <div class="form-group  col-12 mt-4 mb-2">
                                    <div class=" float-end">

                                        <button (click)="pocisionBottom(pocision)" [disabled]="!form.valid || submit_disabled" class="btn btn-block btn-primary ">
                                        <span >Consultar</span>
                                        <span *ngIf="submit_disabled">
                                          <i class="fa  fa-spinner fa-spin ms-2"></i>
                                        </span>
                                      </button>

                                    </div>

                                </div>


                                <hr *ngIf="errorMsg" class="mt-2">
                                <div *ngIf="errorMsg" class="text-danger">
                                    {{errorMsg}}
                                </div>


                            </div>

                        </form>


                    </div>
                </ng-container>


            </div>
        </div>
    </div>
</div>