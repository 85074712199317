<div class="row">
    <div class="col-12 mt-2">
        <div class="mt-2 mb-5">

            <form *ngIf="!loadForm" autocomplete="nope" novalidate [formGroup]="form" class="text-start">
                <div class="row">

                    <div class="form-group col-12  mb-3">

                        <div class="form-floating ">
                            <input type="text" [ngClass]="{'error': form.controls.amount.invalid && form.controls.amount.dirty}" class="form-control" id="amount" formControlName="amount" placeholder="name@example.com">
                            <label for="amount" class=" label font-weight-normal" style="word-wrap: break-word;">
                                <span >Monto Promedio Mensual (USD)</span>
                              </label>
                        </div>


                        <div class="form-control-feedback help-block" *ngIf="form.get('amount').errors">
                            <div *ngIf="form.get('amount').errors['required'] && !form.get('amount').pristine">
                                Campo requerido
                            </div>
                        </div>
                    </div>



                    <br>
                    <div class="form-group  col-12 mt-4 mb-2">
                        <div class=" float-end">
                            <button (click)="submit()" [disabled]="!form.valid || submit_disabled" class="btn btn-block btn-primary ">
                        <span >Guardar</span>
                        <span *ngIf="submit_disabled">
                          <i class="fa  fa-spinner fa-spin ms-2"></i>
                        </span>
                      </button>
                        </div>
                    </div>




                </div>

            </form>
            <ng-container *ngIf="loadForm ">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>
        </div>
    </div>
</div>