<div class="mt-md-3 container-fluid">
    <div class="row ">

        <div class=" col-12 col-md-8 offset-md-2">
            <div class="row rmt-2" *ngIf="!loading_person">
                <div class="col-12  col-md-8 offset-md-2">

                    <h5 class="mb-1 text-secondary mb-4 text-center">

                        <i class="  fas fa-user"></i> Penalidades

                    </h5>

                    <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && !person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                        <img *ngIf="person?.person.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                        <img *ngIf="person?.person.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">
                    </div>


                    <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                        <img class="rounded-circle  " [src]="sanitize(person?.person?.file_selfie?.url)">
                    </div>

                    <div *ngIf="!loading_person && person?.person" class="text-center mb-3">
                        <div class="text-capitalize h4 text-dark">{{person?.person?.name}} {{person?.person?.lastName}}</div>
                        <div> {{getIdentity(person)}}</div>
                        <div> {{getContact(person)}}</div>
                    </div>

                </div>
            </div>

            <ng-container *ngIf="!loading && person?.person" class="mt-2">
                <div class="row mt-2" >
                    <div class="col-12  col-md-8 offset-md-2">
                        <ng-container *ngFor="let row of resp_paymentsCredits">
                            <div class="bg-white mt-4 my-3 p-3 bg-body2  rounded shadow-sm" >
                                <div class="row mt-3 row-k">


                                    


                                        <div class="col-6">

                                            <div class="small text-secondary small pb-2">{{row?._id }}</div>


                                            <div class="small ml-2">
                                                <span>
                                                    {{ row?.params?.concept_deduction?.name }} <br>
                                                    {{ row?.params?.concept_deduction?.description  }}

                                                    <div class="small ">
                                                        <span>
                                                            <div class="text-primary small cursor-pointer" (click)="openBottom(fee,{ code_fee: row?.params?.fee_rules?.code })">  {{row?.params?.fee_rules?.code }}</div>
                                                        </span>
                                                    </div>



                                                    

                                                </span>

                                            </div>
                                            

                                            <div class="small pt-3">
                                                <span>
                                                    Crédito <br>
                                                    Fecha programada de pago: {{ row?.params?.installment?.scheduledPaymentDate | date: 'dd/MM/yyyy' }}   <br>
                                                    {{ row?.params?.invoice?.identifier }} / Cuota {{ row?.params?.installment?.order }}   
                                                                                                   

                                                </span>

                                            </div>

                                            <div class="small pt-3">
                                                <span>
                                                    Pago <br>
                                                                                            
                                                    <div class="small ">
                                                        <span>
                                                            <div class="text-primary small cursor-pointer" (click)="openBottom(payment,{ person_code: row?.params?.person?.code, code_payment: row?.params?.payment?.code})">  {{row?.params?.payment?.code}}</div>
                                                        </span>
                                                    </div>


                                                </span>

                                            </div>

                                            

                                        </div>
                                        <div class="col-6  text-end " >
                                            <div class=" text-dark"> 
                                                <ng-container >{{row?.params?.amount | currency:'USD':'': '1.2-2'}} USD</ng-container>
                                            </div>
                                        </div>


                                </div>
                            </div>
                        </ng-container>
                    </div>

                </div>


            </ng-container>


            <div *ngIf="!loading && !loading_person &&  person?.person && resp_paymentsCredits?.length < 1" class="col-12  col-md-8 offset-md-2">
                <div class="mt-5 my-3 p-3 bg-body rounded shadow-sm">
                    <div>No se encontraron pagos registrados</div>
                </div>
            </div>


            <div *ngIf="!loading_person && !person?.person">
                <div>No se encontro person con este id: {{queryParams?.code}}</div>
            </div>


            <ng-container *ngIf="loading_person ||  loading ">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>
        </div>
    </div>

</div>


<ng-template #payment let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                               Detalle de Pago
                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">
            <div class="row">
                <div class=" col-12 col-md-10 offset-md-1">
                    <div class="text-start">
                        <app-person-payments [code_person]="params_payment['person_code']" [code_payment]="params_payment['code_payment']" (resetStatus)="response($event)"></app-person-payments>
  
                    </div>
                </div>
            </div>
        </div>
    </div>
  </ng-template>


  <ng-template #fee let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                               Detalle de Fee
                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">
            <div class="row">
                <div class=" col-12 col-md-10 offset-md-1">
                    <div class="text-start">
                        <app-fee-detail [code_fee]="params_payment['code_fee']"  ></app-fee-detail>
  
                    </div>
                </div>
            </div>
        </div>
    </div>
  </ng-template>