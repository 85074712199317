


import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import { mobiscroll, MbscCalendarOptions } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import * as alertifyjs from 'alertifyjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { async } from '@angular/core/testing';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light'
};


@Component({
  selector: 'app-consult-quota',
  templateUrl: './consult-quota.component.html',
  styleUrls: ['./consult-quota.component.css']
})
export class ConsultQuotaComponent implements OnInit,OnDestroy {

  @Output() close = new EventEmitter<any>();

  dataJson = {};

  dateSetting: MbscCalendarOptions = {
    responsive: {
      small: {
          controls: ['calendar'],
          display: 'center'
      },
      medium: {
          display: 'bubble',
      }
  },
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light'
  };

  birthday:any;
  birthdayString:any ="";
  form:any;
  form2:any;
  form3:any;

  submit_disabled:any;
  person:any;
  searchPerson:any;
  step:any = 1;
  validateNumber:any;

  type_identity:any = [];
  code_identity:any = [];
  marital_status:any ;
  identityResponse:any;
  countInvoice = 0;
  result:any;
  buro:any={};
  errorMsg:any;
  base_amount:any;
  constructor(
    private formBuilder: FormBuilder,
    private _user:UserService,
    private router: Router,
    public _auth: AuthService,
    private sanitizer: DomSanitizer,
    private offcanvasService: NgbOffcanvas

  ) { }

  ngOnInit(): void {

    this.getBase_amount();
    this.birthday =  new Date(`06/02/1988`);


    localStorage.removeItem('new_person');
    this.searchPerson =null;

    this.form = this.formBuilder.group({

      type_identity: ['CI',[Validators.required]],
      code_identity: ['V',[Validators.required]],
      identity: ['',[Validators.required,Validators.minLength(7),Validators.maxLength(8)],]
  });

  
  this.getLists();

  
  }

  getBase_amount = async () =>  {

    try {

      let response =  await this._user.getBase_amount();
      this.base_amount = response?.amount;

    } catch (error) {

      console.log(error);
      
      
    }
  }

  getLists = async () =>  {
    const [marital_status, type_identity,code_identity] = await Promise.all([
      await this._user.getPersonMaritalStatus(),
      await this._user.getPersonTypeIdentity(),
      await this._user.getPersonCodeIdentity()
  ]);

    this.marital_status = marital_status.resp;
    this.type_identity = type_identity.resp;
    this.code_identity = code_identity.resp;
  }

  submit = async () =>  {

    return new  Promise( async (resolve, reject)  => {

    this.searchPerson =null;
    this.result=false;
    this.person = null;
    if ( this.form.valid ) {
        this.submit_disabled = true;
        const data = this.form.getRawValue();
        this.identityResponse = data.code_identity+data.identity;

      let params = {
      
        "client": {
          "type_identity": data.type_identity ,
          "code_identity": data.code_identity,
          "identity": data.identity
        }
      
      };


    try {
         
      let resp = await this._user.clientQuotaBasic(params);

      this.searchPerson =resp?.res?.person;
      this.countInvoice = resp?.res?.countInvoice;
    
      this.submit_disabled = false;

      resolve(true);


     }  catch (error:any) {

          const params = error?.error?.error;
          if(params?.code){
            this.errorMsg = params?.msg;
          }

        this.submit_disabled = false;
        reject(error);
     }
  }

    });
}


restClient = async () =>  {
  this.searchPerson =null;
  this.result=false;
  this.person = null;
  if ( this.form.valid ) {
      this.submit_disabled = true;
      const data = this.form.getRawValue();
      this.identityResponse = data.code_identity+data.identity;

    let params = {
    
      "client": {
        "type_identity": data.type_identity ,
        "code_identity": data.code_identity,
        "identity": data.identity
      }
    
    };


  try {
       
    let resp = await this._user.clientQuotaReset(params);

    this.searchPerson =resp?.res?.person;
  
     this.submit();

   }  catch (error:any) {

        const params = error?.error?.error;
        if(params?.code){
          this.errorMsg = params?.msg;
        }

      this.submit_disabled = false;
   }
}
}



sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}


pocisionBottom = async (content: TemplateRef<any>,data:any= {}) =>  {

  await this.submit();

  if(!this.errorMsg){
  
      this.offcanvasService.open(content, { position: 'bottom',keyboard: false} );
  
    
  }
 
}

offcanvasServiceClose(){
  this.offcanvasService.dismiss();

}


ngOnDestroy = () => {
  this.offcanvasServiceClose();
}
}
