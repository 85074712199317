

import { DatePipe } from '@angular/common';
import { noUndefined } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import * as alertifyjs from 'alertifyjs';
import { mobiscroll, MbscDatetimeOptions } from '@mobiscroll/angular';
import { AuthService } from 'src/app/services/auth/auth.service';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};

const now = new Date();
const nowm1 = new Date();
const init = new Date((nowm1).setDate(nowm1.getDate() - 1));
const fb = new Date(2023,0,1);


//const week = [ new Date(now.getFullYear(),0, 1),now];
let week = [ fb ,init];



@Component({
  selector: 'app-installments-console',
  templateUrl: './installments-console.component.html',
  styleUrls: ['./installments-console.component.css'],
  providers: [DatePipe]

})
export class InstallmentsConsoleComponent implements OnInit{
   nowm1:any = new Date();

  //pruebass
  queryParams: any;
  person: any;
  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  loading_person = false;
  loading = true;

  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;

  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
   type_approved: any;
   clientQuota:any;
   invoiceCredits: any = [];
   resp_invoiceCredits: any = [];
   installMents: any = [];
   installmentNexPayment :any;
    view = 1;
    invoiceSelected:any;
    installMentSelected:any;
    payment:any;
    stadistic:any;
    typeInstallMents:any= 'p-oll';
    countInstallmente:any = 0;
    countClient:any = 0;

    nextDate:any;
    socketServiceSub:any;
    usersActives:any = {};

    range: Array < Date > = week;
    date: Array < Date > = [];
    nonForm: Array < Date > = [];
    external = week;
    d1:any;
    d2:any; 
  
    dateSetting: MbscDatetimeOptions = {
      lang: 'es',
      theme: 'ios',
      themeVariant: 'light',
      responsive: {
        small: {
            controls: ['calendar'],
            display: 'center'
        },
        medium: {
            display: 'bubble',
        }
    }
    
    };


    dateSetting2: MbscDatetimeOptions = {
      lang: 'es',
      theme: 'ios',
      themeVariant: 'light',
      min: now,
      responsive: {
        small: {
            controls: ['calendar'],
            display: 'center'
        },
        medium: {
            display: 'bubble',
        }
    }
    
    };
  

    
    dateSetting3: MbscDatetimeOptions = {
      lang: 'es',
      theme: 'ios',
      themeVariant: 'light',
      max:init,
      responsive: {
        small: {
            controls: ['calendar'],
            display: 'center'
        },
        medium: {
            display: 'bubble',
        }
    }
    
    };
  
    birthday = new Date();
  date_base: any = new Date;
  date_base_string :any;

  mobileSettings: MbscDatetimeOptions = {
    display: 'bottom',
    themeVariant: 'dark',
};


desktopSettings: MbscDatetimeOptions = {
    display: 'bubble',
    touchUi: false
};
supUse:any;
env = environment;
    loadingXls2:any;
   sumInstallment:any = 0;
   sumInstallmentFalta = 0;


   page = 1; // Current page number
   limit = 200; // Number of documents per page

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    public _auth: AuthService,


  ) { }

  ngOnInit(): void {
    this._auth.auth$.subscribe((rep: any) => {

      let sessionUser = rep? this._auth.sessionUser: null; 
      

      if(
        sessionUser?.username == 'juan@hazling.com' ||
        sessionUser?.username == 'diribarren'||
        sessionUser?.username == 'lrodriguez'
      ){
        this.supUse =true;

      }
      
      //nathalyaespisona@totalmundo.com
 });

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');
  
    this.getNextDate();
    this.loading_person = true;
    this.loading = true;
    this.getListInstallMents();

    
    this.socketServiceSub = this.socketService.usersActives.subscribe((resp: any) => {
      // this.usersActives = resp.filter((item:any) => item.uid != this.uid);
  
      this.usersActives = {};
 
       resp?.map((item:any)=>{
 
       if(item?.role?.role ==  "CLIENT_ROLE"){
 
         this.usersActives[item?.person?.code] = true;
 
       };
 
 
       return item;
 
      });

      this.usersActives;
     });

  }

  // simple handler without any parameters
  birthdaySet() {  

    this.page = 1;
    this.installMents = [];

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');
    this.getListInstallMents();

    
      }
  
  getStadisticServer = async () => {
    try{
        return this._user.getStadistic();

      } catch (error) {
        console.error(error);
        
      }
  }

  getListInstallMents = async () => {
    try {
      
      debugger
      
      this.loading = true;

      const resp = await this.getListInstallMentsService();
      debugger

      let result  = resp.res;
      let installMents:any = this.installMents;


      this.installMents =installMents.concat(result);



      let countInstallmente = 0;
      let countClient = 0;
      let sumInstallment = 0;
      let sumInstallmentFalta = 0;


      this.installMents.map((value:any)=>{
        
        countClient = countClient + (value?.installMents?.length? 1 : 0);
        
        countInstallmente = countInstallmente + (value?.installMents?.length || 0);

        value?.installMents?.map((value2:any)=>{

          sumInstallment = this.toFixedApp(sumInstallment + value2?.amount);
          sumInstallmentFalta = this.toFixedApp(sumInstallmentFalta + value2?.dueBalance);

        });


       // return value;
      })


      this.countClient = countClient;
      this.countInstallmente = countInstallmente;
      this.sumInstallment = sumInstallment;
      this.sumInstallmentFalta = sumInstallmentFalta;
      this.loading = false;


    } catch (error) {
      console.error(error);
      this.loading = false;

    } 

  

  }


  getListInstallMentsService = async () => {
    try{
      this.range;

     debugger
      let query =  {
        page:this.page,
        limit: this.typeInstallMents == 'p-oll' ? 1500: this.limit,
        i: this.typeInstallMents,
        init:this.d1,
        end:this.d2
      };

        return this._user.getInstallMents(query);

      } catch (error) {
        console.error(error);
        
        
      }
  }




  getIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

  }


  getContact(item: any) {

    if (!(item?.constacts?.length > 0))
      return "";

    return `${item?.constacts[0]?.contact}`;


  }


  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }



  public redirect = () => {
    
    let Doc_ID;
    let Type_ID;
    let TLF;


    if (this.person?.identitys?.length > 0) {

      const data = this.person?.identitys[0];
      Type_ID = data.type_identity?.additionalType;
      Doc_ID = data.identity;

    }


    const data = {

      CED: environment.CED,
      IP: environment.IP_LOCAL,
      Type_ID,
      Doc_ID,
      PN: this.person?.person?.name,
      PA: this.person?.person?.lastName,
      FN: this.datePipe.transform(this.person?.person?.birth_date, 'yyyy-MM-dd'),
      GN: this.person?.person?.gender,
      TLF: "",
      EMAIL: ""

    }

    this.redirecFormService.postRedirect(environment.externalPsicometrico, data);

  }



redirectTo(code:any) {
  this.router.navigate([`/console/payments/person/${code}`], {});
}


setTypeInstallMents(){

  this.page = 1;

  this.installMents = [];


  const nowm1 = new Date();
  const init = new Date((nowm1).setDate(nowm1.getDate() + 5));


  if(this.typeInstallMents == 'p'){

  const temp = new Date();
  const ff = new Date((temp).setDate(temp.getDate() + 2));

    let week = [ now ,ff];
    this.range = week;

  }else if(this.typeInstallMents == ''){ 

    const temp = new Date();
    const ff = new Date((temp).setDate(temp.getDate() - 30));
  
    let week = [ now ,now];
    this.range = week;

  }else if(this.typeInstallMents == 'c'){ 


    // const temp = new Date();
    // const ff = new Date((temp).setDate(temp.getDate() - 30));
  
    let week = [ now ,now];
    this.range = week;

  }else  if(this.typeInstallMents == 'p-oll'){

  
    let week = [ fb ,init];
    this.range = week;
  }


  this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
  this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');

  this.getListInstallMents();
}

externalProfile(code:any){

   let url = `console/person/profile/${code}`;
   window.open(url, '_blank');
}

gotoWhatsapp(num:any) : void {
  window.open(" https://wa.me/"+num?.replace('+', ''), "_blank");
}


getNextDate = () => {

  let dateInstallment = new Date();
  const dia = dateInstallment.getDate();
  const mes = dateInstallment.getMonth();
  const anno = dateInstallment.getFullYear();

  const u_dia = new Date(anno, mes + 1, 0).getDate();

  if (dia <= 15) {

    this.nextDate  = new Date(anno, mes, 15);


  } else {

      this.nextDate = new Date(anno, mes, u_dia);


  }
}


installMentNextCuote = async () => {
    
  try {

      await this._user.installMentNextCuote({});
      alertifyjs.success(`Recordatorio de cuotas enviadas exitosamente`);

  } catch (error) {

    console.error(error);
    alertifyjs.error(`Error al enviar mensaje de cuotas`);

  }


}

 restarDia = (num:any, fecha:any) => {
  fecha.setDate(fecha.getDate() - num);
  return new Date(fecha);
}


public generate_installment_csv   =  async () =>{

  this.loadingXls2 = true;
  try {

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');
    debugger
   this._user.getInstallMents_statusFN(
    {
      init:this.d1,
      end:this.d2,
      i: this.typeInstallMents

    });
  this.loadingXls2 = false;

  

   let url = `${environment.BASE_LA}/csv/installments_status.xlsx`;
   window.open(`${url}`);

  } catch (error) {
    
    this.loadingXls2 = false;


  }

}

toFixedApp = (x:any) => {

  let a:any = `e+${2}`;
  let b:any = `e-${2}`;

  return +(Math.round(x + a) + b);

  //return Number(Number.parseFloat(String((x + Number.EPSILON))).toFixed(TOFIXED));
  
}

nextRows = () =>{

  this.page = this.page + 1 ; 
  this.getListInstallMents();
}


}