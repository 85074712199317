<div class="card mb-4"  style="min-height: 200px;" >
    <div class="card-body">
    
    <div class="row pt-3">

   

    
    <div class=" text-start">
        {{ item?.concept_deduction?.code_concept }} · {{item?.concept_deduction?.name}}  
    </div>
    <div class="text-secondary small text-start">
        {{item?.concept_deduction?.description}} 
    </div>

    <div class="mt-4 small">
        

        

        

                
                        
                        
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <span class="text-primary fw-bold">
                                   

                                    <ng-container *ngIf="item.withCondition==null || item.withCondition==true">
                                        {{ getValueByKey(list_fee_attributes,item.attribute) }} {{ getValueByKey(list_comparison, item.condition) }} <span *ngIf="item.condition=='BETWEEN'">{{ item.lower_limit }}  y {{ item.upper_limit }}</span> <span *ngIf="item.condition!='BETWEEN'">{{ item.single_value }}</span>&nbsp;&nbsp;
                                    </ng-container>


                                    <ng-container *ngIf="item.withCondition==false">
                                        Se aplica a todos los casos sin condiciones
                                    </ng-container>
                                    
                                    <br>
                                    <span class="text-secondary small">
                                        {{ item.start_date | date: 'dd/MM/yyyy' }} <span *ngIf="item.end_date"> hasta {{ item.end_date | date: 'dd/MM/yyyy' }}</span>
                                    </span>  
                                </span>
                                <span class="ms-auto text-end  fw-bold">
                                    <span *ngIf="item.fixed_amount">{{ item.currency }} {{ item.fixed_amount }}</span> <span *ngIf="item.percentage"><span *ngIf="item.fixed_amount">+</span>  {{ item.percentage }}% </span>
                                </span>
                            </li>

                

                

         
    </div>


    

    <div class="mt-4 small" *ngIf="item?.concept_deduction?.fee?.account_payment">
        <span class="fw-bold">Cuenta contable SAP</span><br>
        <span class="">Suscriptor: {{ item?.concept_deduction?.fee?.account_payment?.subscriptor_cuenta }} </span>; 
        <span class="">Suscriptor simbolo: {{ item?.concept_deduction?.fee?.account_payment?.subscriptor_simbolo }}</span>; 
        <span class="">Suscriptor MP: {{ item?.concept_deduction?.fee?.account_payment?.subscriptor_mp }}</span>; 
    </div>
    <div class="text-end small text-secondary">
        Por: {{item?.concept_deduction?.user?.name }} {{item?.concept_deduction?.user?.lastName }} <br>
        {{item?.concept_deduction?.user?.date | date: 'dd/MM/yyyy hh:mm a' }}
    </div>
</div>
</div>