
<!--  -->
<div class="container" appDnd (fileDropped)="onFileDropped($event)" *ngIf="files.length===0">
    <!-- -->
    <input type="file" #fileDropRef id="fileDropRef" multiple  (change)="fileBrowseHandler($event)"/>
    <img src="assets/public/images/ic-upload-file.svg" width="35px" alt="">
    <h3>Arrastre y suelte el archivo aquí</h3>
    <h3>ó</h3>
    <label for="fileDropRef">Buscar archivo</label>
  </div>
  <div class="files-list">
    <div class="single-file" *ngFor="let file of files; let i = index">
      <img src="assets/public/images/ic-file.svg" width="45px" alt="file">
      <div class="info">
        <h4 class="name">
          {{ file?.name }}
        </h4>
        <p class="size" *ngIf="_loading"><br>
          <i class="fas fa-spinner fa-spin"></i> Analizando... <!--{{ formatBytes(file?.size) }}-->
        </p>
        <p class="size" *ngIf="verified"><br>
          <i class="fas fa-check color-check"></i> Verificado el lote
        </p>

        <p class="size" *ngIf="verified && resumen_response">
          <i class="fas fa-check color-check"></i> Total de cobro: {{ resumen_response.total_amount_02.toFixed(2)}} 
        </p>

        <p class="size" *ngIf="verified && resumen_response">
          <i class="fas fa-check color-check"></i> Cantidad de cobros efectivos: {{ resumen_response.count_02}} 
        </p>

        <p class="size" *ngIf="verified && resumen_response">
          <ng-container *ngIf="resumen_response.redflags.length>0"><i class="fas fa-exclamation-triangle color-red"></i> Por favor, revisar el archivo, se encontraron los siguientes redflags:  {{ resumen_response.redflags.length }} </ng-container>
          <ng-container *ngIf="resumen_response.redflags.length==0"> <i class="fas fa-check color-check"></i> No se encontraron redflags. </ng-container>

        </p>

        <!-- {{ resumen_response  | json }} -->
        <!-- <p class="size" *ngIf="error">
          <i class="fas fa-exclamation-triangle color-red"></i> Archivo  no valido o no corresponde, verifique... 
        </p> -->
        <p class="size" *ngIf="error">
          <i class="fas fa-exclamation-triangle color-red"></i> Archivo  no valido o no corresponde, verifique... 
        </p>
        <!-- <app-progress-dnd [progress]="file?.progress"></app-progress-dnd> -->
      </div>
      <img src="assets/public/images/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
    </div>
  </div>