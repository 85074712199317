<div class="mt-md-3 p-3">

    <div class="row ">
        <div class=" col-12 col-md-6 offset-md-3 ">
            <div class="row">
                <div class="mb-4 mt-2 col-12 col-md-6 ">
                    <div class="form-floating ">
                        <select (change)="setShop()" [(ngModel)]="type" class="form-select " id="sssss" aria-label="Floating label select example ">
                      <option   value="">Todas las tiendas</option>
                      <option *ngFor="let item of shops"  [value]="item.code">{{item.name}}</option>

                    </select>
                        <label for="sssss ">Tienada</label>
                    </div>

                </div>

                <ng-container *ngIf="loading ">
                    <div class="w-100 text-center text-md-start">
                        <img src="./assets/public/images/loading.gif  " alt=" ">
                    </div>
                </ng-container>


            </div>

            <div *ngIf="!loading " class="mt-3 my-3 p-3 bg-body rounded shadow-sm">
                <ng-container>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark"> Compras Financiadas 
                                                                    <span>{{stadistic?.invoice}}</span>
                                </span>

                                <span class="fw-bold">
                                 {{stadistic?.totalAmount | currency:'USD':'': '1.2-2'}} USD
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark"> Total inicial
                                </span>

                                <span class="fw-bold">
                                 {{stadistic?.totalminimumPaymentDue | currency:'USD':'': '1.2-2'}} USD
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark"> Total monto financiado
                                </span>

                                <span class="fw-bold">
                                 {{stadistic?.amount | currency:'USD':'': '1.2-2'}} USD
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark"> Total monto <br class="d-block d-md-none"> faltante por pago
                                </span>

                                <span class="fw-bold">
                                 {{stadistic?.dueBalance | currency:'USD':'': '1.2-2'}} USD
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-gray-dark"> Pagos
                                  <span>{{stadistic?.Payment}}</span>
                                </span>

                                <span class="fw-bold">
                                 {{stadistic?.totalPayment | currency:'USD':'': '1.2-2'}} USD
                                </span>
                            </div>

                        </div>
                    </div>



                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span class="text-success"> Pagos conciliados
                                   <span>{{stadistic?.paymentReconciled}}</span>
                                </span>

                                <span class="fw-bold">
                                 {{stadistic?.totalPaymentReconciled | currency:'USD':'': '1.2-2'}} USD
                                </span>
                            </div>

                        </div>
                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <span [ngClass]="{'text-danger': stadistic?.paymentNotReconciled > 0,'text-gray-dark': stadistic?.paymentNotReconciled < 1 }"> Pagos por conciliar
                                   <span>{{stadistic?.paymentNotReconciled}}</span>
                                </span>

                                <span class="fw-bold">
                                 {{stadistic?.totalPaymentNotReconciled | currency:'USD':'': '1.2-2'}} USD
                                </span>
                            </div>

                        </div>
                    </div>
                </ng-container>
                <!-- <pre>
                    {{ stadistic | json }}
                </pre> -->
            </div>


        </div>
    </div>
</div>