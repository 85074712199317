import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import { mobiscroll, MbscCalendarOptions } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import * as alertifyjs from 'alertifyjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RedirecFormService } from 'src/app/services/redirec-form.service';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light'
};

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css'],
  providers: [DatePipe]

})
export class InvoiceComponent implements OnInit {
  @Output() close = new EventEmitter<any>();
  dataJson = {};

  dateSetting: MbscCalendarOptions = {
    responsive: {
      small: {
          controls: ['calendar'],
          display: 'center'
      },
      medium: {
          display: 'bubble',
      }
  },
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light'
  };

  birthday:any;
  birthdayString:any ="";
  form:any;
  form2:any;
  form3:any;

  submit_disabled:any;
  person:any;
  searchPerson:any;
  step:any = 1;
  validateNumber:any;

  type_identity:any = [];
  code_identity:any = [];
  marital_status:any ;
  identityResponse:any;
  currencyResponse:any;
  result:any;
  buro:any={};
  errorMsg:any;
  queryParams: any;

  constructor(
    private formBuilder: FormBuilder,
    private _user:UserService,
    private router: Router,
    public _auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    private offcanvasService: NgbOffcanvas



  ) { }

  ngOnInit(): void {
    
debugger

    this.activatedRoute.params.subscribe(params => {
      this.queryParams = params;

      if (this.queryParams?.code) {

      }
    });



    this.searchPerson =null;

    this.form = this.formBuilder.group({

      type_identity: ['CI',[Validators.required]],
      code_identity: ['V',[Validators.required]],
      currency: ['BS',[Validators.required]],
      chage_rate: ['24.1',[Validators.required]],
      identity: ['',[Validators.required,Validators.minLength(7),Validators.maxLength(8)],],
      total_amount: ['',[Validators.required,Validators.min(20)]],
    
  });

  
  this.getLists();
  
  }

  getLists = async () =>  {
    const [marital_status, type_identity,code_identity] = await Promise.all([
      await this._user.getPersonMaritalStatus(),
      await this._user.getPersonTypeIdentity(),
      await this._user.getPersonCodeIdentity()
  ]);

    this.marital_status = marital_status.resp;
    this.type_identity = type_identity.resp;
    this.code_identity = code_identity.resp;
  }

  submit = async () =>  {
    this.searchPerson =null;

    this.result=false;
    this.person = null;
    if ( this.form.valid ) {
      
        this.submit_disabled = true;
        const data = this.form.getRawValue();
        this.identityResponse = data.code_identity+data.identity;

        let params = {
          "subscriber": {
            "subscriber": 1,
            "shop": 1020,
            "cash_register": 1,
            "user": "V19163767"
          },
          "client": {
            "type_identity": data.type_identity ,
            "code_identity": data.code_identity,
            "identity": data.identity
          },
          "params": {
            "currency": data.currency,
            "total_amount": data.total_amount,
            "chage_rate": data.chage_rate
          }
        };


    try {
         
      let resp = await this._user.clientQuota(params);

      this.currencyResponse = data.currency;

      this.searchPerson =resp?.res;

      this.submit_disabled = false;

     } catch (error:any) {

      const params = error?.error?.error;
      if(params?.code){
        this.errorMsg = params?.msg;
      }

        this.submit_disabled = false;
     }
  }
}



sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}



}
