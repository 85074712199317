<ng-container *ngIf="loading ">
    <div class="w-100 text-center mt-3 ">
        <img src="./assets/public/images/loading.gif " alt=" ">
    </div>
</ng-container>



<form *ngIf="!loading" autocomplete="nope" novalidate [formGroup]="form" class="text-start">
    <div class="row ">

        <div class="form-group col-12 mt-3">

            <div class="form-group  col-12 ">
                <div class="form-floating">
                    <select [disabled]="buro" [ngClass]="{'error': form.controls.public.invalid && form.controls.public.dirty}" class="form-select" id="public" formControlName="public" aria-label="Floating label select example">
                        <option  value="">Selecione</option>
                        <option  value="true">Publico</option>
                            <option  value="false">Privado</option>
                    </select>
                    <label for="public">Visibilidad</label>
                </div>
    
                <div class="form-control-feedback help-block" *ngIf="form.get('public').errors">
                    <div *ngIf="form.get('public').errors['required'] && !form.get('public').pristine">
                        Campo requerido
                    </div>
                </div>
            </div>

            <div class="form-floating  mt-3">
                <input [disabled]="buro" type="text" [ngClass]="{'error': form.controls.code.invalid && form.controls.code.dirty}" class="form-control text-capitalize" id="code" formControlName="code" placeholder="code@example.com">
                <label for="code" class=" label font-weight-normal" style="word-wrap: break-word;">
                    <span >Código</span>
                <span class="text-danger ps-1">*</span>
                </label>
            </div>

            <div class="form-control-feedback help-block" *ngIf="form.get('code').errors">
                <div *ngIf="form.get('code').errors['required'] && !form.get('code').pristine">
                    Campo requerido
                </div>
            </div>

        </div>

        <div class="form-group col-12 mt-3">

            <div class="form-floating ">
                <input [disabled]="buro" type="text" [ngClass]="{'error': form.controls.name.invalid && form.controls.name.dirty}" class="form-control text-capitalize" id="name" formControlName="name" placeholder="name@example.com">
                <label for="name" class=" label font-weight-normal" style="word-wrap: break-word;">
                    <span >Nombre</span>
                <span class="text-danger ps-1">*</span>
                </label>
            </div>

            <div class="form-control-feedback help-block" *ngIf="form.get('name').errors">
                <div *ngIf="form.get('name').errors['required'] && !form.get('name').pristine">
                    Campo requerido
                </div>
            </div>
        </div>

        <div class="form-group  col-12 mt-3">
            <div class="form-floating">
                <textarea [ngClass]="{'error': form.controls.description.invalid && form.controls.description.dirty}" class="form-control " id="description" formControlName="description" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                <label for="floatingTextarea2">Descripción</label>
            </div>
        </div>


        <div class="form-group col-12  col-md-8 mt-3">

            <div class="form-floating ">
                <input [disabled]="buro" type="number" [ngClass]="{'error': form.controls.min_amount.invalid && form.controls.min_amount.dirty}" class="form-control text-capitalize" id="min_amount" formControlName="min_amount" placeholder="min_amount@example.com">
                <label for="min_amount" class=" label font-weight-normal" style="word-wrap: break-word;">
                    <span >Monto mínimo de compra</span>
                <span class="text-danger ps-1">*</span>
                </label>
            </div>

            <div class="form-control-feedback help-block" *ngIf="form.get('min_amount').errors">
                <div *ngIf="form.get('min_amount').errors['required'] && !form.get('min_amount').pristine">
                    Campo requerido
                </div>
            </div>
        </div>


        <div class="form-group col-12  col-md-4 mt-3">

            <div class="form-floating ">
                <input [disabled]="buro" type="number" [ngClass]="{'error': form.controls.min_number_installment.invalid && form.controls.min_number_installment.dirty}" class="form-control text-capitalize" id="min_number_installment" formControlName="min_number_installment"
                    placeholder="min_number_installment@example.com">
                <label for="min_number_installment" class=" label font-weight-normal" style="word-wrap: break-word;">
                    <span >Cant. de cuotas</span>
                <span class="text-danger ps-1">*</span>
                </label>
            </div>

            <div class="form-control-feedback help-block" *ngIf="form.get('min_number_installment').errors">
                <div *ngIf="form.get('min_number_installment').errors['required'] && !form.get('min_number_installment').pristine">
                    Campo requerido
                </div>
            </div>
        </div>




        <div class="form-group  col-12 mt-3">
            <div class="form-floating">
                <select [disabled]="buro" [ngClass]="{'error': form.controls.initial_installMent.invalid && form.controls.initial_installMent.dirty}" class="form-select" id="initial_installMent" formControlName="initial_installMent" aria-label="Floating label select example">
                    <option  value="">Selecione</option>
                    <option  value="true">Si</option>
                        <option  value="false">No</option>
                    </select>
                <label for="initial_installMent">Con inicial</label>
            </div>

            <div class="form-control-feedback help-block" *ngIf="form.get('initial_installMent').errors">
                <div *ngIf="form.get('initial_installMent').errors['required'] && !form.get('initial_installMent').pristine">
                    Campo requerido
                </div>
            </div>
        </div>

        <div class="form-group  col-6 mt-3" *ngIf="form?.getRawValue()?.initial_installMent == 'true'">
            <div class="form-floating">
                <select [disabled]="buro" [ngClass]="{'error': form.controls.type_amount_initial_installMent.invalid && form.controls.type_amount_initial_installMent.dirty}" class="form-select" id="type_amount_initial_installMent" formControlName="type_amount_initial_installMent"
                    aria-label="Floating label select example">
                        <option  value="P">Porcentaje</option>
                        <option  value="A">Monto</option>

                    </select>
                <label for="type_amount_initial_installMent">Base de inicial</label>
            </div>

            <div class="form-control-feedback help-block" *ngIf="form.get('type_amount_initial_installMent').errors">
                <div *ngIf="form.get('type_amount_initial_installMent').errors['required'] && !form.get('type_amount_initial_installMent').pristine">
                    Campo requerido
                </div>
            </div>
        </div>

        <div *ngIf="form?.getRawValue()?.initial_installMent == 'true'" class="form-group  col-6 mt-3">

            <div class="form-floating ">
                <input [disabled]="buro" type="number" [ngClass]="{'error': form.controls.amount_initial_installMent.invalid && form.controls.amount_initial_installMent.dirty}" class="form-control text-capitalize" id="amount_initial_installMent" formControlName="amount_initial_installMent"
                    placeholder="amount_initial_installMent@example.com">
                <label for="amount_initial_installMent" class=" label font-weight-normal" style="word-wrap: break-word;">
                    <span *ngIf="form?.getRawValue()?.type_amount_initial_installMent == 'P'" >%</span>
                    <span *ngIf="form?.getRawValue()?.type_amount_initial_installMent == 'A'">USD</span>
                <span class="text-danger ps-1">*</span>
                </label>
            </div>

            <div class="form-control-feedback help-block" *ngIf="form.get('amount_initial_installMent').errors">
                <div *ngIf="form.get('amount_initial_installMent').errors['required'] && !form.get('amount_initial_installMent').pristine">
                    Campo requerido
                </div>
            </div>
        </div>

        <div class="form-group  col-12 mt-3">
            <div class="form-floating">
                <select [ngClass]="{'error': form.controls.calendar.invalid && form.controls.calendar.dirty}" class="form-select" id="calendar" formControlName="calendar" aria-label="Floating label select example">
                    <option  value="">Selecione</option>
                  <!-- <option  value="true">Días calendario</option>--> 
                        <option  value="false">Días continuos</option>
                </select>
                <label for="calendar">Tipo de frecuencia</label>
            </div>

            <div class="form-control-feedback help-block" *ngIf="form.get('calendar').errors">
                <div *ngIf="form.get('calendar').errors['required'] && !form.get('calendar').pristine">
                    Campo requerido
                </div>
            </div>
        </div>


        <div class="form-group  col-12 mt-3" *ngIf="form?.getRawValue()?.calendar == 'true'">
            <div class="form-floating">
                <select [disabled]="buro" [ngClass]="{'error': form.controls.calendar_type.invalid && form.controls.calendar_type.dirty}" class="form-select" id="calendar_type" formControlName="calendar_type" aria-label="Floating label select example">
                    <option  value="">Selecione</option>
                    <option  value="s">Semanal</option>
                    <option  value="q">Quincenal</option>
                        <option  value="m">Mensual</option>
                        <option  value="b">Bimensual</option>
                        <option  value="t">Trimestral</option>
                </select>
                <label for="calendar_type">Frecuencia</label>
            </div>

            <div class="form-control-feedback help-block" *ngIf="form.get('calendar_type').errors">
                <div *ngIf="form.get('calendar_type').errors['required'] && !form.get('calendar_type').pristine">
                    Campo requerido
                </div>
            </div>
        </div>


        <div class="form-group col-12  col-md-12 mt-3" *ngIf="form?.getRawValue()?.calendar == 'false'">

            <div class="form-floating ">
                <input [disabled]="buro" type="number" [ngClass]="{'error': form.controls.calendar_time.invalid && form.controls.calendar_time.dirty}" class="form-control text-capitalize" id="calendar_time" formControlName="calendar_time" placeholder="calendar_time@example.com">
                <label for="calendar_time" class=" label font-weight-normal" style="word-wrap: break-word;">
                    <span >Frecuencia en (Días)</span>
                <span class="text-danger ps-1">*</span>
                </label>
            </div>

            <div class="form-control-feedback help-block" *ngIf="form.get('calendar_time').errors">
                <div *ngIf="form.get('calendar_time').errors['required'] && !form.get('calendar_time').pristine">
                    Campo requerido
                </div>
            </div>
        </div>



    </div>
</form>

<br>
<div *ngIf=" !loading" class=" col-12 mt-4 mb-2">
    <button (click)="submit()" type="button" [disabled]="!form.valid || submit_disabled" class="btn btn-block btn-primary ">
                        <span >Continuar</span>
                        <span *ngIf="submit_disabled">
                          <i class="fa  fa-spinner fa-spin ms-2"></i>
                        </span>
                      </button>
</div>

<!-- <pre>{{ form.getRawValue() | json }}</pre> -->
<!-- <pre>{{ person | json }}</pre> -->