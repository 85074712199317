import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
  @Input() code_person: any;
  @Output() changeView = new EventEmitter<any>();
  @Output() resetStatus = new EventEmitter<any>();
  @Input() estatus_approve: any;

  form:any;
  submit_disabled:any;

  country: any;
  city:any;
  state: any;
  type: any;

  errorMsg:any;
loadForm:any;
loadPerson_Address:any;
section_transactions:any = [];
personAddress:any = [];
section_complete:any;

  constructor(   
     private _common: CommonService,
     private formBuilder: FormBuilder,
     private _user:UserService,
     public _auth: AuthService,

    ) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({

      country: ['VE',[Validators.required]],
      city: ['Caracas',[Validators.required]],
      state: ['VE-A',[Validators.required]],
      type: ['',[Validators.required]],
      description: ['',[Validators.required]],


  });
  this.getPerson_Address();
  this.getLists();

}



getLists = async () =>  {

  try {
    this.loadForm = true;
    const [country, city,state,type] = await Promise.all([
      await this._common.getCountry({}),
      await this._common.getCity({}),
      await this._common.getState({}),
      await this._common.getTypeAddress({}),
  ]);
  
  this.country = country.resp;
  this.city = city.resp;
  this.state = state.resp;
  this.type = type.resp;
  this.loadForm = false;
  
  
  } catch (error) {
    console.log(error);
    
  }

}


getPerson_Address = async () =>  {

  
  try{
    this.loadPerson_Address= true;
  let resp =  await this._common.getPerson_Address({code_person:this.code_person})

  this.personAddress  = resp?.resp;
  this.loadPerson_Address= false;

  } catch (error) {
    console.log(error);
    this.loadPerson_Address= false;

  }

}

delete_address_person = async (code_address:any) =>  {
  let params = {
    code_person:this.code_person,
    code_address
   };

    try {
        
        let resp = await this._common.delete_address_person(params);

        this.getPerson_Address();
        this.resetStatus.emit(true);

      this.submit_disabled = false;


    }  catch (error:any) {

          const params = error?.error?.error;
          if(params?.code){
            this.errorMsg = params?.msg;
          }

        this.submit_disabled = false;
    }

    }



submit = async () =>  {

  if ( this.form.valid ) {
      this.submit_disabled = true;
      const data = this.form.getRawValue();

    let params = {
      code_person:this.code_person,
      code_country:data.country,
      code_state:data.state,
      code_city:data.city,
      code_type:data.type,
      description:data.description,
  };


  try {
       
    let resp = await this._common.setAddress_Person(params);
    alertifyjs.success('Registro exitoso');

    this.form = this.formBuilder.group({

      country: ['VE',[Validators.required]],
      city: ['Caracas',[Validators.required]],
      state: ['VE-A',[Validators.required]],
      type: ['',[Validators.required]],
      description: ['',[Validators.required]],

      });

      this.getPerson_Address();

      this.resetStatus.emit(true);
    this.submit_disabled = false;


   }  catch (error:any) {

        const params = error?.error?.error;
        if(params?.code){
          this.errorMsg = params?.msg;
        }

      this.submit_disabled = false;
   }
}

}


setChange(View:any){
    
  this.changeView.emit(View);

 }

}