import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MbscDatetimeOptions } from '@mobiscroll/angular';
import { UserService } from 'src/app/services/user/user.service';
import { DirectDebitService } from '../../../services/direct-debit/direct-debit.service';
import * as alertifyjs from 'alertifyjs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-payment-method-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
})
export class EditComponent implements OnInit {
  @Output() changeView = new EventEmitter<any>();
  @ViewChild('miInput') miInput: ElementRef | undefined;
  @Output() resetStatus = new EventEmitter<any>();

  @Input() set dataEdit(id: any) {
    this.id = id;
    this.getPaymentMethod();
  }

  formDataUpload: any = null;
  fileName = '';

  mobileSettingsFile: MbscDatetimeOptions = {
    display: 'bottom',
  };

  banks: any = [];
  bank: any;

  form: any;
  submit_disabled: any;
  validation_account_number: any;

  loadForm: any;
  loadPerson_Address: any;

  id: any;

  payment_method_customer: any;
  bank_name = '';
  bank_id = '';

  value = '';
  last_value = this.value;

  constructor(
    private formBuilder: FormBuilder,
    private _user: UserService,
    private directDebitService: DirectDebitService
  ) {}

  ngOnInit(): void {
    this.getBanks();
    this.form = this.formBuilder.group({
      currency: ['63ffb65dbb023a686c40ab8c', [Validators.required]],
      payment_method: ['640b83e26522a425711e006f', [Validators.required]],
      account_type: ['', [Validators.required]],
      account_number: ['', [Validators.required, Validators.minLength(20)]],
    });
  }

  onChange = ($event: any) => {
    let target = $event.target as HTMLInputElement;
    let value = target.value;

    if (value) {
      if (value && value.length >= 4) {
        this.value = value;
        this.last_value = this.value;
      } else {
        this.value = this.last_value;
      }
    } else {
      this.value = this.last_value;
    }

    this.form.patchValue({
      account_number: this.value,
    });
  };

  ngAfterViewInit() {
    if(this.miInput) {
      this.miInput.nativeElement.onpaste = function(e: any) {
        e.preventDefault();
      };
      this.miInput.nativeElement.oncopy = function(e: any) {
        e.preventDefault();
      };
    }
  }

  getPaymentMethod = async () => {
    this.payment_method_customer = await this._user.getPaymentMethodCustomer(
      this.id
    );
    if (this.payment_method_customer) {
      let payment_method_customer = this.payment_method_customer.resp[0];
      this.form.patchValue({
        account_number: payment_method_customer.account_number,
        account_type: payment_method_customer.account_type
          ? payment_method_customer.account_type
          : '00',
        currency: payment_method_customer.currency
          ? payment_method_customer.currency
          : '63ffb65dbb023a686c40ab8c',
        // otros campos pueden ser omitidos aquí
      });

      this.bank_name = payment_method_customer.bank.name;
      this.bank_id = payment_method_customer.bank.code;

      this.setBank();
    }
  };

  setBank = async () => {
    try {
      // this.value = payment_method_customer.bank.code;
      // this.last_value = this.value;
      let banks_resp = this.banks?.filter((item: any) => {
        return item.code == this.bank_id;
      });
      this.bank = banks_resp[0];
    } catch (error) {
      console.error(error);
    }
  };

  getBanks = async () => {
    try {
      const resp = await this.getBanksServer();
      this.banks = resp.res.filter((item: any) => {
        console.log(item.showClient)
        return item.bankCode ? item.showClient=== true ? true : false : false;
      });
      this.setBank();
    } catch (error) {
      console.error(error);
    }
  };

  getBanksServer = async () => {
    try {
      return this._user.getBanks({});
    } catch (error) {
      console.error(error);
    }
  };

  validate_account_number = () => {
    let campo = this.form.controls.account_number.value;
    if (campo.length == 20) {
      this.validation_account_number = true;
    } else {
      this.validation_account_number = false;
    }
  };

  submit = async () => {
    this.submit_disabled = true;
    if (this.form.valid) {
      const data = this.form.getRawValue();
      if(data.account_number.toString().substring(0,  4) === this.bank.bankCode){

        let payment_method_customer = this.payment_method_customer.resp[0];
        let params: any = {
          currency:
            data.currency !== payment_method_customer.currency
              ? data.currency
              : undefined,
          account_type:
            data.account_type !== payment_method_customer.account_type
              ? data.account_type
              : undefined,
          account_number:
            data.account_number !== payment_method_customer.account_number
              ? data.account_number
              : undefined,
        };
        Object.keys(params).forEach((key) => {
          if (params[key] === undefined) {
            delete params[key];
          }
        });
        if (Object.keys(params).length > 0 || this.formDataUpload) {
          const formData = new FormData();

          if (this.formDataUpload) {
            formData.append('archivo', this.formDataUpload);
          }

          formData.append('params', JSON.stringify(params));

          await this.directDebitService.updatePaymentMethodCustomer(
            formData,
            this.id
          );
          alertifyjs.success('Actualización exitosa');
          this.resetStatus.emit(true);
         
          this.changeView.emit(5);
        } else {
          alertifyjs.error('No hay cambios para actualizar');
        }
        this.submit_disabled = false;
      } else {
        // this.form.patchValue({
        //   account_number: this.bank.bankCode,
        // });
        this.submit_disabled = false;
        alertifyjs.error('Los primeros 4 dígitos del número de cuenta no son válidos para una cuenta del banco seleccionado.');
      }
    }
  };

  onFileSelected = async (event: any) => {
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      this.formDataUpload = file;
      this.validate_account_number();
      this.submit_disabled = false;
    }
  };

  downloadFile = async (url:any, extension: string, name: string) => {
    let res = await this.directDebitService.downloadImage(url, extension);
    if(res) {
      saveAs(res, `${name}.${extension}`);
    }
  }
}
