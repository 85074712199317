import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import * as alertifyjs from 'alertifyjs';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  shops:any = [];
  form:any;
  people:any = [];
  loading:any;
  usersActives:any = {};
  socketServiceSub:any;
  submit_disabled:any;
  active_filter:any;
  people_total:any = 0;
   reload_loading:any;
   rows_number = 0;
   rows_size = 10;
   reload_loading_pluss =true;
   rows:any = [];
   rows_temp:any = [];
   role:any = 'USER_ROLE';
    supUse = false;
    view:any = 1;
    user_options:any;
    usQuote: any = false;
    submit_disabled_contact:any;
    password:any;
    submit_disabled_shop:any;
    submit_disabled_profile:any;
    profile:any = '';
    profiles:any = {};


    shop:any = '';
    obj_shop:any = {};
    status_users:any = true;

   @Output() changeView = new EventEmitter<any>();

    page = 1; // Current page number
    limit = 100; // Number of documents per page

   search_form: any = '';
   constructor(
    private offcanvasService: NgbOffcanvas,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user:UserService,
    private sanitizer: DomSanitizer,
    public socketService: SocketioService,
    public _auth : AuthService


  ) { }
  ngOnDestroy(): void {

    this.socketServiceSub.unsubscribe();
    
  }

  ngOnInit(): void {

    this.profiles =  this._auth.getProfiles();
   
    this.getUsers();
    this.getshops();

    this.socketServiceSub = this.socketService.usersActives.subscribe((resp: any) => {
      // this.usersActives = resp.filter((item:any) => item.uid != this.uid);
  
      this.usersActives = {};
 
       resp?.map((item:any)=> {
 
       if(item?.role?.role ==  "USER_ROLE"){
 
         this.usersActives[item?.person?.code] = true;
 
       };
 
       return item;
 
      });

      this.usersActives;

     });
  }

  openBottom(content: TemplateRef<any>,data:any= {}) {
    this.user_options =  data;
    let user =  this.user_options?.user;

    this.usQuote = user?.status;
    this.shop = user?.shop || '';
    let profile   = this.getProfile(user);
    this.profile = profile ? profile?.key : '';
    
    
    this.offcanvasService.open(content, { position: 'bottom',keyboard: false} );

  }

  closeAddClient(){
    this.getUsers();
    this.offcanvasService.dismiss("");
    
  }


  getUsers = async (loading = true,reload=false) =>  {
    this.loading = loading;

  if(reload)
    this.page = 1;
  

    let filter = {

     page: this.page ,
     limit: this.limit,
     status: this.status_users

    }

    const resp =  await this._user.getUsers(filter);
    let result  = resp.resp;
    this.people_total = result.total;

    let people:any = reload ? [] : this.rows;
    
    let rows = people.concat(result?.rows);
    rows.sort((a:any, b:any) => a.person.name.localeCompare(b.person.name));

   // this.nextRows();

   this.rows = rows;
   this.loading = false;

  }


  nextRows = () =>{

    this.reload_loading_pluss =true;

this.rows_number = this.rows_number + this.rows_size;

if(this.rows_number > this.people?.lenght){
 this.rows_number = this.people?.lenght;
}

let rows = this.people?.slice(0, this.rows_number);
this.rows = rows;

this.reload_loading_pluss = false;
  
  }

  
  getIdentity(item:any){

    if(!(item?.identitys?.length > 0))
      return "";

      return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;
  }



sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}
onScrollDown() {
  if(!this.active_filter){

   // this.nextRows();

   this.page = this.page + 1 ; 

   this.getUsers();


   }

  console.log("scrolled down!!");
}

onScrollUp() {
  
  console.log("scrolled up!!");
}


  onScroll() {

    console.log("scrolled!!");
  }

  search_form_method() {

if(this.search_form?.trim() != ''){

  this.active_filter = true;

      this.rows_temp = this.rows_temp?.length > 0? this.rows_temp : this.rows;

      let datas:any = [];
      
       datas =  this.people?.filter((element:any) =>{

        let name =  element?.person?.name;
        let lastName =  element?.person?.lastName;
        let ename =  element?.person?.ejecutive?.name;
        let elastName =  element?.person?.ejecutive?.lastName;
        let identity = this.getIdentity(element)

      
        let resp = 
        identity.toLowerCase().includes(this.search_form) ||
         name.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1 ||
         lastName.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1;
        //  ename.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1 ||
        //  elastName.toString().toLowerCase().indexOf(this.search_form.toLowerCase()) !== -1;
         

        return resp;
      });
      
      this.rows= datas;


      }else{
             

            this.rows = this.rows_temp?.length > 0? this.rows_temp : this.rows;
            this.active_filter = false;

      }

   }

   
offcanvasServiceClose(){
  this.view = 1;
  this.offcanvasService.dismiss();

}

setChangView(view:any){
  this.view = view;
  //this.getSection_transaction_by_person();

}



toogleStatus = async (st = this.usQuote) => {
    
  let status = st? true:false;
  try {
    let query = {
      code_user: this.user_options.user.code,
      status
    };

     await this._user.status_user(query);
    alertifyjs.success('Cambio de estatus exitoso');

    this.getUsers(false,true);

  } catch (error) {

    this.usQuote = !this.usQuote;
    alertifyjs.error('Error al cambiar estatus');
    console.log(error);
    
  }


}

changeShop = async () => {
    
  try {
    let query = {
      code_user: this.user_options.user.code,
      code_shop:this.shop
    };

     await this._user.shop_user(query);
    alertifyjs.success('Cambio de tienda exitoso');

    this.getUsers(false,true);

  } catch (error) {

    this.usQuote = !this.usQuote;
    alertifyjs.error('Error al tienda estatus');
    console.log(error);
    
  }


}



changePass = async () => {
    
  try {
    let query = {
      code_user: this.user_options.user.code,
      password:this.password
    };

     await this._user.pass_user(query);
    alertifyjs.success('Cambio clave exitoso');
    this.password = '';
    this.getUsers(false,true);

  } catch (error) {

    this.usQuote = !this.usQuote;
    alertifyjs.error('Error al cambiar clave');
    console.log(error);
    
  }


}


getshops = async () => {
  try {

    const resp = await this.getshopsServer();
    this.shops = resp?.res;
    let obj_shop:any ={};
    this.shops?.map((item:any)=>{

      obj_shop[item.code] = item;


    });

      this.obj_shop = obj_shop;


    
    
  } catch (error) {
    console.error(error);

  } 

 


}


getshopsServer = async () => {
  try{
      return this._user.getshops({});

    } catch (error) {
      console.error(error);
      
      
    }
}



changeProfile = async () => {
    
  try {
    let query = {
      code_user: this.user_options.user.code,
      code_profile:this.profile
    };

    this.submit_disabled_profile = true;


     await this._user.profile_user(query);
    alertifyjs.success('Cambio de perfil exitoso');

    this.getUsers(false,true);

  } catch (error) {

    this.usQuote = !this.usQuote;
    alertifyjs.error('Error cambiar el  perfil');
    console.log(error);
    
  }

    this.submit_disabled_profile = false;

}


getProfile(user:any){
  let profiles = user?.profile ;
  let profile;

  if(profiles){
    let value = this.profiles[profiles[0]];
    let  key = profiles[0];

    profile = {key,value}
  }

   return profile ;

}
}
