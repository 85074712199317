import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-stadistic-dashboard',
  templateUrl: './stadistic-dashboard.component.html',
  styleUrls: ['./stadistic-dashboard.component.css']
})
export class StadisticDashboardComponent implements OnInit {

  constructor(
    public _auth : AuthService

  ) { }

  ngOnInit(): void {
  }

}
