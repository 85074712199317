
<div class="modal-body">
    <ng-container [ngSwitch]="formType">
        <div *ngSwitchCase="'template'">
            <div>
    <form [formGroup]="actionType === 'edit' ? editForm : form" (ngSubmit)="saveTemplate()">
        <div class="form-floating mt-3" *ngIf="actionType === 'create'">
            <select class="form-select bg-body" 
                    id="floatingSelect" 
                    aria-label="Floating label select example" 
                    formControlName="channel" 
                    required>
                <option value="">Seleccione</option>
                <ng-container *ngFor="let item of channels">
                    <option [value]="item?._id">{{item?.description}}</option>
                </ng-container>
            </select>
            <label for="floatingSelect">Canal</label>
            <div *ngIf="form.get('channel')?.invalid && form.get('channel')?.touched" class="text-danger">
                Campo requerido
            </div>
        </div>

        <div class="form-floating mb-3 mt-3" *ngIf="actionType === 'create'">
            <input type="text" 
                   [ngClass]="{'error': form.get('code')?.invalid && form.get('code')?.dirty}" 
                   class="form-control" 
                   id="code" 
                   formControlName="code" 
                   placeholder="Nombre">
            <label for="code" class="label font-weight-normal" style="word-wrap: break-word;">
                <span>Código</span>
            </label>
            <div *ngIf="form.get('code')?.invalid && form.get('code')?.touched" class="text-danger">
                Campo requerido
            </div>
        </div>

        <div class="form-floating mb-3 mt-3" *ngIf="actionType === 'edit'">
            <input type="text" 
                   [ngClass]="{'error': form.get('subject')?.invalid && form.get('subject')?.dirty}" 
                   class="form-control" 
                   id="subject" 
                   formControlName="subject" 
                   placeholder="subject">
            <label for="subject" class="label font-weight-normal" style="word-wrap: break-word;">
                <span>Subject</span>
            </label>
        </div>

        <div class="form-floating mb-3 mt-3" *ngIf="actionType === 'create'">
            <input type="text" 
                   [ngClass]="{'error': form.get('description')?.invalid && form.get('description')?.dirty}" 
                   class="form-control" 
                   id="description" 
                   formControlName="description" 
                   placeholder="Nombre">
            <label for="description" class="label font-weight-normal" style="word-wrap: break-word;">
                <span>Nombre</span>
            </label>
            <div *ngIf="form.get('description')?.invalid && form.get('description')?.touched" class="text-danger">
                Campo requerido
            </div>
        </div>

        <div class="form-floating mb-3 mt-3" *ngIf="actionType === 'edit'">
            <input type="text" 
                   [ngClass]="{'error': editForm.get('description')?.invalid && editForm.get('description')?.dirty}" 
                   class="form-control" 
                   id="description" 
                   formControlName="description" 
                   placeholder="Nombre">
            <label for="description" class="label font-weight-normal" style="word-wrap: break-word;">
                <span>Nombre</span>
            </label>
            <div *ngIf="editForm.get('description')?.invalid && editForm.get('description')?.touched" class="text-danger">
                Campo requerido
            </div>
        </div>

        <div class="form-floating mb-3" *ngIf="actionType === 'create'">
    <!-- <textarea
    type="text" 
              [ngClass]="{'error': form.get('message')?.invalid && form.get('message')?.dirty}" 
              class="form-control" 
              id="message" 
              formControlName="message" 
              placeholder="Mensaje"
              [maxLength]="maxCharacters">
    </textarea> -->
    <!-- <angular-editor [placeholder]="'Enter text here...'" [config]="editorConfig" [(ngModel)]="htmlContent"></angular-editor> -->
    <!-- <label for="message" class="label font-weight-normal" style="word-wrap: break-word;">
        <span>Mensaje</span>
    </label> -->
    <div *ngIf="form.get('message')?.invalid && form.get('message')?.touched" class="text-danger">
        Campo requerido
    </div>
    <div *ngIf="maxCharacters" class="text-muted">
        {{ form.get('message')?.value?.length || 0 }}/{{ maxCharacters }} caracteres
    </div>
</div>

<div class="form-floating mb-3" *ngIf="actionType === 'edit'">
    <textarea type="text" 
    (input)="adjustHeight($event)"
              [ngClass]="{'error': editForm.get('message')?.invalid && editForm.get('message')?.dirty}" 
              class="form-control" 
              id="message" 
              style="overflow:hidden; resize:none;"
              formControlName="message" 
              placeholder="Mensaje">
    </textarea>
    <label for="message" class="label font-weight-normal" style="word-wrap: break-word;">
        <span>Mensaje</span>
    </label>
    <div *ngIf="editForm.get('message')?.invalid && editForm.get('message')?.touched" class="text-danger">
        Campo requerido
    </div>
    <!-- <div *ngIf="maxCharacters" class="text-muted">
        {{ editForm.get('message')?.value?.length || 0 }}/{{ maxCharacters }} caracteres
    </div> -->
</div>


        <div class="text-end">
            <button type="submit" class="btn btn-primary btn-sm p-2 mt-4" [disabled]="(actionType === 'edit' ? editForm : form).invalid">
  {{ actionType === 'create' ? 'Agregar' : 'Actualizar' }}
</button>
        </div>
    </form>
</div>
        </div>
        <div *ngSwitchCase="'event'">
            <form [formGroup]="formEvents" (ngSubmit)="saveNewEvent()">
  <div class="form-floating mb-3 mt-3">
    <input type="text" 
           [ngClass]="{'error': formEvents.get('code')?.invalid && formEvents.get('code')?.dirty}" 
           class="form-control" 
           id="code" 
           formControlName="code" 
           placeholder="Nombre">
    <label for="code" class="label font-weight-normal" style="word-wrap: break-word;">
      <span>Código</span>
    </label>
    <div *ngIf="formEvents.get('code')?.invalid && formEvents.get('code')?.touched" class="text-danger">
      Campo requerido
    </div>
  </div>

  <div class="form-floating mb-3 mt-3">
    <input type="text" 
           [ngClass]="{'error': formEvents.get('description')?.invalid && formEvents.get('description')?.dirty}" 
           class="form-control" 
           id="description" 
           formControlName="description" 
           placeholder="Nombre">
    <label for="description" class="label font-weight-normal" style="word-wrap: break-word;">
      <span>Nombre</span>
    </label>
    <div *ngIf="formEvents.get('description')?.invalid && formEvents.get('description')?.touched" class="text-danger">
      Campo requerido
    </div>
  </div>

  <!-- <div class="form-floating mb-3">
    <input type="text" 
           [ngClass]="{'error': formEvents.get('limit')?.invalid && formEvents.get('limit')?.dirty}" 
           class="form-control" 
           id="limit" 
           formControlName="limit" 
           placeholder="Límite">
    <label for="limit" class="label font-weight-normal" style="word-wrap: break-word;">
      <span>Límite</span>
    </label>
  </div> -->

  <div class="text-end">
    <button type="submit" class="btn btn-primary btn-sm p-2 mt-4" [disabled]="formEvents.invalid">
      Agregar
    </button>
  </div>
</form>
        </div>
        <!-- <div *ngSwitchCase="'notification'">
            <form [formGroup]="formNotifications" (ngSubmit)="saveNewNotification()">

            </form>
        </div> -->
        <div *ngSwitchDefault>
            <p>Seleccione un formulario.</p>
        </div>
    </ng-container>
</div>
