import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import {
  MbscCalendarOptions,
  MbscFormOptions,
  MbscDatetimeOptions,
} from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import * as alertifyjs from 'alertifyjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';

import { FormErrorService } from 'src/app/services/form-errors.service';
import {v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-new-fee',
  templateUrl: './new-fee.component.html',
  styleUrls: ['./new-fee.component.css'],
  providers: [DatePipe],
})
export class NewFeeComponent implements OnInit {
  
  @Output() close = new EventEmitter<any>();

  @Input() set code_edit(_code: any) {
    this.code = _code;
  };
  
  dateSetting: MbscCalendarOptions = {
    responsive: {
      small: {
        controls: ['calendar'],
        display: 'center',
      },
      medium: {
        display: 'bubble',
      },
    },
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light',
  };

  code: string = "";
  birthday: any;
  birthdayString: any = '';
  formAccount: any;
  formInitial: any;
  form: any;

  submit_disabled: any;
  person: any;
  searchPerson: any;
  step: any = 1;
  validateNumber: any;

  type_identity: any = [];
  code_identity: any = [];
  marital_status: any;
  result: any;
  buro: any = {};
  loading: any;

  submit_disabled_contact: any;
  validate_contact: any;

  activeValidateTlf = false;
  dataValidateTlf: any;
  email = '^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$';
  buro_loading: any;

  date_base: any = new Date();
  date_base_string: any;

  mobileSettingsFile: MbscDatetimeOptions = {
    display: 'bottom',
  };

  mobileSettings: MbscDatetimeOptions = {
    display: 'bottom',
    themeVariant: 'dark',
  };

  desktopSettings: MbscDatetimeOptions = {
    display: 'bubble',
    touchUi: false,
  };

  formSettings: MbscFormOptions = {
    inputStyle: 'box',
  };
  env = environment;

  FormErrorService: any;

  list_comparison: any = [];
  list_fee_application_types: any = [];
  list_categories: any = [];
  list_fee_frecuencies: any = [];
  list_fee_measurements: any = [];
  list_fee_attributes: any = [];


  showBetweenInputs: boolean = false;
  showMixedCommission: boolean = false;
  showFixedCommission: boolean = false;
  showPercentageCommission: boolean = false;
  showRestrictionInputs: boolean = false;
  showFrequencyInputs: boolean = false;
  showContainerConditions: boolean = true;


  listElements: any = [];
  account_payments: any = [];

  current_commision: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private _user: UserService,
    private datePipe: DatePipe,
    private router: Router,
    public _auth: AuthService,
    public formErrorService:FormErrorService
  ) {}

  async ngOnInit() {
    this.date_base_string = this.datePipe.transform(
      this.date_base,
      'MM/dd/yyyy'
    );

    if(this.code!=""){
      let response: any = await this._user.getFeeByCode({code: this.code});
      this.current_commision = response?.data[0];

      this.current_commision?.fees.map((row: any)=> {
        this.listElements.push(Object.assign(row, { uuid: uuidv4()}));
      });

    }
    this.initFormInitial();
    this.initFormAmount();
    this.initFormAccount();
    
    this.getAccountPayments();
    this.getListCategories();
    this.getListComparison();
    this.getListFeeApplicationTypes();
    this.getListFeeMeasurements();
    this.getListFeeFrecuencies();
    this.getListFeeAttributes();
  }

  // formulario inicial, detalles
  initFormInitial = () => {
    this.formInitial = this.formBuilder.group({
      title: [this.current_commision?.name ?? '', Validators.required],
      codigo: [this.current_commision?.code_concept, Validators.required],
      description: [this.current_commision?.description ?? '', Validators.required],
      status: ['ACTIVE', Validators.required],
      application_type: [ this.current_commision?.fee?.application_type?._id ?? '', Validators.required],
      frequency: [this.current_commision?.fee?.frequency?._id ?? '', Validators.required],
      unit_of_measure: [this.current_commision?.fee?.unit_of_measure?._id ?? '', Validators.required],
      limit: [this.current_commision?.fee?.limit ?? '', Validators.required]
    });
    if (this.current_commision?.code_concept) {
      this.formInitial.get('codigo')?.disable();
    }

    this.formInitial.get('frequency').valueChanges.subscribe((value: any) => {
      let frequency = this.list_fee_frecuencies.find((row:any)=>row._id.toString() === value.toString());
      console.log(frequency);
      this.updateFrequencyVisibility(frequency.code);
    });

  }

  // formulario interno, agregar reglas
  initFormAmount = () => {
    this.form = this.formBuilder.group({
      withCondition: [true, Validators.required],
      attribute: ['', Validators.required],
      condition: ['', Validators.required],
      lower_limit: [''],
      upper_limit: [''],
      single_value: [''],
      commission_type: ['', Validators.required],
      currency: ['USD', Validators.required],
      fixed_amount: [''],
      percentage: ['', [Validators.min(1), Validators.max(100)]],
      start_date: ['', Validators.required],
      end_date: ['']
    });


    this.form.get('withCondition').valueChanges.subscribe((value: any) => {
      console.log(value);
      this.updateConditionVisibility(value);
    });

    this.form.get('condition').valueChanges.subscribe((value: any) => {
      this.updateVisibility(value);
    });

    

    this.form.get('commission_type').valueChanges.subscribe((value: any) => {
      this.updateCommissionVisibility(value);
    });

    this.updateVisibility(this.form.get('condition').value);
    this.updateCommissionVisibility(this.form.get('commission_type').value);

  }

   // formulario de la cuenta contable
   initFormAccount = () => {
    this.formAccount = this.formBuilder.group({
      account: [this.current_commision?.fee?.account_payment?._id ?? '', Validators.required]
    });

  }


  

  getAccountPayments = async (plans_loading = true) => {
    try {
      let respuesta = await this._user.getListAccountPayments({});
      this.account_payments = respuesta?.data;
    } catch (error: any) {
      console.log(error);
    }
  };

  getListCategories = async () => {
    try {  
      let response = await this._user.getListFeeCaterories({});
      this.list_categories = response.data;
      console.log(this.list_categories);
    } catch (error: any) {
      this.list_categories = [];
      console.log(error);
    }
  }

  getListFeeApplicationTypes = async () => {
    try {  
      let response = await this._user.getListFeeApplicationTypes({ status: "ACTIVE"});
      this.list_fee_application_types = response.data;
      console.log(this.list_fee_application_types);
    } catch (error: any) {
      this.list_fee_application_types = [];
      console.log(error);
    }
  }

  
  getListFeeAttributes = async () => {
    try {  
      let response = await this._user.getListFeeAttributes({ status: "ACTIVE"});
      this.list_fee_attributes = response.data;
      console.log(this.list_fee_attributes);
    } catch (error: any) {
      this.list_fee_attributes = [];
      console.log(error);
    }
  }

  getValueByKey = (obj: any, key: string) => {
    const foundItem = obj.find((item:any) => item.key === key);
    return foundItem ? foundItem.value : null;
  }

  
  getListFeeFrecuencies = async () => {
    try {  
      let response = await this._user.getListFeeFrecuencies({ status: "ACTIVE"});
      this.list_fee_frecuencies = response.data;
      console.log(this.list_fee_application_types);
    } catch (error: any) {
      this.list_fee_application_types = [];
      console.log(error);
    }
  }

  getListFeeMeasurements = async () => {
    try {  
      let response = await this._user.getListFeeMeasurements({ status: "ACTIVE"});
      this.list_fee_measurements = response.data;
      console.log(this.list_fee_application_types);
    } catch (error: any) {
      this.list_fee_application_types = [];
      console.log(error);
    }
  }

  getListComparison = async () => {
      try {  
        let response = await this._user.getListComparison({});
        this.list_comparison = response.data;
        console.log(this.list_comparison);
      } catch (error: any) {
        this.list_comparison = [];
        console.log(error);
      }
  }

  private updateFrequencyVisibility(conditionValue: string) {
    switch (conditionValue) {
      case 'ALWAYS':
        this.showFrequencyInputs = false;
        this.setRequired('unit_of_measure', false);
        this.setRequired('limit', false);
        break;
      default:
        this.showFrequencyInputs = true;
        this.setRequired('unit_of_measure', true);
        this.setRequired('limit', true);
    }
  }

  private updateConditionVisibility(conditionValue: any) {
    if(conditionValue===true) {
        this.showContainerConditions = true;
        this.setRequired('lower_limit', false);
        this.setRequired('upper_limit', false);
        this.setRequired('single_value', true);
        this.setRequired('condition', true);
        this.setRequired('attribute', true);
    } else { 
        this.showContainerConditions = false;
        this.setRequired('lower_limit', false);
        this.setRequired('upper_limit', false);
        this.setRequired('single_value', false);
        this.setRequired('condition', false);
        this.setRequired('attribute', false);
    }
  }

  private updateVisibility(conditionValue: string) {
    switch (conditionValue) {
      case 'BETWEEN':
        this.showBetweenInputs = true;
        this.setRequired('lower_limit', true);
        this.setRequired('single_value', false);
        break;
      default:
        this.showBetweenInputs = false;
        this.setRequired('lower_limit', false);
        this.setRequired('upper_limit', false);
        this.setRequired('single_value', true);
    }
  }

  private updateCommissionVisibility(commissionType: string) {
    switch (commissionType) {
      case 'MIXED':
        this.showMixedCommission = true;
        this.showFixedCommission = false;
        this.showPercentageCommission = false;
        this.setRequired('fixed_amount', true);
        this.setRequired('percentage', true);
        break;
      case 'FIXED':
        this.showMixedCommission = false;
        this.showFixedCommission = true;
        this.showPercentageCommission = false;
        this.setRequired('fixed_amount', true);
        this.setRequired('percentage', false);
        break;
      case 'PERCENTAGE':
        this.showMixedCommission = false;
        this.showFixedCommission = false;
        this.showPercentageCommission = true;
        this.setRequired('fixed_amount', false);
        this.setRequired('percentage', true);
        break;
      default:
        this.showMixedCommission = false;
        this.showFixedCommission = false;
        this.showPercentageCommission = false;
        this.setRequired('fixed_amount', false);
        this.setRequired('percentage', false);
    }
  }



  // Formulario 1
  validateField(field: string) {
    this.form.get(field)?.markAsTouched();
  }

  hasError(controlName: string): boolean {
    const control = this.form.get(controlName);
    return control?.invalid && (control?.touched || control?.dirty);
  }

  getErrorMessage(controlName: string): string {
    const control = this.form.get(controlName);
    if (control?.errors) {
      return this.formErrorService.getErrorMessage(controlName, control.errors);
    }
    return '';
  }

  setRequired(controlName: string, isRequired: boolean) {
    const control = this.form.get(controlName);
      if (isRequired) {
        control?.setValidators([Validators.required]);
      } else {
        control?.clearValidators();
      }
      control?.updateValueAndValidity({ emitEvent: false });
  }

  // Formulario 2
  validateField1(field: string) {
    this.formInitial.get(field)?.markAsTouched();
  }

  hasError1(controlName: string): boolean {
    const control = this.formInitial.get(controlName);
    return control?.invalid && (control?.touched || control?.dirty);
  }

  getErrorMessage1(controlName: string): string {
    const control = this.formInitial.get(controlName);
    if (control?.errors) {
      return this.formErrorService.getErrorMessage(controlName, control.errors);
    }
    return '';
  }

  setRequired1(controlName: string, isRequired: boolean) {
    const control = this.formInitial.get(controlName);
      if (isRequired) {
        control?.setValidators([Validators.required]);
      } else {
        control?.clearValidators();
      }
      control?.updateValueAndValidity({ emitEvent: false });
  }
  



  add = async () => {
    console.clear();
    if (this.form.valid) {
      let withCondition = this.form.get('withCondition').value;
      console.log(withCondition);
      this.listElements.push(Object.assign(this.form.getRawValue(), { uuid: uuidv4()}));
      this.initFormAmount();
      setTimeout(()=> {
        this.updateConditionVisibility(withCondition);
      }, 3000);
    } else {
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    } 
  };

  delete = async (uuid: string) => {
    this.listElements = this.listElements.filter((row:any) => row.uuid!==uuid);
    alertifyjs.success('Eliminado');
  };


  submit = async () => {
    console.clear();
    if (this.formInitial.valid && this.formAccount.valid && this.listElements.length>0) {
      if(this.code){
        Swal.fire({
          title: "¿Confirma desea editar esta Penalidad?",
          text: "El proceso de editar una Penalidad guarda el respaldo de la Penalidad actual y genera una nueva versión, ¿Desea editar esta Penalidad?",
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          input: "textarea",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonText: `Si, continuar!`,
          showLoaderOnConfirm: true,
          preConfirm: async (login) => {
            try {
              if (login?.trim() == '') {
                return Swal.showValidationMessage(`Debe añadir una razón`);
              }
            } catch (error) {
              Swal.showValidationMessage(`
                Request failed: ${error}
              `);
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.saveMethod();
          }
        });
      } else {
        this.saveMethod();
      }
    }
  };

  saveMethod = async() => {
    this.submit_disabled = true;
    let data = this.formInitial.getRawValue();
    data = Object.assign(data, this.formAccount.getRawValue());
    data = Object.assign(data, {details : this.listElements});
    if(this.code) {
      data = Object.assign(data, {code : this.code});
    }
    console.log(data);
    try {
      let resp = await this._user.saveFee(data);
      this.close.emit(true);
      alertifyjs.success('Creacion exitosa');
    } catch (error: any) {
      alertifyjs.error(error?.error?.msg);
      console.error(error);
      this.submit_disabled = false;
    }
  }


}
