import { DatePipe } from '@angular/common';
import { noUndefined } from '@angular/compiler/src/util';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { async } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';

import * as alertifyjs from 'alertifyjs';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import Swal from'sweetalert2';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FeeService } from 'src/app/services/fee.service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-person-fee-payments',
  templateUrl: './person-fee-payments.component.html',
  styleUrls: ['./person-fee-payments.component.css'],
  providers: [DatePipe]

})
export class PersonFeePaymentsComponent implements OnInit {

  queryParams: any;
  person: any;
  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  loading_person = false;
  loading = false;

  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;

  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
   type_approved: any;
   clientQuota:any;
   invoiceCredits: any = [];
   resp_paymentsCredits: any = [];
   installments: any = [];

   autopago: any = [{key: "BA", name: "AUTO: BANCAAMIGA"}, {key: "MERCHANT", name: "AUTO: MEGASOFT"}, {key: "MC", name: "AUTO: MERCANTIL"}];
   banks: any;


view = 1;
invoiceSelected:any;
installMentSelected:any;
payment:any;
loadcon:any;
loadcon2:any;
subStatus:any = {};
submit_disabled_changeSubStatus: any;
list_payment_sub_status:any;

params_payment:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService,
    public _auth : AuthService,
    private feeService: FeeService,
    private offcanvasService: NgbOffcanvas


  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      this.queryParams = params;
      if (this.queryParams?.code) {

        this.getPerson();
        this.getBanks();
      }

    });
    this.getpayment_sub_status();


  }




  cancelPayment = async (payment:any) => {


    let action = 'Anular'

    Swal.fire({
      title: "¿Deseas Anular este pago?",
      text: "¡No podrás revertir esto!",
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      input: "select",
      inputOptions: {
        'Referencia errada': 'Referencia errada',
        'Banco errado': 'Banco errado',
        'Soporte errado': 'Soporte errado',
        'Sin soporte': 'Sin soporte',
        'Monto errado': 'Monto errado',
        'Fecha errada': 'Fecha errada',
        'Datos de pago errados': 'Datos de pago errados',
        'No se encuentra en banco': 'No se encuentra en banco',
        'Método de pago errado': 'Método de pago errado',
        'Pago duplicado': 'Pago duplicado'
      },
      inputPlaceholder: 'Selecciona una razón',
      showCancelButton: true,
      confirmButtonText: `Si, ${action}!`,
      showLoaderOnConfirm: true,
      preConfirm: async (reason) => {
        try {
          if (!reason) {
            return Swal.showValidationMessage(`Debe seleccionar una razón de anulación`);
          }
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        this.cancelPaymentQuestion(payment, result.value);
      }
    });

  }


  cancelPaymentQuestion = async (payment:any,msg = '') => {

    this.loadcon2 = true;
    try {
      
      const resp = await this._user.cancelPayment({ code_payment: payment,description:msg });
      

      if(this.subStatus[payment] == 'REVISION'){
        this.changeSubStatus(payment,'NA')
  
      }  
      const resp_paymentsCredits = await this.listClientPaymentsCredits();
      this.resp_paymentsCredits = resp_paymentsCredits?.result?.fees;

 
      if(resp?.res){

        alertifyjs.success(`Pago anulado codigo ${payment}`);
        this.getPerson();

      }
      
      this.loadcon2 = false;

    } catch (error) {
      console.error(error);

    this.loadcon2 = false;
  }
 
    this.loading_person = false;

  }


  getPerson = async () => {
    this.loading_person = true;
    try {

      const resp = await this._user.getPersonbyCode({ code: this.queryParams.code });
      this.person = resp.resp;

      const resp_paymentsCredits = await this.listClientPaymentsCredits();
      

   

    
    


      //payment_sub_status
      this.resp_paymentsCredits = resp_paymentsCredits?.result?.fees;





    } catch (error) {
      console.error(error);

    } 

 
    this.loading_person = false;


  }





  listClientPaymentsCredits = async () => {
       
    try {
         const data =  {
          personCode :this.queryParams?.code
         }

        return this.feeService.fees(data);

      } catch (error) {
        console.error(error);
        
        
      }
  }




  getIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

  }


  getContact(item: any) {

    if (!(item?.constacts?.length > 0))
      return "";

    return `${item?.constacts[0]?.contact}`;


  }


  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }


  redirectIMG(url:String){
    const link = `${url}`;
    window.open(link, '_blank');

}

findAutopago = (acron: string) => {
  console.log(acron);
  if(acron==="BA" || acron==="MC") {
    let autopago = this.autopago.find((ele: any) => ele.key===acron);
    if(autopago) {
      return autopago.name;
    } else {
      return null;
    }
  } else {
    return acron
  }
}


getBanks = async () => {
  try {
    const resp = await this.getBanksServices();

    let banks_resp = resp?.res;
    let banks:any = {};


    banks_resp.map((value:any)=>{

      banks[value.bankCode] = value?.name;

    });

    this.banks = banks;
    
    
  } catch (error) {
    console.error(error);

  } 

}


getBanksServices = async () => {
  return await this._user.getBanks({});
};


setPayment(data:any){


  let action = 'Editar'

  Swal.fire({
    title: `¿Deseas ${action} este pago?`,
    text: "¡No podrás revertir esto!",
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    input: "select",
    inputOptions: {
      'Banco Receptor errado': 'Banco Receptor errado',
      'Banco Emisor errado': 'Banco Emisor errado',
      'Número de referencia errado': 'Número de referencia errado',
      'Fecha de Pago errada': 'Fecha de Pago errada',
      'Monto errado': 'Monto errado',
      'Comprobante de pago errado': 'Comprobante de pago errado'
    },
    inputPlaceholder: 'Selecciona una razón',
    showCancelButton: true,
    confirmButtonText: `Si, ${action}!`,
    showLoaderOnConfirm: true,
    preConfirm: async (reason) => {
      try {
        if (!reason) {
          return Swal.showValidationMessage(`Debe seleccionar una razón de Edición`);
        }
      } catch (error) {
        Swal.showValidationMessage(`Request failed: ${error}`);
      }
    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then((result) => {
    if (result.isConfirmed) {
      data.description_edit = result?.value;
      localStorage.setItem('pe',JSON.stringify(data));
      this.router.navigate([`/console/post`], {});
    

    }
  });




}




changeSubStatus= async (code:any,code_sub:any) => {
  this.submit_disabled_changeSubStatus = true;

  
        let query = {
          code_payment: code,
          code_payment_sub_status:code_sub
        };
    
         await this._user.payment_sub_statusPost(query);
         this.subStatus[code] = code_sub;
        alertifyjs.success('Cambio exitoso');
        this.submit_disabled_changeSubStatus = false;
  

}



  



getpayment_sub_status = async () => {
try {

  const resp = await this.payment_sub_status();
  this.list_payment_sub_status = resp?.resp;
  
} catch (error) {
  console.error(error);

} 




}


payment_sub_status = async () => {
try{
    return this._user.payment_sub_status({});

  } catch (error) {
    console.error(error);
    
    
  }
}



paymentRollback = async (payment:any) => {

let action = 'Revertir'

Swal.fire({
  title: `¿Deseas ${action} este pago?`,
  text: "¡No podrás revertir esto!",
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  input: "select",
  inputOptions: {

    'No se encontro en banco': 'No se encontro en banco'
   
  },
  inputPlaceholder: 'Selecciona una razón',
  showCancelButton: true,
  confirmButtonText: `Si, ${action}!`,
  showLoaderOnConfirm: true,
  preConfirm: async (reason) => {
    try {
      if (!reason) {
        return Swal.showValidationMessage(`Debe seleccionar una razón`);
      }
    } catch (error) {
      Swal.showValidationMessage(`Request failed: ${error}`);
    }
  },
  allowOutsideClick: () => !Swal.isLoading()
}).then((result) => {
  if (result.isConfirmed) {

    this.servicePaymentRollback(payment, result.value);

  }
});
}


servicePaymentRollback = async (payment:any,msg = '') => {

  this.loadcon2 = true;

  try {
    
    const resp = await this._user.paymentRollback({ code_payment: payment,description:msg });

    const resp_paymentsCredits = await this.listClientPaymentsCredits();
    this.resp_paymentsCredits = resp_paymentsCredits?.result?.fees;


    

    if(resp?.res){
      this.getPerson();

      alertifyjs.success(`Pago devuelto `);

    }
    this.loadcon2 = false;

  } catch (error) {
    alertifyjs.error('Error al devolver pago estatus');

    console.error(error);

  this.loadcon2 = false;
} 


  this.loading_person = false;


}

toDateFn(isoString: string): string {
  const [datePart, timePart] = isoString.split('T');
  const [year, month, day] = datePart.split('-');
  return `${day}/${month}/${year}`;
}


openBottom= async (content: TemplateRef<any>,params_invoice:any) => {
  try {
    console.log(params_invoice);

    this.params_payment = params_invoice;

    this.offcanvasService.open(content, {
      position: 'bottom',
      keyboard: false,

    });
    

  } catch (error) {
    console.log(error);
  }
};

response = (event: any) => {
 
}



}
