<div class="mt-md-3 p-3">

    <div class="row ">
        <div class=" col-12 col-md-6 offset-md-3 ">
            <h5 class=" text-secondary text-center ">
                <i class="  fas fa-users me-2"></i> Estadisticas de registro
            </h5>



            <div class="row mt-4">

                <div class="mb-4 mt-2 col-12 col-md-6 ">
                    <input style="
                padding: 16px 9px;
                background: white;
            " id="demo-external" (onSet)="birthdaySet()" [(ngModel)]="range" mbsc-range class="form-control" [mbsc-options]="dateSetting3" #externalRange="mobiscroll" />

                </div>
            </div>


            <div *ngIf="!loading " class="mt-3 my-3 p-3 bg-body rounded shadow-sm">
                <ng-container>

                    <div class="  text-dark pt-3 mb-4" *ngFor="let item of rows; let i=index">

                        <h5 class="text-secondary">{{shops[item.shop]}} </h5>


                        <div class=" d-flex text-dark pt-3" *ngFor="let item2 of item.ejecutives; let i=index">

                            <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                                <div class="d-flex justify-content-between">
                                    <span class="text-gray-dark text-capitalize"> {{item2?.name}} 
                                    <!-- <span class="ms-1 text-secondary">({{item2[item?.shop]}})</span> -->
                                    </span>

                                    <span>
                                      {{item2?.count}}
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class=" d-flex text-dark pt-3">


                            <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                                <div class="d-flex justify-content-between">
                                    <span class="text-gray-dark text-capitalize fw-bold"> Total
                                    <!-- <span class="ms-1 text-secondary">({{item2[item?.shop]}})</span> -->
                                    </span>

                                    <span class="fw-bold">
                                      {{item?.total}}
                                    </span>

                                </div>
                            </div>
                        </div>


                    </div>

                    <div class=" d-flex text-dark pt-3">
                        <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <h5 class="d-flex justify-content-between">
                                <span class="text-gray-dark text-capitalize fw-bold"> Total
                                </span>

                                <span class="fw-bold">
                                    {{stadistic?.total}}
                                </span>
                            </h5>

                        </div>
                    </div>
                </ng-container>

            </div>

            <div>

                <!-- 
                <pre>
                  {{ stadistic | json }}
                </pre> -->
                <ng-container *ngIf="loading ">
                    <div class="w-100 text-center text-md-start">
                        <img src="./assets/public/images/loading.gif  " alt=" ">
                    </div>
                </ng-container>

            </div>



        </div>
    </div>
</div>