<ng-template #content let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 ">
                    <div class="row">
                        <div class="col-10 h5 mt-2">
                            Nuevo Plan
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 col-md-6 offset-md-3">
                    <app-new-plan (close)="close()"></app-new-plan>
                </div>
            </div>
        </div>
    </div>
</ng-template>



<div class="mt-md-3 p-3">

    <div class="row ">
        <div class=" col-12 col-md-8 offset-md-2">

            <h5 class=" text-secondary   text-center"> Planes

               
            </h5>
            <div class="mb-5 mt-2 text-center">
                <button (click)="pocisionBottom(content)" class="btn btn-block btn-primary ">
                <span >Nuevo plan</span>
                <!-- <span *ngIf="submit_disabled">
                  <i class="fa  fa-spinner fa-spin ms-2"></i>
                </span> -->
              </button>
            </div>
           
            <ng-container *ngIf="!plans_loading" class="pt-4">

                <div class="mb-2 text-start">
                    <button (click)="status_plans = true" [ngClass]="{'btn-secondary': status_plans == true,'btn-outline-secondary': status_plans == false}" class="btn btn-sm  me-3">
                       <span >Activos</span>
                    </button>

                    <button (click)="status_plans = false" [ngClass]="{'btn-outline-secondary': status_plans == true,'btn-secondary': status_plans == false}" class="btn   btn-sm ">
                        <span >Inactivos</span>
                     </button>
                </div>
                <div class=" mt-4 row">
                    <div [ngClass]="{'d-none': item.status != status_plans }" *ngFor="let item of plans" class="col-12 col-md-6 ">
                        <div class="card mb-4"  style="min-height: 200px;" >
                            <div class="card-body">

                            <div class="d-flex">
                                <div class="text-primary fw-bold">
                                    {{item?.code}}
                                </div>
                                <div class="ms-auto text-end small">
                                    <div class="cursor-pounter float-end ">
                                        <i  *ngIf="item.status == true"(click)="deletePlan(item?.code)" class="fas fa-times text-danger " style="font-size: 18px;cursor: pointer;"></i>
                                        <i  *ngIf="item.status == false"(click)="activePlan(item?.code)" class="fas fa-redo-alt text-success " style="font-size: 18px;cursor: pointer;"></i>

                                        
                                    </div>
                                    <div class="me-4">
                                        {{item?.date | date: 'dd/MM/yyyy'}}

                                    </div>
                                </div>
                            </div>
                            <div class="mt-1 ">
                                {{item?.name}}
                            </div>
                            <div class="text-secondary small">
                                {{item?.description}}
                            </div>
                            <!-- <pre>{{ item | json }}</pre> -->
                            <div class="mt-4">
                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Monto minimo : {{item?.min_amount}} USD</span>
                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Cuotas minimas  : {{item?.min_number_installment}}</span>
                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2">Inicial  : {{item?.initial_installMent?'Si':'No'}}</span>
                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="item?.initial_installMent">Monto inicial : {{item?.amount_initial_installMent}}{{item?.type_amount_initial_installMent == 'P'?'%':' USD'}}</span>
                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="!item?.item?.calendar">Cuota cada: {{item?.calendar_time}}D</span>
                                <span class="badge rounded-pill bg-secondary2 me-2 mt-2" *ngIf="!item?.item?.public">Publico: {{item?.public?'Si':'No'}}</span>

                            </div>


                            <!-- <pre>{{ item | json }}</pre> -->

                        </div>
                      </div>
                    </div>


                </div>

                <div class="mt-2 text-secondary h5" *ngIf="!plans_active && status_plans == true">

                    No se encontraron planes activos
                </div>

                <div class="mt-2 text-secondary h5" *ngIf="!plans_inactive && status_plans == false">
                    No se encontraron planes inactivos

                </div>


             


            </ng-container>


            <div *ngIf="!plans_loading && !plans">
                <div>No se encontraron planes </div>
            </div>

            <ng-container *ngIf="plans_loading ">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>


        </div>
    </div>

</div>