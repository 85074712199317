import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.css']
})
export class WebcamComponent implements OnInit,OnDestroy {

  constructor(private _user:UserService) { }
  @Output() getPicture = new EventEmitter<WebcamImage>();

  showWebcam = true;
  isCameraExist = true;
  mediaStream:any = true;
  directionOrDeviceId:any;
  errors: WebcamInitError[] = [];
    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();
    private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
    
  
  ngOnInit(): void {

    this._user.take$.subscribe((response) => {

      if(response){
        this.takeSnapshot(); 

      }

     });

    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      console.log("Let's get this party started")
    }


    navigator.mediaDevices.getUserMedia({
      video: {
        width: { ideal: 400 },
        height: { ideal: 400 },
        facingMode: "environment"
      }


    }).then((mediaStream)=> {

      console.log("mediaStream",mediaStream);
      this.mediaStream = mediaStream?.active;



    }).catch(function(err) {

      console.log(err);
      

      /* manejar el error */
    });



    WebcamUtil.getAvailableVideoInputs()
    .then((mediaDevices: MediaDeviceInfo[]) => {

      this.isCameraExist = mediaDevices && mediaDevices.length > 0;
    });



  }


  takeSnapshot(): void {
    this.trigger.next();
  }

  onOffWebCame() {
    this.showWebcam = !this.showWebcam;
  }

  handleInitError(error: WebcamInitError) {
    this.errors.push(error);
  }

  changeWebCame() {
    this.directionOrDeviceId = !this.directionOrDeviceId;
    this.nextWebcam.next(this.directionOrDeviceId);
  }

  handleImage(webcamImage: WebcamImage) {
    this.getPicture.emit(webcamImage);
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  reset(){

    this.getPicture.emit(undefined);

  }
  
  ngOnDestroy(): void {
    this.onOffWebCame();
    }

}

